import React, { Component } from 'react';

import { ApartmentSelect } from "components/blocks/Apartment";


export default class MainFieldSet extends Component {
  state = {
    layout: 'horizontal',
  };

  render() {
    const { form, instance, errorData, orderId, city } = this.props;
    const { layout } = this.state;
    const itemProps = { form, instance, errorData, layout };

    return (
      <div className="form-block">
        <ApartmentSelect name="apartment" label="Апартаменты" required={true} {...itemProps}
                   activeFilter={{city: city.activeId, order: orderId, active: true}}/>
      </div>
    );
  }
}
