import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { tableColumn, arrayIsEqual } from "utils";
import { PARAMETERS, getParametetsValue } from "utils/choices";
import { TableList, ActionButton, Photo, DateTimeText } from "components/common";
import { CityValue } from "components/blocks/City";
import { AgencyValue } from "components/blocks/Agency";
import WorkerValue from "../WorkerValue";


class WorkerList extends Component {
  state = {
    columnList: ['id', 'photo', 'name', 'parameters', 'city', 'agency', 'date_created', 'actions'],
    columnByName: {
      id: tableColumn('ID', 'id', {
        width: 70,
        sorter: true,
      }),
      name: tableColumn('Имя', 'name', {
        render: (val, item) => <WorkerValue detail={item} asLink={true} isBack={true}/>,
        sorter: true,
      }),
      photo: tableColumn('Фото', 'photo', {
        render: (text, item) => (
          <Link to={`/worker/${item.id}`} state={{ slideIn: true }}>
            <Photo src={item.photos.length && item.photos[0].url} width={120} height={140}/>
          </Link>
        ),
        width: 160,
      }),
      parameters: tableColumn('Параметры', 'parameters', {
        render: (val, item) => (
          PARAMETERS.map(p => {
            if (!val[p.name]) return null;
            return (
              <div key={`${p.name}${item.id}`} style={{fontSize: '12px'}}>
                <span>{p.label}: </span><span>{getParametetsValue(val, p.name)}</span>
              </div>
            )
          })
        ),
        //width: 150,
      }),
      city: tableColumn('Город', 'city', {
        render: (val, item) => <CityValue detail={val} asLink={true} isBack={true}/>,
        sorter: true,
      }),
      agency: tableColumn('Агентство', 'agency', {
        render: (val, item) => <AgencyValue detail={val} asLink={true} isBack={true}/>,
        sorter: true,
      }),
      date_created: tableColumn('Дата создания', 'date_created', {
        render: (val, item) => <DateTimeText date={val}/>,
        width: 130,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        width: 50,
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.worker.listLoad({ query, activeFilter });
  };

  showEditForm = (item) => {
    this.props.actions.worker.showEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.worker.delete({ id: item.id })
  };

  render() {
    const { worker, defaultFilter, activeFilter, history, topActions } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={worker}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        showSearch={true}
        topActions={topActions}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    worker: state.worker,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      worker: bindActionCreators(Object.assign({}, actions.worker), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkerList);
