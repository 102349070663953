import React, { Component } from 'react';

import { ClientDetail } from "components/blocks/Client";


export default class ClientDetailScreen extends Component {
  render() {
    return (
      <div className="page-main">
        <ClientDetail/>
      </div>
    );
  }
}
