import React, {PureComponent} from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';


class ShiftValue extends PureComponent {
  getLinkPath() {
    const { id, asLink, isBack, router } = this.props;
    let path, state;
    if (asLink) {
      path = `/shift/${id}`;
      state = { slideIn: true };
      if (isBack) {
        state.backUrl = router.location.pathname;
        state.backSearch = router.location.search
      }
      return { pathname: path, state: state }
    }
    return null;
  }

  render() {
    const { id } = this.props;
    const value = `Смена №${id}`;
    const path = this.getLinkPath();

    return (
      <Spin spinning={false}>
        {path ?
          <Link to={path}>{value}</Link> :
          <span>{value}</span>}
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    router: state.router,
  };
}

export default connect(mapStateToProps)(ShiftValue);
