import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Descriptions, DateTimeText } from 'components/common';


class CityDetail extends Component {
  render() {
    const { city } = this.props;
    const detail = city.detail || {};

    return (
      <Row gutter={16}>
        <Col span={12}>
          <Descriptions column={2} gutter={16}>
            <Descriptions.Item label="Название">{detail.name}</Descriptions.Item>
            <Descriptions.Item label="Дата создания"><DateTimeText date={detail.date_created}/></Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    city: state.city,
  };
}

export default connect(mapStateToProps)(CityDetail);
