import { put, takeEvery, select } from 'redux-saga/effects';
import { push, replace } from "connected-react-router";

import * as base from 'store/BaseSagas';
import * as reduxActions from './reduxActions';
import * as api from 'store/api/shift';


export function* watch() {
  yield takeEvery(reduxActions.SHIFT_LIST_LOAD.START, actionListLoad);
  yield takeEvery(reduxActions.SHIFT_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(reduxActions.SHIFT_UPDATE.START, actionUpdate);
  yield takeEvery(reduxActions.SHIFT_DELETE.START, actionDelete);
  yield takeEvery(reduxActions.SHIFT_SELECT, actionSelect);

  yield takeEvery(reduxActions.SHIFT_CURRENT_LOAD.START, actionCurrentLoad);
  yield takeEvery(reduxActions.SHIFT_CURRENT_UPDATE.START, actionCurrentUpdate);
}

function* actionSelect(action) {
  yield put(push(`/`));
}

export function* actionListLoad(action) {
  yield base.Request(action, api.shiftListLoad, reduxActions.actions.listLoad);
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.shiftDetailLoad, reduxActions.actions.detailLoad);
}

function* actionUpdate(action) {
  yield base.Request(action, api.shiftUpdate, reduxActions.actions.update,
    function* (data) {
      yield put(reduxActions.actions.showEditForm({ show: false }));
      if (!action.payload.preventLoadDetail) {
        yield put(reduxActions.actions.detailLoad({ id: data.id }));
        yield put(push(`/shift/${data.id}`));
      } else {
        const filter = yield select((state) => state.shift.filter);
        yield put(reduxActions.actions.listLoad(filter));
      }
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.shiftDelete, reduxActions.actions.delete,
    function* (data) {
      const filter = yield select((state) => state.shift.filter);
      yield put(replace('/shift'));
      yield put(reduxActions.actions.listLoad(filter));
    }
  );
}


export function* actionCurrentLoad(action) {
  yield base.Request(action, api.shiftCurrentLoad, reduxActions.actions.currentLoad);
}

function* actionCurrentUpdate(action) {
  yield base.Request(action, api.shiftCurrentUpdate, reduxActions.actions.currentUpdate,
    function* (data) {
      yield put(reduxActions.actions.showEditForm({ show: false }));
      if (action.payload.stop && data.active_orders > 0) {
        alert('У вас остались не завершённые заказы!');
        yield put(push(`/order`));
      } else {
        yield put(push(`/`));
      }
    }
  );
}
