import React from 'react';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';

import { DataLoadComponent } from 'components/common';


export default class ValueComponent extends DataLoadComponent {
  //apiDetailLoad = phoneDetailLoad;

  getValue() {
    const { id } = this.props;
    const { detail } = this.state;
    return (detail && detail.name) || id;
  }

  getPath() {
    const { detail } = this.state;
    const detailId = detail && detail.id;
    return `${detailId}`;
  }

  getLinkPath() {
    const { asLink, isBack, router } = this.props;
    let path, state;
    if (asLink) {
      path = this.getPath();
      state = { slideIn: true };
      if (isBack) {
        state.backUrl = router.location.pathname;
        state.backSearch = router.location.search
      }
      return { pathname: path, state: state }
    }
    return null;
  }

  render() {
    const { loadStatus } = this.state;
    const value = this.getValue();
    if (!value) return null;

    const path = this.getLinkPath();

    return (
      <Spin spinning={loadStatus.isLoading}>
        {path ?
          <Link to={path}>{value}</Link> :
          <span>{value}</span>}
      </Spin>
    );
  }
}
