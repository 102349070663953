import React from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { carDetailLoad } from "store/api/car";
import { DataLoadComponent } from 'components/common';
import { DriverValue } from "components/blocks/Driver";


class CarValue extends DataLoadComponent {
  apiDetailLoad = carDetailLoad;

  render() {
    const { id, asLink, state } = this.props;
    const { detail, loadStatus } = this.state;
    const driver = detail && detail.driver;
    const _id = (detail && detail.id) || id;

    return (
      <Spin spinning={loadStatus.isLoading}>
        {asLink && _id ?
          <Link to={{ pathname: `/car/${_id}`, state: state }}>
            {driver ? <DriverValue detail={driver}/>: _id}
          </Link> :
          <span>{driver ? <DriverValue detail={driver}/>: _id}</span>}
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(CarValue);
