import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Icon } from 'antd';

import { actions } from 'store';
import styles from './header_shift.module.css';


class HeaderOrderPanel extends Component {
  showOrderForm = () => {
    this.props.actions.order.showEditForm({ show: true })
  };

  render() {
    const { shift } = this.props;
    const currentShiftId = shift.current && shift.current.id;
    if (!currentShiftId) return null;

    return (
      <div className={styles.main}>
        <Button className={styles.orderBtn} type="primary" onClick={this.showOrderForm} size="large">
          <Icon className={styles.orderBtnIcon} type="plus"/>
          <span>Новый заказ</span>
        </Button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    shift: state.shift,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      order: bindActionCreators(Object.assign({}, actions.order), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderOrderPanel);
