import { put, select, takeEvery } from 'redux-saga/effects';
import { push, replace } from "connected-react-router";

import * as base from 'store/BaseSagas';
import * as reduxActions from './reduxActions';
import * as api from 'store/api/clientAddress';


export function* watch() {
  yield takeEvery(reduxActions.CLIENT_ADDRESS_LIST_LOAD.START, actionListLoad);
  yield takeEvery(reduxActions.CLIENT_ADDRESS_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(reduxActions.CLIENT_ADDRESS_UPDATE.START, actionUpdate);
  yield takeEvery(reduxActions.CLIENT_ADDRESS_DELETE.START, actionDelete);
  yield takeEvery(reduxActions.CLIENT_ADDRESS_SELECT, actionSelect);
}

function* actionSelect(action) {
  yield put(push(`/`));
}

export function* actionListLoad(action) {
  yield base.Request(action, api.clientAddressListLoad, reduxActions.actions.listLoad);
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.clientAddressDetailLoad, reduxActions.actions.detailLoad);
}

function* actionUpdate(action) {
  yield base.Request(action, api.clientAddressUpdate, reduxActions.actions.update,
    function* (data) {
      yield put(reduxActions.actions.showEditForm({ show: false }));
      if (!action.payload.preventLoadDetail) {
        yield put(reduxActions.actions.detailLoad({ id: data.id }));
        yield put(push(`/clientAddress/${data.id}`));
      } else {
        const filter = yield select((state) => state.clientAddress.filter);
        yield put(reduxActions.actions.listLoad(filter));
      }
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.clientAddressDelete, reduxActions.actions.delete,
    function* (data) {
      const filter = yield select((state) => state.clientAddress.filter);
      yield put(replace('/clientAddress'));
      yield put(reduxActions.actions.listLoad(filter));
    }
  );
}
