import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Icon, Popconfirm, Collapse } from 'antd';
import classNames from "classnames";

import { actions } from "store";
import { Status, ActionButton } from 'components/common';
import { ApartmentValue } from 'components/blocks/Apartment';
import { WorkerCard } from 'components/blocks/Worker';
import styles from "./card.module.css";


class ApartmentCard extends PureComponent {
  showEditForm = (item) => {
    this.props.actions.apartment.showEditForm({ show: true, object: item })
  };

  stop = (item) => {
    this.props.actions.apartment.update({ id: item.id, is_closed: true })
  };

  orderApartmentReject = (orderApartment) => {
    this.props.actions.orderApartment.update({ id: orderApartment.id, status: Status.REJECTED });
  };

  showOrderWorkerEditFrom = (orderId, worker) => {
    this.props.actions.orderWorker.showEditForm({ show: true, editWorker: worker, orderId });
  };

  render() {
    const { className, mode, style, detail, orderApartment, orderWorkers, showActions, isManager } = this.props;
    if (!detail || !detail.id) return null;

    const status = orderApartment && orderApartment.status;
    const isCanceled = [Status.CANCELED, Status.REJECTED, Status.RETURN].includes(status);
    let carStatus;
    if (mode === 'simple') {
      if (isCanceled) {
        carStatus = status;
      }
    } else {
      carStatus = status || (detail.address.is_busy && Status.APARTMENT_ASSIGNED);
    }

    const cx = classNames([
      styles.container,
      styles[`mode--${mode}`],
      isCanceled && styles.isCanceled,
      className,
    ]);

    let apartmentActions = [];
    if (isManager && orderApartment && orderApartment.status === Status.APARTMENT_ASSIGNED) {
      apartmentActions.push(
        <Popconfirm placement="bottomLeft"
                    title="Уверены, что хотите отменить апартаменты?" okText="Отказ" cancelText="Не надо"
                    onConfirm={() => this.orderApartmentReject(orderApartment)}>
          <Button size="small"><Icon type="stop"/>Отказ</Button>
        </Popconfirm>
      );
    }

    let workers = detail.workers.slice();
    if (orderWorkers) {
      const orderWorkerStatuses = orderWorkers.reduce((result, item) => {
        result[item.worker.id] = item.status;
        return result;
      }, {});

      workers = workers.map(worker => {
        if (worker.id in orderWorkerStatuses) {
          worker.status = orderWorkerStatuses[worker.id];
        } else if (worker.is_busy) {
          worker.status = 200;
        }
        return worker;
      });
    }

    return (
      <div className={cx} style={style}>
        <Collapse
          className="ant-collapse-simple"
          defaultActiveKey={mode === 'simple' ? [] : ['1']}
        >
          <Collapse.Panel
            key="1"
            disabled={isCanceled}
            header={(
              <div className={styles.header}>
                <Icon className={styles.headerIcon} type="environment"/>
                <div className={styles.headerMain}>
                  <div className={styles.headerTitle}>{<ApartmentValue detail={detail}/>}</div>
                  {carStatus &&
                  <Status className={styles.status} status={carStatus} size={mode === 'simple' ? 'small' : ''}/>}
                </div>
                <div className={styles.headerActions}>
                  {apartmentActions}
                  {isManager && showActions && <ActionButton actions={[
                    { title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(detail) },
                    {
                      title: 'Завершить', key: 'stop', icon: 'stop', action: () => this.stop(detail),
                      confirm: {
                        title: 'Уверены, что хотите завершить?',
                        okText: 'Завершить',
                        cancelText: 'Отмена'
                      }
                    }]}
                  />}
                </div>
              </div>
            )}
          >
            <div className={styles.content}>
              {workers.map(worker =>
                <WorkerCard
                  key={`worker${worker.id}`}
                  style={{ marginBottom: '8px' }}
                  detail={worker}
                  size={mode === 'simple' ? 'xs': 'small'}
                  workerActions={
                    [
                      Status.APARTMENT_ASSIGNED, Status.APPROVED, Status.PROLONGED,
                      Status.CANCELED, Status.RETURN
                    ].includes(status) &&
                    <Button size="small" onClick={() => this.showOrderWorkerEditFrom(orderApartment.order, worker)}>
                      <Icon type="check"/>Назначить
                    </Button>
                  }
                  isManager={isManager}
                />
              )}
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      apartment: bindActionCreators(Object.assign({}, actions.apartment), dispatch),
      orderApartment: bindActionCreators(Object.assign({}, actions.orderApartment), dispatch),
      orderWorker: bindActionCreators(Object.assign({}, actions.orderWorker), dispatch),
    }
  }
}

export default connect(null, mapDispatchToProps)(ApartmentCard);
