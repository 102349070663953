import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router';
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Layout } from 'antd';
import classNames from 'classnames';

import { Header, Navigation } from 'components/layout';
import { OrderForm } from "components/blocks/Order";
import SettingsScreen from 'screens/Settings';
import DashboardScreen from 'screens/DashboardScreen';
import CityScreen from 'screens/City';
import ClientScreen from 'screens/Client';
import ClientAddressScreen from 'screens/ClientAddress';
import WorkerScreen from 'screens/Worker';
import DriverScreen from 'screens/Driver';
import UserScreen from 'screens/User';
import UserAuditScreen from 'screens/UserAudit';
import ProfileScreen from 'screens/Profile';
import ShiftScreen from 'screens/Shift';
import OrderScreen from 'screens/Order';
import AgencyScreen from 'screens/Agency';
import ServiceScreen from 'screens/Service';


class MainScreen extends Component {
  state = {
    collapsed: false,
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  render() {
    const { history, currentUser } = this.props;

    return (
      <Layout>
        <Header history={history} />

        <Layout className="app-layout">
          <Layout.Sider
            breakpoint="lg"
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
            className="app-layout-sider"
          >
            <Navigation history={history} />
          </Layout.Sider>

          <Layout.Content className="app-layout-content">
            <Route
              render={({ location }) => {
                const { pathname, state } = location;
                const cx = classNames({
                  'page-wrap': true,
                  'page-wrap--slide-out': state && state.slideOut,
                  'page-wrap--slide-in': state && state.slideIn,
                });
                return (
                  <TransitionGroup className={cx}>
                    <CSSTransition
                      key={pathname}
                      classNames="page"
                      timeout={{
                        enter: 500,
                        exit: 800,
                      }}
                    >
                      <Route
                        location={location}
                        render={() => (
                          <Switch>
                            <Route exact path="/" component={DashboardScreen} />

                            <Route exact path="/order" component={OrderScreen} />
                            <Route path="/order/status/:status" component={OrderScreen} />
                            <Route path="/order/:orderId" component={OrderScreen} />

                            <Route exact path="/worker" component={WorkerScreen} />
                            <Route path="/worker/:workerId" component={WorkerScreen} />

                            <Route exact path="/driver" component={DriverScreen} />
                            <Route path="/driver/:driverId" component={DriverScreen} />

                            <Route exact path="/shift" component={ShiftScreen} />
                            <Route path="/shift/:shiftId" component={ShiftScreen} />

                            <Route exact path="/settings" component={SettingsScreen} />

                            {currentUser.role === 'administrator' &&
                              <Fragment>
                                {!currentUser.city &&
                                  <Fragment>
                                    <Route exact path="/city" component={CityScreen} />
                                    <Route path="/city/:cityId" component={CityScreen} />
                                    <Route exact path="/audit" component={UserAuditScreen} />
                                  </Fragment>}

                                <Route exact path="/agency" component={AgencyScreen} />
                                <Route path="/agency/:agencyId" component={AgencyScreen} />

                                <Route exact path="/client" component={ClientScreen} />
                                <Route path="/client/:clientId" component={ClientScreen} />

                                <Route exact path="/clientAddress" component={ClientAddressScreen} />
                                <Route path="/clientAddress/:clientAddressId" component={ClientAddressScreen} />

                                <Route exact path="/profile" component={ProfileScreen} />
                                <Route path="/profile/:profileId" component={ProfileScreen} />

                                <Route exact path="/user" component={UserScreen} />
                                <Route path="/user/:userId" component={UserScreen} />

                                <Route exact path="/service" component={ServiceScreen} />
                                <Route path="/service/:serviceId" component={ServiceScreen} />
                              </Fragment>}
                          </Switch>
                        )}
                      />
                    </CSSTransition>
                  </TransitionGroup>
                )
              }}
            />

            <OrderForm />
          </Layout.Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.fields,
  };
}

export default connect(mapStateToProps)(MainScreen);
