import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Icon } from "antd";

import { actions } from "store";
import { tableColumn, arrayIsEqual } from "utils";
import { TableList, ActionButton, DateTimeText, Status } from "components/common";
import { AgencySelect, AgencyValue } from "components/blocks/Agency";
import { ClientValue } from "components/blocks/Client";
import { ApartmentCard } from "components/blocks/Apartment";
import { CarCard } from "components/blocks/Car";
import { WorkerCard } from "components/blocks/Worker";
import { UserValue } from "components/blocks/User";
import OrderValue from "../OrderValue";
import styles from './list.module.css';


class OrderList extends Component {
  state = {
    updateItemId: null,
    columnList: ['id', 'dates', 'client', 'car', 'worker', 'actions'],
    columnByName: {
      id: tableColumn('ID', 'id', {
        render: (val, item) => (
          <div>
            <OrderValue id={val} asLink={true} isBack={true} />
            <DateTimeText date={item.date_created} size="small" />
            <Status status={item.status} size="small" />
          </div>
        ),
        sorter: true,
        width: 130,
      }),
      dates: tableColumn(<Icon type="clock-circle" />, 'dates', {
        render: (val, item) => (
          <div style={{ fontSize: '13px' }}>
            <div>Начало: <DateTimeText date={item.date_approved} mode="time" inline={true} /></div>
            <div>План: <DateTimeText date={item.date_planned} mode="time" inline={true} /></div>
            <div>Заверш.: <DateTimeText date={item.date_completed} mode="time" inline={true} /></div>
          </div>
        ),
        width: 130,
      }),
      client: tableColumn('Клиент', 'client', {
        render: (val, item) => (
          <div>
            <ClientValue detail={val} asLink={true} isBack={true} />
            <div style={{ fontSize: '13px' }}>{item.address && item.address.address}</div>
            <div style={{ fontSize: '13px' }}>
              <Icon type="clock-circle" /> {item.real_durations[0]}ч
              {item.real_durations[1] > 0 && ` + ${item.real_durations[1]}ч`}
            </div>
          </div>
        ),
        width: 140,
      }),
      car: tableColumn('Машина/Апарт.', 'car', {
        render: (val, item) => {
          const { currentUser } = this.props;
          const isManager = currentUser.role === 'manager';

          let actions = [];
          if (
            isManager &&
            [Status.NEW, Status.CAR_ASSIGNED, Status.REJECTED, Status.RETURN].includes(item.status)
          ) {
            actions.push(
              <Button
                className={styles.cItem}
                key="carAdd"
                type="default"
                size="small"
                onClick={() => this.showOrderCarEditForm(item)}
              >
                <Icon type="car" />
                <span>Назначить машину</span>
              </Button>
            );
          }
          if (
            isManager &&
            [Status.NEW, Status.REJECTED, Status.RETURN].includes(item.status)
          ) {
            actions.push(
              <Button
                className={styles.cItem}
                key="apartmentAdd"
                type="default"
                size="small"
                onClick={() => this.showOrderApartmentEditForm(item)}
              >
                <Icon type="environment" />
                <span>Назначить апартаменты</span>
              </Button>
            )
          }

          const apartments = item.apartments.map(item =>
            <ApartmentCard
              className={styles.cItem}
              key={`apartment${item.id}`}
              mode="simple"
              detail={item.apartment}
              orderApartment={item}
              isManager={isManager}
            />
          );

          const cars = item.cars.map(item => (
            <CarCard
              className={styles.cItem}
              key={`car${item.id}`}
              mode="simple"
              detail={item.car}
              orderCar={item}
              isManager={isManager}
            />
          ));

          return (
            <div>
              {apartments}
              {cars}
              {actions}
            </div>
          )
        },
        width: 240,
      }),
      worker: tableColumn('Девушка', 'worker', {
        render: (val, item) => {
          const { currentUser } = this.props;
          const isManager = currentUser.role === 'manager';

          return (
            <div>
              {item.workers.map(item => (
                <WorkerCard
                  className={styles.cItem}
                  key={`worker${item.id}`}
                  size="xs"
                  detail={item.worker}
                  orderWorker={item}
                  isManager={isManager}
                />
              ))}
            </div>
          )
        },
        width: 120,
      }),
      manager: tableColumn('Менеджер', 'manager', {
        render: (val, item) => <UserValue detail={val} asLink={true} isBack={true} />,
        width: 120,
      }),
      status: tableColumn('Статус', 'status', {
        render: (val, item) => <Status status={val} />,
        width: 120,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        width: 50,
        render: (val, item) => {
          const { currentUser } = this.props;
          const isAdmin = currentUser.role === 'administrator';

          let actions = [
            { title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item) }
          ];

          if (isAdmin && [0, 10, 11, 20, 21].includes(item.status)) {
            actions.push(
              {
                title: 'Завершить',
                className: 'green',
                key: 'close',
                icon: 'close-circle',
                action: () => this.itemUpdate({ id: item.id, status: 29, preventLoadDetail: true }),
              }
            );
            actions.push(
              {
                title: 'Отменить',
                className: 'red',
                key: 'cancel',
                icon: 'close-circle',
                action: () => this.itemUpdate({ id: item.id, status: 30, preventLoadDetail: true }),
              }
            );
          }

          return (
            <ActionButton actions={actions} />
          )
        },
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.order.listLoad({ query, activeFilter, locationChange: true });
  };

  showEditForm = (item) => {
    this.props.actions.order.showEditForm({ show: true, object: item })
  };

  itemUpdate = (data) => {
    this.setState({
      updateItemId: data.id,
    });
    this.props.actions.order.update(data);
  };


  showOrderApartmentEditForm = (order, item = null) => {
    const { actions } = this.props;
    actions.orderApartment.showEditForm({ show: true, object: item, order });
  };

  showOrderCarEditForm = (order, item = null) => {
    const { actions } = this.props;
    actions.orderCar.showEditForm({ show: true, object: item, order });
  };

  render() {
    const { order, defaultFilter, activeFilter, history, currentUser, cityActiveId } = this.props;
    const { columnList, columnByName } = this.state;
    const isAdmin = currentUser.role === 'administrator';

    return (
      <TableList
        history={history}
        data={order}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        showSearch={true}
        filterFields={isAdmin && {
          list: [
            'agency', 'status'
          ],
          byName: {
            'agency': {
              label: 'Агентство',
              type: 'list_int',
              component: AgencySelect,
              valueComponent: AgencyValue,
              activeFilter: { city: cityActiveId },
            },
            'status': {
              label: 'Статус',
              type: 'list_single',
              valueComponent: Status,
              options: [
                { label: 'Новые', value: '0' },
                { label: 'Назначена машина', value: '10' },
                { label: 'Назначены апартаменты', value: '11' },
                { label: 'Принято', value: '20' },
                { label: 'Продлено', value: '21' },
                { label: 'Завершено', value: '29' },
                { label: 'Отбой', value: '30' },
                { label: 'Отказ', value: '31' },
                { label: 'Возврат', value: '32' },
              ]
            },
          }
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    cityActiveId: state.city.activeId,
    currentUser: state.user.fields,
    order: state.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      order: bindActionCreators(Object.assign({}, actions.order), dispatch),
      orderApartment: bindActionCreators(Object.assign({}, actions.orderApartment), dispatch),
      orderCar: bindActionCreators(Object.assign({}, actions.orderCar), dispatch),
      orderWorker: bindActionCreators(Object.assign({}, actions.orderWorker), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
