import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { arrayIsEqual, tableColumn } from "utils";
import { TableList, ActionButton, DateTimeText, OnOff, ActiveStatus } from 'components/common';
import { CityValue, CitySelect } from 'components/blocks/City';
import { AgencyValue, AgencySelect } from 'components/blocks/Agency';
import UserValue from "../UserValue";


class UserList extends Component {
  state = {
    updateItemId: null,
    columnList: ['id', 'email', 'first_name', 'role', 'city', 'agency', 'is_online', 'is_active', 'actions'],
    columnByName: {
      id: tableColumn('ID', 'id', {
        sorter: true,
        width: 70,
      }),
      email: tableColumn('E-mail', 'email', {
        render: (val, item) => <UserValue detail={item} asLink={true} asEmail={true} isBack={true} />,
        sorter: true,
      }),
      first_name: tableColumn('Имя', 'first_name', {
        sorter: true,
        width: 150,
      }),
      last_name: tableColumn('Фамилия', 'last_name', {
        sorter: true,
        width: 150,
      }),
      role: tableColumn('Роль', 'role', {
        width: 150,
      }),
      city: tableColumn('Город', 'city', {
        render: (val, item) => <CityValue id={val} asLink={true} isBack={true} />,
        sorter: true,
        width: 150,
      }),
      agency: tableColumn('Агентство', 'agency', {
        render: (val, item) => <AgencyValue detail={val} asLink={true} isBack={true} />,
        sorter: true,
        width: 150,
      }),
      is_online: tableColumn('Онлайн', 'is_online', {
        render: (val, item) => (
          <div>
            <ActiveStatus
              active={val}
              titles={['онлайн', 'оффлайн', 'выйти']}
              buttonSize="small"
              onAction={() => {
                this.itemUpdate({ id: item.id, is_expired: true, preventLoadDetail: true });
              }}
            />
            <div style={{ fontSize: '12px' }}>
              Последний вход: <DateTimeText date={item.last_login} />
            </div>
          </div>
        ),
        sorter: true,
        width: 160,
      }),
      is_active: tableColumn('Активен', 'is_active', {
        sorter: true,
        width: 150,
        render: (val, item) => <OnOff
          param="is_active"
          preventLoadDetail={true}
          item={item}
          updateItemId={this.state.updateItemId}
          updateStatus={this.props.user.updateStatus}
          onChange={this.itemUpdate}

        />,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }, {
              title: 'Удалить', key: 'delete', icon: 'delete', action: () => this.delete(item),
              confirm: {
                title: 'Уверены, что хотите удалить?',
                okText: 'Удалить',
                cancelText: 'Отмена'
              }
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    const { user } = this.props;
    if (activeFilter === undefined) {
      activeFilter = user.filter.activeFilter
    }
    this.props.actions.user.listLoad({ query, activeFilter, locationChange: true });
  };

  showEditForm = (item) => {
    this.props.actions.user.showEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.user.delete({ id: item.id })
  };

  itemUpdate = (data) => {
    this.setState({
      updateItemId: data.id,
    });
    this.props.actions.user.update(data);
  };

  render() {
    const { user, defaultFilter, activeFilter, history, topActions } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={user}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        showSearch={true}
        topActions={topActions}
        filterFields={{
          list: [
            'is_active', 'is_online', 'role', 'city', 'agency',
          ],
          byName: {
            'is_active': {
              label: 'Активно',
              type: 'bool',
            },
            'is_online': {
              label: 'Онлайн',
              type: 'bool',
            },
            'role': {
              label: 'Роль',
              type: 'list',
              options: [
                { label: 'Менеджер', value: 'manager' },
                { label: 'Администратор', value: 'administrator' },
              ]
            },
            'city': {
              label: 'Город',
              type: 'list_int',
              component: CitySelect,
              valueComponent: CityValue,
            },
            'agency': {
              label: 'Агентство',
              type: 'list_int',
              component: AgencySelect,
              valueComponent: AgencyValue,
            },
          }
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      user: bindActionCreators(Object.assign({}, actions.user), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
