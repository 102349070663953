import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { actions } from "store";
import { getFullNameOrEmail } from "utils";
import { PageHeader } from "components/layout";
import { Loader } from "components/common";
import { ShiftDetail } from "components/blocks/Shift";


class ShiftCurrentScreen extends Component {
  componentDidMount() {
    const { activeCityId } = this.props;
    this.props.actions.shift.currentLoad({ activeFilter: { city: activeCityId } });
  }

  render() {
    const { history, shift, currentUser } = this.props;
    const detail = shift.current;
    const isActive = detail && detail.id;
    const manager = isActive ? detail.manager: currentUser;

    return (
      <div className="page-inner">
        <PageHeader
          mode="detail"
          history={history}
          title={isActive ? `Смена №${detail.id}`: `Смена не открыта`}
          info={`Диспетчер: ${getFullNameOrEmail(manager)}`}
        />

        <div className="page-main">
          {isActive ?
            <ShiftDetail detail={detail}/> :
            null
          }
        </div>

        <Loader active={shift.currentLoadStatus.isLoading}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeCityId: state.city.activeId,
    currentUser: state.user.fields,
    shift: state.shift,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      shift: bindActionCreators(Object.assign({}, actions.shift), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftCurrentScreen);
