import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { actions } from "store";
import { Descriptions, DateTimeText, OnOff } from 'components/common';
import { CityValue } from 'components/blocks/City';
import { AgencyValue } from 'components/blocks/Agency';


class UserDetail extends Component {
  itemUpdate = (data) => {
    this.props.actions.user.update(data);
  };

  render() {
    const { user } = this.props;
    const detail = user.detail || {};

    return (
      <Row gutter={16}>
        <Col span={12}>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Город">
              {detail.city && <CityValue id={detail.city} asLink={true} isBack={true} />}
            </Descriptions.Item>
            <Descriptions.Item label="Агентство">
              {detail.agency && <AgencyValue detail={detail.agency} asLink={true} isBack={true} />}
            </Descriptions.Item>
            <Descriptions.Item label="E-mail">{detail.email}</Descriptions.Item>
            <Descriptions.Item label="Имя">{detail.first_name}</Descriptions.Item>
            <Descriptions.Item label="Роль">{detail.role}</Descriptions.Item>
            <Descriptions.Item label="Активный">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_active"
                updateStatus={user.updateStatus}
                onChange={this.itemUpdate}
              />
            </Descriptions.Item>

            <Descriptions.Item label="Дата создания"><DateTimeText date={detail.date_joined} /></Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      user: bindActionCreators(Object.assign({}, actions.user), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
