import React from 'react';
import classNames from 'classnames';
import { Button } from 'antd';

import styles from './status.module.css';


export default ({ active, titles, onAction, buttonSize, buttonType }) => {
  const cx = classNames([
    styles.main,
    active && styles.active,
  ]);

  const btnSize = buttonSize || "default";
  const btnType = buttonType || "default";

  return (
    <div className={cx}>
      <div className={styles.icon} />
      <div className={styles.title}>{active ? titles[0] : titles[1]}</div>
      {active && <Button type={btnType} size={btnSize} onClick={onAction}>{titles[2]}</Button>}
    </div>
  );
};
