import React, { Component } from 'react';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import OrderListScreen from './OrderListScreen';
import OrderDetailScreen from './OrderDetailScreen';


export default class OrderScreen extends Component {
  render() {
    return (
      <Page>
        <Route exact path="/order" component={OrderListScreen}/>
        <Route exact path="/order/status/:status" component={OrderListScreen}/>
        <Route exact path="/order/:orderId" component={OrderDetailScreen}/>
        <Route exact path="/order/:orderId/history" component={OrderDetailScreen}/>
        <Route exact path="/order/:orderId/order" component={OrderDetailScreen}/>
      </Page>
    );
  }
}
