import { put, call, takeEvery, all, select } from 'redux-saga/effects';

import * as reduxActions from './reduxActions';
import { reduxActions as AuthActions } from 'store/auth';
import { sagas as UserSagas } from 'store/user';
import { sagas as CitySagas, reduxActions as CityReduxActions } from 'store/city';
import { sagas as ShiftSagas } from 'store/shift';
import { getToken } from 'utils/helpers';
import { apiAuthTokenCheck } from 'store/api/auth';


export function* watch() {
  yield takeEvery(reduxActions.APP_INIT, appInit);
  yield takeEvery(reduxActions.APP_DATA_LOAD.START, appDataLoad);
}


function* appInit(action) {
  const tokenOld = getToken();
  const token = yield call(() => apiAuthTokenCheck(tokenOld));
  yield put(reduxActions.actions.dataLoad({ token, authorize: true }));
}


function* appDataLoad(action) {
  yield put(reduxActions.actions.dataLoad.request());
  const { token, authorize } = action.payload;

  try {
    if (token) {
      if (authorize) yield put(AuthActions.actions.login.complete({ token }));

      yield all([
        call(UserSagas.currentUserLoad, { token }),
        call(CitySagas.actionListLoad, { payload: {} }),
      ]);
      const currentUser = yield select((state) => state.user.fields);
      if (currentUser.role === 'manager') {
        const cityActive = currentUser.city;
        yield put(CityReduxActions.actions.select(cityActive));
        //const cityActive = yield select((state) => state.city.activeId);
        yield call(ShiftSagas.actionCurrentLoad, { payload: { activeFilter: { city: cityActive } } });
      }

      yield put(reduxActions.actions.dataLoad.complete());
    } else {
      yield put(reduxActions.actions.dataLoad.complete());
    }

  } catch (error) {
    yield put(reduxActions.actions.dataLoad.error());
  }
}
