import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const APARTMENT_LIST_LOAD = createRequestTypes('APARTMENT_LIST_LOAD');
export const APARTMENT_DETAIL_LOAD = createRequestTypes('APARTMENT_DETAIL_LOAD');
export const APARTMENT_UPDATE = createRequestTypes('APARTMENT_UPDATE');
export const APARTMENT_DELETE = createRequestTypes('APARTMENT_DELETE');
export const APARTMENT_SHOW_EDIT_FORM = 'APARTMENT_SHOW_EDIT_FORM';
export const APARTMENT_SELECT = 'APARTMENT_SELECT';


export const actions = {
  showEditForm: createAction(APARTMENT_SHOW_EDIT_FORM),
  select: createAction(APARTMENT_SELECT),

  listLoad: createRequestActions(APARTMENT_LIST_LOAD),
  detailLoad: createRequestActions(APARTMENT_DETAIL_LOAD),
  update: createRequestActions(APARTMENT_UPDATE),
  delete: createRequestActions(APARTMENT_DELETE),
};
