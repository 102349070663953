import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { Page } from 'components/layout';
import ShiftCurrentScreen from "screens/Shift/ShiftCurrentScreen";
import AnalyticsScreen from 'screens/Analytics';


class DashboardScreen extends Component {
  render() {
    const { currentUser } = this.props;

    return (
      <Page>
        {currentUser.role === 'manager' && <ShiftCurrentScreen/>}
        {currentUser.role === 'administrator' && <AnalyticsScreen/>}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    city: state.city,
    currentUser: state.user.fields,
  };
}


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.city), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);
