import React from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';

import { phoneListLoad } from "store/api/phone";
import { FormItem, loadStates, loadStatus } from "utils";
import { DataLoadComponent } from 'components/common';


class PhoneSelect extends DataLoadComponent {
  apiListLoad = phoneListLoad;

  listLoad = () => {
    const { auth_token, activeFilter, instance, name } = this.props;
    const value = (instance && instance[name]) || this.props.initialValue;

    this.setState({
      loadStatus: loadStatus(loadStates.loading),
    });

    this.apiListLoad(auth_token, { activeFilter, query: '?limit=1000' })
      .then(({ error, data }) => {
        if (error) {
          this.setState({
            loadStatus: loadStatus(loadStates.failed, error),
          });
        } else {
          this.setState({
            items: data.results,
            loadStatus: loadStatus(loadStates.loaded),
          });
          if (value) {
            const item = data.results.filter(item => item.id === value)[0];
            this.props.onChange && this.props.onChange(value, item)
          }
        }
      })
  };

  render() {
    const { form, instance, name, label, required, errorData, disabled, onChange, layout, showAgency } = this.props;
    const { items, loadStatus } = this.state;
    const initialValue = instance && instance.id ? null : this.props.initialValue;

    return (
      <FormItem
        form={form}
        instance={instance}
        name={name}
        label={label}
        required={required}
        errorData={errorData}
        initialValue={initialValue}
        convertValue={(obj) => (obj && obj.id) || obj}
        layout={layout}
      >
        <Select
          style={{ width: 200 }}
          loading={loadStatus.isLoading}
          onChange={(value, opt) => onChange && onChange(value, opt.props.item)}
          showSearch={true}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.item.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          disabled={disabled}
        >
          {!required && <Select.Option value={null}>---</Select.Option>}
          {items.map(item => (
            <Select.Option key={`phone${item.id}`} value={item.id} item={item}>
              {item.name} {showAgency && <span style={{ color: '#aaa' }}>({item.agency.name})</span>}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    );
  }
}


function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(PhoneSelect);
