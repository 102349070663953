import { connect } from 'react-redux';

import { userDetailLoad } from "store/api/user";
import { getFullNameOrEmail } from "utils";
import { ValueComponent } from 'components/common';


class UserValue extends ValueComponent {
  apiDetailLoad = userDetailLoad;

  getValue() {
    const { id, asEmail } = this.props;
    const { detail } = this.state;
    if (asEmail) {
      return (detail && detail.email) || id;
    }
    return (detail && getFullNameOrEmail(detail)) || id;
  }

  getPath() {
    const { detail } = this.state;
    const detailId = detail && detail.id;
    return `/user/${detailId}`;
  }
}

function mapStateToProps(state) {
  return {
    router: state.router,
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(UserValue);
