import { put, takeEvery, select, call } from 'redux-saga/effects';
import { push } from "connected-react-router";

import * as base from 'store/BaseSagas';
import * as reduxActions from './reduxActions';
import { sagas as ShiftSagas } from 'store/shift';
import * as api from 'store/api/car';


export function* watch() {
  yield takeEvery(reduxActions.CAR_LIST_LOAD.START, actionListLoad);
  yield takeEvery(reduxActions.CAR_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(reduxActions.CAR_UPDATE.START, actionUpdate);
  yield takeEvery(reduxActions.CAR_DELETE.START, actionDelete);
  yield takeEvery(reduxActions.CAR_SELECT, actionSelect);
}

function* actionSelect(action) {
  yield put(push(`/`));
}

export function* actionListLoad(action) {
  yield base.Request(action, api.carListLoad, reduxActions.actions.listLoad);
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.carDetailLoad, reduxActions.actions.detailLoad);
}

function* actionUpdate(action) {
  yield base.Request(action, api.carUpdate, reduxActions.actions.update,
    function* (data) {
      yield put(reduxActions.actions.showEditForm({ show: false }));
      const filter = yield select((state) => state.car.filter);
      yield put(reduxActions.actions.listLoad(filter));

      const cityActive = yield select((state) => state.city.activeId);
      yield call(ShiftSagas.actionCurrentLoad, { payload: { activeFilter: { city: cityActive } } });
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.carDelete, reduxActions.actions.delete,
    function* (data) {
      const filter = yield select((state) => state.car.filter);
      yield put(reduxActions.actions.listLoad(filter));
    }
  );
}
