import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { PageHeader } from "components/layout";
import { UserAuditList } from "components/blocks/UserAudit";


class UserAuditListScreen extends Component {
  showEditForm = () => {
    this.props.actions.userAudit.showEditForm({ show: true })
  };

  render() {
    return (
      <div className="page-inner">
        <PageHeader
          title="Лог входов"
          breadcrumbs={null}
        />

        <div className="page-main">
          <UserAuditList/>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userAudit: state.userAudit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      userAudit: bindActionCreators(Object.assign({}, actions.userAudit), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAuditListScreen);
