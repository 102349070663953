import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const ADDRESS_LIST_LOAD = createRequestTypes('ADDRESS_LIST_LOAD');
export const ADDRESS_DETAIL_LOAD = createRequestTypes('ADDRESS_DETAIL_LOAD');
export const ADDRESS_UPDATE = createRequestTypes('ADDRESS_UPDATE');
export const ADDRESS_DELETE = createRequestTypes('ADDRESS_DELETE');
export const ADDRESS_SHOW_EDIT_FORM = 'ADDRESS_SHOW_EDIT_FORM';
export const ADDRESS_SELECT = 'ADDRESS_SELECT';


export const actions = {
  showEditForm: createAction(ADDRESS_SHOW_EDIT_FORM),
  select: createAction(ADDRESS_SELECT),

  listLoad: createRequestActions(ADDRESS_LIST_LOAD),
  detailLoad: createRequestActions(ADDRESS_DETAIL_LOAD),
  update: createRequestActions(ADDRESS_UPDATE),
  delete: createRequestActions(ADDRESS_DELETE),
};
