import React, { Component } from 'react';

import { DriverSelect } from "components/blocks/Driver";
import { WorkerTransferField } from "components/blocks/Worker";


export default class MainFieldSet extends Component {
  state = {
    layout: 'horizontal',
  };

  render() {
    const { form, instance, errorData, city } = this.props;
    const { layout } = this.state;
    const itemProps = { form, instance, errorData };

    const carId = instance && parseInt(instance.id);

    return (
      <div className="form-block">
        <DriverSelect
          name="driver" label="Водитель" required={true} layout={layout}
          activeFilter={{ free: true, car: carId, city: city.activeId }}
          {...itemProps}
        />

        <WorkerTransferField
          name="workers" label="Девушки" required={true}
          activeFilter={{ free: true, car: carId, city: city.activeId }}
          {...itemProps}
        />
      </div>
    );
  }
}
