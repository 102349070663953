import React, { PureComponent } from 'react';
import classNames from "classnames";

import styles from './status.module.css';

export const STATUS = {
  0: 'новое',
  10: 'назначена машина',
  11: 'назначены апартаменты',

  20: 'принято',
  21: 'продлено',
  29: 'завершено',

  30: 'отбой',
  31: 'отказ',
  32: 'возврат',

  200: 'занята',
};

export default class Status extends PureComponent {
  static NEW = 0;
  static CAR_ASSIGNED = 10;
  static APARTMENT_ASSIGNED = 11;

  static APPROVED = 20;
  static PROLONGED = 21;
  static COMPLETED = 29;

  static CANCELED = 30;
  static REJECTED = 31;
  static RETURN = 32;

  render() {
    const { id, status, className, size } = this.props;
    const value = id || status;

    const cx = classNames([
      styles.status,
      styles[`s${value}`],
      size && styles[`size--${size}`],
      className,
    ]);

    return (
      <div className={cx}>{STATUS[value] || value}</div>
    );
  }
}
