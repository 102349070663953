import React, { Component } from 'react';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import ClientAddressListScreen from './ClientAddressListScreen';
import ClientAddressDetailScreen from './ClientAddressDetailScreen';


export default class ClientAddressScreen extends Component {
  render() {
    return (
      <Page>
        <Route exact path="/clientAddress" component={ClientAddressListScreen}/>
        <Route exact path="/clientAddress/:clientAddressId" component={ClientAddressDetailScreen}/>
        <Route exact path="/clientAddress/:clientAddressId/history" component={ClientAddressDetailScreen}/>
        <Route exact path="/clientAddress/:clientAddressId/order" component={ClientAddressDetailScreen}/>
      </Page>
    );
  }
}

