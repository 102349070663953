import React, { Component } from 'react';
import { Button, Modal, Form, Spin } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { Error } from 'components/common';
import MainFieldSet from "./MainFieldSet";


class OrderCarForm extends Component {
  state = {
    layout: 'horizontal',
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { orderCar } = this.props;
        const instance = orderCar.editObject;
        const order = orderCar.editOrder;

        let data = Object.assign({order: order.id}, values);
        if (instance && instance.id) {
          data.id = instance.id;
        }
        this.props.actions.orderCar.update(data);
      }
    });
  };

  close = () => {
    this.props.actions.orderCar.showEditForm({ show: false });
  };

  render() {
    const { orderCar } = this.props;
    const { showEditForm, updateStatus, editObject } = orderCar;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const isLoading = orderCar.updateStatus.isLoading;

    return (
      <Modal
        width={640}
        visible={showEditForm}
        title={instance ? `Редактирование машины ${instance.name}` : 'Выберите свободную машину'}
        onCancel={this.close}
        maskClosable={false}
        destroyOnClose={true}
        footer={[
          <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit} size="large">
            Сохранить
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form onSubmit={this.handleSubmit} autoComplete="off"
                onKeyPress={(e) => e.key === 'Enter' && this.handleSubmit(e)}>
            <Error errors={errorData.non_field_errors}/>

            <MainFieldSet errorData={errorData} instance={instance} {...this.props}/>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const WrappedForm = Form.create()(OrderCarForm);

function mapStateToProps(state) {
  return {
    currentUser: state.user.fields,
    city: state.city,
    orderCar: state.orderCar,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      orderCar: bindActionCreators(Object.assign({}, actions.orderCar), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm);

