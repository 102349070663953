import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const CITY_LIST_LOAD = createRequestTypes('CITY_LIST_LOAD');
export const CITY_DETAIL_LOAD = createRequestTypes('CITY_DETAIL_LOAD');
export const CITY_UPDATE = createRequestTypes('CITY_UPDATE');
export const CITY_DELETE = createRequestTypes('CITY_DELETE');
export const CITY_SHOW_EDIT_FORM = 'CITY_SHOW_EDIT_FORM';
export const CITY_SELECT = 'CITY_SELECT';


export const actions = {
  showEditForm: createAction(CITY_SHOW_EDIT_FORM),
  select: createAction(CITY_SELECT),

  listLoad: createRequestActions(CITY_LIST_LOAD),
  detailLoad: createRequestActions(CITY_DETAIL_LOAD),
  update: createRequestActions(CITY_UPDATE),
  delete: createRequestActions(CITY_DELETE),
};
