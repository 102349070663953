import { BASE_URL } from "config";

import * as base from './_base';


export const endpoints = {
  list: `${BASE_URL}/api/v1/geo/city/`,
  detail: (id) => `${BASE_URL}/api/v1/geo/city/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/geo/city/${id}/history/`,
};

export const cityListLoad = (token, payload) => base.ListLoad(endpoints.list, token, payload);
export const cityDetailLoad = (token, { id }) => base.DetailLoad(endpoints.detail, token, { id });
export const cityUpdate = (token, data) => base.Update(endpoints.list, endpoints.detail, token, data);
export const cityDelete = (token, data) => base.Delete(endpoints.detail, token, data);
export const historyLoad = (token, payload) => base.DetailLoad(endpoints.history, token, { id: payload.id });
