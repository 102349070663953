import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ResponsiveContainer, Tooltip, PieChart, Pie, Cell } from 'recharts';

import { actions } from "store";
import { Loader } from "components/common";
import styles from './card.module.css';


class OrderPieCard extends Component {
  render() {
    const { analytics } = this.props;
    const { orderStatus, order } = analytics;
    const counts = order.counts || {};

    const data = [
      { name: 'Новые', value: counts['0'] },
      { name: 'Назначена машина/апартаменты', value: counts['1'] },
      { name: 'принято', value: counts['2'] },
      //{ name: 'продлено', value: counts['0'] },
      { name: 'завершено', value: counts['29'] },
      { name: 'отказ', value: counts['31'] },
      { name: 'возврат', value: counts['32'] },
      { name: 'отбой', value: counts['30'] },
    ];

    const colors = ['#65CAFC', '#ffe37b', '#D68FE7', '#eea9ff', '#83E163', '#FF625C', '#ff8b69'];

    return (
      <div className={styles.card}>
        <div className={styles.cardTitle}>Заказы по статусам</div>
        <div className={styles.cardMain}>
          <div className={styles.cardChart}>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart onMouseEnter={this.onPieEnter}>
                <Pie
                  data={data}
                  innerRadius="60%"
                  outerRadius="100%"
                  fill="#8884d8"
                  dataKey="value"
                >
                  {
                    data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]}/>)
                  }
                </Pie>
                <Tooltip/>
              </PieChart>
            </ResponsiveContainer>
          </div>

          <div className={styles.cardLegend}>
            <div>
              {data.map((item, idx) => (
                <div key={`item${idx}`} className={styles.legendItem}>
                  <div className={styles.legendItemIcon} style={{ backgroundColor: colors[idx % colors.length] }}/>
                  <div className={styles.legendItemLbl}>{item.name}</div>
                  <div className={styles.legendItemVal}>{item.value}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <Loader active={orderStatus.isLoading}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cityActiveId: state.city.activeId,
    analytics: state.analytics,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      analytics: bindActionCreators(Object.assign({}, actions.analytics), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPieCard);
