import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { tableColumn, arrayIsEqual } from "utils";
import { TableList, DateTimeText, ActiveStatus } from "components/common";
import { UserValue } from "components/blocks/User";
import { CityValue } from "components/blocks/City";
import ShiftValue from "../ShiftValue";


class ShiftList extends Component {
  state = {
    updateItemId: null,
    columnList: ['id', 'manager', 'city', 'date_start', 'date_end', 'is_active'],
    columnByName: {
      id: tableColumn('Номер', 'id', {
        render: (val, item) => <ShiftValue id={val} asLink={true} isBack={true} />,
        sorter: true,
        width: 150,
      }),
      date_start: tableColumn('Дата начала', 'date_start', {
        render: (val, item) => <DateTimeText date={val} />,
        width: 130,
      }),
      date_end: tableColumn('Дата завершения', 'date_end', {
        render: (val, item) => <DateTimeText date={val} />,
        width: 130,
      }),
      manager: tableColumn('Менеджер', 'manager', {
        render: (val, item) => <UserValue detail={val} asLink={true} isBack={true} />,
        sorter: true,
        width: 150,
      }),
      city: tableColumn('Город', 'city', {
        render: (val, item) => <CityValue detail={val} asLink={true} isBack={true} />,
        sorter: true,
        width: 150,
      }),
      is_active: tableColumn('Активна', 'is_active', {
        render: (val, item) => (
          <div>
            <ActiveStatus
              active={item.date_end === null}
              titles={['открыта', 'закрыта', 'закрыть']}
              buttonSize="small"
              onAction={() => {
                if (item.active_orders > 0) {
                  alert('У смены остались не завершённые заказы!');
                } else {
                  this.itemUpdate({ id: item.id, stop: true, preventLoadDetail: true });
                }
              }}
            />
            <div style={{ fontSize: '12px' }}>
              Незакрытых заказов: {item.active_orders}
            </div>
          </div>
        ),
        sorter: true,
        width: 160,
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.shift.listLoad({ query, activeFilter });
  };

  showEditForm = (item) => {
    this.props.actions.shift.showEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.shift.delete({ id: item.id })
  };

  itemUpdate = (data) => {
    this.setState({
      updateItemId: data.id,
    });
    this.props.actions.shift.update(data);
  };

  render() {
    const { shift, defaultFilter, activeFilter, history } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={shift}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        showSearch={true}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.fields,
    shift: state.shift,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      shift: bindActionCreators(Object.assign({}, actions.shift), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftList);
