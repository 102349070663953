import { BASE_URL } from "config";
import { getHeaders } from "utils";

import * as base from './_base';
import { Load } from "./_base";


export const endpoints = {
  list: `${BASE_URL}/api/v1/team/shift/`,
  detail: (id) => `${BASE_URL}/api/v1/team/shift/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/team/shift/${id}/history/`,
  current: `${BASE_URL}/api/v1/team/shift/current/`,
};

export const shiftListLoad = (token, payload) => base.ListLoad(endpoints.list, token, payload);
export const shiftDetailLoad = (token, { id }) => base.DetailLoad(endpoints.detail, token, { id });
export const shiftUpdate = (token, data) => base.Update(endpoints.list, endpoints.detail, token, data);
export const shiftDelete = (token, data) => base.Delete(endpoints.detail, token, data);
export const historyLoad = (token, payload) => base.DetailLoad(endpoints.history, token, { id: payload.id });

export const shiftCurrentLoad = (token, payload) => base.ListLoad(endpoints.current, token, payload);
export const shiftCurrentUpdate = (token, data) => {
  let method = 'put';
  if (data.start) {
    method = 'post';
  }
  return Load(method, endpoints.current, data, {
    headers: getHeaders(token),
  });
};
