import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const ORDER_WORKER_LIST_LOAD = createRequestTypes('ORDER_WORKER_LIST_LOAD');
export const ORDER_WORKER_DETAIL_LOAD = createRequestTypes('ORDER_WORKER_DETAIL_LOAD');
export const ORDER_WORKER_UPDATE = createRequestTypes('ORDER_WORKER_UPDATE');
export const ORDER_WORKER_DELETE = createRequestTypes('ORDER_WORKER_DELETE');
export const ORDER_WORKER_SHOW_EDIT_FORM = 'ORDER_WORKER_SHOW_EDIT_FORM';
export const ORDER_WORKER_SHOW_RETURN_FORM = 'ORDER_WORKER_SHOW_RETURN_FORM';
export const ORDER_WORKER_SELECT = 'ORDER_WORKER_SELECT';


export const actions = {
  showEditForm: createAction(ORDER_WORKER_SHOW_EDIT_FORM),
  showReturnForm: createAction(ORDER_WORKER_SHOW_RETURN_FORM),
  select: createAction(ORDER_WORKER_SELECT),

  listLoad: createRequestActions(ORDER_WORKER_LIST_LOAD),
  detailLoad: createRequestActions(ORDER_WORKER_DETAIL_LOAD),
  update: createRequestActions(ORDER_WORKER_UPDATE),
  delete: createRequestActions(ORDER_WORKER_DELETE),
};
