import React from 'react';
import classNames from 'classnames';

import styles from './logo.module.css';


export default function Logo(props) {
  const { className } = props;

  const cx = classNames([
    styles.wrap,
    className,
  ]);

  return (
    <div className={cx}>
      <div className={styles.logo}/>
    </div>
  );
};
