import React, { Component } from 'react';

import { ProfileDetail } from "components/blocks/Profile";


export default class DetailScreen extends Component {
  render() {
    return (
      <div className="page-main">
        <ProfileDetail/>
      </div>
    );
  }
}
