import React, { Component } from 'react';
import { Input, Row, Col } from "antd";

import { FormItem } from "utils";
import { PhoneField, PhotoUpload } from 'components/common';
import { CitySelect } from "components/blocks/City";
import { AgencySelect } from "components/blocks/Agency";


export default class MainFieldSet extends Component {
  state = {
    layout: 'horizontal',
  };

  render() {
    const { form, instance, errorData, city, currentUser } = this.props;
    const { layout } = this.state;
    const itemProps = { form, instance, errorData, layout };

    const agencyId = currentUser.agency && currentUser.agency.id;

    return (
      <Row gutter={32}>
        <Col span={16}>
          <div className="form-block">
            <CitySelect name="city" label="Город" required={true} disabled={true}
                        initialValue={parseInt(city.activeId)} {...itemProps}/>

            <AgencySelect name="agency" label="Агентство" required={true} disabled={!!agencyId}
                          initialValue={agencyId} {...itemProps}/>

            <FormItem name="name" label="Имя" required={true} {...itemProps}>
              <Input size="large"/>
            </FormItem>

            <PhoneField name="phone" label="Телефон" required={false} {...itemProps}/>
          </div>
        </Col>
        <Col span={8}>
          <FormItem name="photos" required={false} valuePropName="fileList" {...itemProps}>
            <PhotoUpload title="Загрузить фото"/>
          </FormItem>
        </Col>
      </Row>
    );
  }
}
