import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ShiftDetail } from "components/blocks/Shift";


class DetailScreen extends Component {
  render() {
    const { shift } = this.props;
    const detail = shift.detail || {};

    return (
      <div className="page-main">
        <ShiftDetail detail={detail}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    shift: state.shift,
  };
}

export default connect(mapStateToProps)(DetailScreen);
