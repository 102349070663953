import React, { Component, Fragment } from 'react';
import { Button, Modal, Form, Spin, Popconfirm, Icon } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { getFullNameOrEmail } from "utils";
import { Error } from 'components/common';
import ClientFieldSet from "./ClientFieldSet";


class OrderForm extends Component {
  state = {
    layout: 'horizontal',
    showEditForm: false,
    client: null,
    client_address: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { showEditForm } = nextProps.order;
    if (showEditForm !== prevState.showEditForm) {
      return {
        showEditForm: showEditForm,
        client: null,
        client_address: null,
      }
    }
    return null;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { order, city } = this.props;
        const { client, client_address } = this.state;
        const instance = order.editObject;

        let data = Object.assign({ city: city.activeId }, values);
        if (instance && instance.id) {
          data.id = instance.id;
        }

        if (client) {
          data.client = {
            id: client.id,
            phone: client.phone,
          };
        } else {
          data.client = {
            phone: values.client_phone,
          };
        }

        if (client_address) {
          data.client_address = {
            id: client_address.id,
            address: client_address.address,
          };
        } else {
          data.client_address = {
            address: values.client_address,
          };
        }

        console.log('data', data);
        this.props.actions.order.update(data);
      }
    });
  };

  close = () => {
    this.props.actions.order.showEditForm({ show: false });
  };

  clientChoose = (client) => {
    this.setState({
      client: client,
    })
  };

  clientAddressChoose = (client_address) => {
    this.setState({
      client_address: client_address,
    })
  };

  updateStatus = (status) => {
    const { form, order } = this.props;
    const instance = order.editObject;
    const values = form.getFieldsValue(['manager_note', 'duration']);

    const data = {
      id: instance.id,
      manager_note: values.manager_note || '',
      duration: values.duration,
      status: status,
    };
    this.props.actions.order.update(data);
  };

  prolong = (e) => {
    e.preventDefault();
    this.updateStatus(3);
  };

  stop = (e) => {
    e.preventDefault();
    this.updateStatus(29);
  };

  cancel = (e) => {
    e.preventDefault();
    this.updateStatus(30);
  };

  render() {
    const { order } = this.props;
    const { showEditForm, updateStatus, editObject } = order;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const isLoading = order.updateStatus.isLoading;

    return (
      <Modal
        width={840}
        visible={showEditForm}
        title={<div>{instance ?
          <Fragment>
            <div>Заказ №{instance.id}</div>
            <div>Диспетчер: {getFullNameOrEmail(instance.manager)}</div>
          </Fragment> :
          <div>Новый заказ</div>
        }</div>}
        onCancel={this.close}
        maskClosable={false}
        destroyOnClose={true}
        footer={
          <div className="modal-footer-inner">
            <div className="modal-footer-start">
              <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit} size="large">
                Сохранить
              </Button>
              {instance && instance.id && [20, 21].includes(instance.status) &&
                <Popconfirm
                  className="actionLink"
                  placement="bottomLeft"
                  title="Уверены, что хотите продлить заказ?"
                  okText="Продлить"
                  cancelText="Отмена"
                  onConfirm={this.prolong}
                >
                  <Icon type="play-circle" />
                  <span>Продлить</span>
                </Popconfirm>}
              {instance && instance.id && [0, 10, 11, 20, 21].includes(instance.status) &&
                <Fragment>
                  <Popconfirm
                    className="actionLink green"
                    placement="bottomLeft"
                    title="Уверены, что хотите завершить заказ?"
                    okText="Завершить"
                    cancelText="Отмена"
                    onConfirm={this.stop}
                  >
                    <Icon type="close-circle" />
                    <span>Завершить</span>
                  </Popconfirm>
                  <Popconfirm
                    className="actionLink red"
                    placement="bottomLeft"
                    title="Уверены, что хотите отменить заказ?"
                    okText="Отменить"
                    cancelText="Отмена"
                    onConfirm={this.cancel}
                  >
                    <Icon type="close-circle" />
                    <span>Отменить</span>
                  </Popconfirm>
                </Fragment>}
            </div>
            <div className="modal-footer-middle" />
            <div className="modal-footer-end" />
          </div>
        }
      >
        <Spin spinning={isLoading}>
          <Form onSubmit={this.handleSubmit} autoComplete="off">
            <Error errors={errorData.non_field_errors} />

            <ClientFieldSet errorData={errorData} instance={instance} {...this.props}
              onClientChoose={this.clientChoose}
              onClientAddressChoose={this.clientAddressChoose} />
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const WrappedForm = Form.create()(OrderForm);

function mapStateToProps(state) {
  return {
    city: state.city,
    currentUser: state.user.fields,
    order: state.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      order: bindActionCreators(Object.assign({}, actions.order), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm);

