import { BASE_URL } from "config";

import * as base from './_base';


export const endpoints = {
  list: `${BASE_URL}/api/v1/team/phone/`,
  detail: (id) => `${BASE_URL}/api/v1/team/phone/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/team/phone/${id}/history/`,
};

export const phoneListLoad = (token, payload) => base.ListLoad(endpoints.list, token, payload);
export const phoneDetailLoad = (token, { id }) => base.DetailLoad(endpoints.detail, token, { id });
export const phoneUpdate = (token, data) => base.Update(endpoints.list, endpoints.detail, token, data);
export const phoneDelete = (token, data) => base.Delete(endpoints.detail, token, data);
export const historyLoad = (token, payload) => base.DetailLoad(endpoints.history, token, { id: payload.id });
