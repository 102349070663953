import React, { Component } from 'react';
import { Button, Modal, Form, Spin, Collapse } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { Error } from 'components/common';
import { DistrictTransferField } from 'components/blocks/District';
import { ServiceTransferField } from 'components/blocks/Service';
import MainFieldSet from "./MainFieldSet";
import ParametersFieldSet from './ParametersFieldSet';
import TariffFieldSet from './TariffFieldSet';


class ProfileForm extends Component {
  state = {
    layout: 'horizontal',
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { profile } = this.props;
        const instance = profile.editObject;

        let data = Object.assign({}, values);
        if (instance && instance.id) {
          data.id = instance.id;
        }
        this.props.actions.profile.update(data);
      }
    });
  };

  close = () => {
    this.props.actions.profile.showEditForm({ show: false });
  };

  render() {
    const { form, city, profile } = this.props;
    const { showEditForm, updateStatus, editObject } = profile;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const isLoading = profile.updateStatus.isLoading;

    const itemProps = { form, instance, errorData };
    const cityId = parseInt(city.activeId);

    return (
      <Modal
        width={640}
        visible={showEditForm}
        title={instance ? `Редактирование ${instance.name}` : 'Новая анкета'}
        onCancel={this.close}
        maskClosable={false}
        destroyOnClose={true}
        footer={[
          <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit} size="large">
            Сохранить
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form onSubmit={this.handleSubmit} autoComplete="off"
                onKeyPress={(e) => e.key === 'Enter' && this.handleSubmit(e)}>
            <Error errors={errorData.non_field_errors}/>

            <MainFieldSet errorData={errorData} instance={instance} {...this.props}/>

            <Collapse defaultActiveKey={['params', 'districts', 'services', 'tariff']}>
              <Collapse.Panel header="Параметры" key="params" forceRender={true}>
                <ParametersFieldSet errorData={errorData} instance={instance} {...this.props}/>
              </Collapse.Panel>

              <Collapse.Panel header="Районы" key="districts" forceRender={true}>
                <DistrictTransferField name="districts" label="Районы" required={false} {...itemProps}
                                       activeFilter={{ city: cityId }}/>
              </Collapse.Panel>

              <Collapse.Panel header="Услуги" key="services" forceRender={true}>
                <ServiceTransferField name="services" label="Услуги" required={false} {...itemProps}/>
              </Collapse.Panel>

              <Collapse.Panel header="Тарифы" key="tariff" forceRender={true}>
                <TariffFieldSet errorData={errorData} instance={instance} {...this.props}/>
              </Collapse.Panel>
            </Collapse>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const WrappedForm = Form.create()(ProfileForm);

function mapStateToProps(state) {
  return {
    city: state.city,
    profile: state.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      profile: bindActionCreators(Object.assign({}, actions.profile), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm);

