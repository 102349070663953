import { BASE_URL } from "config";

import * as base from './_base';


export const endpoints = {
  order: `${BASE_URL}/api/v1/analytics/order/`,
  client: `${BASE_URL}/api/v1/analytics/client/`,
  clientList: `${BASE_URL}/api/v1/analytics/client/list/`,
  agencyList: `${BASE_URL}/api/v1/analytics/agency/`,
  managerList: `${BASE_URL}/api/v1/analytics/manager/`,
  addressList: `${BASE_URL}/api/v1/analytics/address/`,
  driverList: `${BASE_URL}/api/v1/analytics/driver/`,
  workerList: `${BASE_URL}/api/v1/analytics/worker/`,
  phoneList: `${BASE_URL}/api/v1/analytics/phone/`,
};

export const analyticsOrderLoad = (token, payload) => base.ListLoad(endpoints.order, token, payload);
export const analyticsClientLoad = (token, payload) => base.ListLoad(endpoints.client, token, payload);
export const analyticsClientListLoad = (token, payload) => base.ListLoad(endpoints.clientList, token, payload);
export const analyticsAgencyListLoad = (token, payload) => base.ListLoad(endpoints.agencyList, token, payload);
export const analyticsManagerListLoad = (token, payload) => base.ListLoad(endpoints.managerList, token, payload);
export const analyticsAddressListLoad = (token, payload) => base.ListLoad(endpoints.addressList, token, payload);
export const analyticsDriverListLoad = (token, payload) => base.ListLoad(endpoints.driverList, token, payload);
export const analyticsWorkerListLoad = (token, payload) => base.ListLoad(endpoints.workerList, token, payload);
export const analyticsPhoneListLoad = (token, payload) => base.ListLoad(endpoints.phoneList, token, payload);
