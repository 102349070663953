import React, { Component, forwardRef } from 'react';
import { Input } from 'antd';
import ReactInputMask from 'react-input-mask';

import { FormItem } from "utils";


const InputMask = forwardRef((props, ref) => {
  return (
    <ReactInputMask {...props}>
      {(inputProps) => <Input {...inputProps} ref={ref} disabled={props.disabled ? props.disabled : null}/>}
    </ReactInputMask>
  )
});

const cleanPhone = (value) => {
  if (!value) return '';
  return value.replace('(', '').replace('(', '').split('-').join('').split(' ').join('').split('_').join('');
};


export default class PhoneField extends Component {
  render() {
    const { onPhoneChange, required, ...otherProps } = this.props;

    return (
      <FormItem
        required={required}
        rules={[
          {
            required: required,
            pattern: /^((\+7|7)+([0-9]){10})$/gm,
            transform: (value) => {
              return cleanPhone(value);
            },
            message: 'Неправильный формат телефона'
          },
        ]}
        normalize={(value, prevValue, allValues) => {
          return cleanPhone(value);
        }}
        getValueFromEvent={e => {
          const { target } = e;
          return cleanPhone(target.value);
        }}
        getValueProps={value => {
          return { value };
        }}
        {...otherProps}
      >
        <InputMask
          size="large"
          mask="+7 999 999-99-99"
          onChange={onPhoneChange}
        />
      </FormItem>
    );
  }
}
