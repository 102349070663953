import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const ORDER_CAR_LIST_LOAD = createRequestTypes('ORDER_CAR_LIST_LOAD');
export const ORDER_CAR_DETAIL_LOAD = createRequestTypes('ORDER_CAR_DETAIL_LOAD');
export const ORDER_CAR_UPDATE = createRequestTypes('ORDER_CAR_UPDATE');
export const ORDER_CAR_DELETE = createRequestTypes('ORDER_CAR_DELETE');
export const ORDER_CAR_SHOW_EDIT_FORM = 'ORDER_CAR_SHOW_EDIT_FORM';
export const ORDER_CAR_SELECT = 'ORDER_CAR_SELECT';


export const actions = {
  showEditForm: createAction(ORDER_CAR_SHOW_EDIT_FORM),
  select: createAction(ORDER_CAR_SELECT),

  listLoad: createRequestActions(ORDER_CAR_LIST_LOAD),
  detailLoad: createRequestActions(ORDER_CAR_DETAIL_LOAD),
  update: createRequestActions(ORDER_CAR_UPDATE),
  delete: createRequestActions(ORDER_CAR_DELETE),
};
