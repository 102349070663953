import React, { Component } from 'react';
import { Input, InputNumber, Row, Col } from "antd";

import { FormItem } from "utils";
import { PhoneSelect } from 'components/blocks/Phone';
import { ClientField } from 'components/blocks/Client';
import { ClientAddressField } from 'components/blocks/ClientAddress';
import { ProfileCards } from 'components/blocks/Profile';


export default class ClientFieldSet extends Component {
  state = {
    layout: 'horizontal',
    prices: {},
  };

  updatePriceField = (prices, duration) => {
    const { form } = this.props;
    let price = prices.out_hour ? duration * prices.out_hour : 1500;
    if (duration === 0.5 && prices.out_express) {
      price = prices.out_express;
    } else if (duration === 2 && prices.out_hour2) {
      price = prices.out_hour2;
    } else if (duration >= 12 && prices.out_night) {
      price = prices.out_night;
    }
    form.setFieldsValue({
      price: price,
    });
  };

  phoneChoose = (phoneId, item) => {
    //const { form } = this.props;
    const prices = item && item.prices.length > 0 ? item.prices[0] : {};
    //const duration = form.getFieldValue('duration');

    this.setState({
      prices: prices,
    });

    //this.updatePriceField(prices, duration);
  };

  durationChange = (duration) => {
    const { prices } = this.state;
    this.updatePriceField(prices, duration);
  };

  clientChoose = (client) => {
    const { form, onClientChoose } = this.props;
    if (client) {
      const address = client.addresses.filter(item => !item.is_banned)[0];
      if (address) {
        form.setFieldsValue({
          client_address: address.address,
        });
      }
    }
    onClientChoose && onClientChoose(client);
  };

  clientAddressChoose = (client) => {
    const { onClientAddressChoose } = this.props;
    onClientAddressChoose && onClientAddressChoose(client);
  };

  render() {
    const { form, instance, errorData, city } = this.props;
    const itemProps = { form, instance, errorData };

    const phoneId = instance && instance.id ? instance.phone : form.getFieldValue('phone');

    return (
      <div className="form-block">
        <PhoneSelect name="phone" label="Телефон, на который звонили" required={true} disabled={!!instance}
                     {...itemProps} layout="horizontal2" activeFilter={{ city: parseInt(city.activeId) }}
                     onChange={this.phoneChoose}
        />

        <ProfileCards activeFilter={{ phone: phoneId || 0 }}/>

        <Row gutter={16} type="flex">
          <Col span={12}>
            <ClientField name="client_phone" label="Телефон клиента" required={false} disabled={!!instance}
                         {...itemProps} onClientChoose={this.clientChoose}/>
          </Col>
          <Col span={12}>
            <ClientAddressField name="client_address" label="Адрес клиента" required={false} disabled={!!instance}
                                {...itemProps} activeFilter={{ city: parseInt(city.activeId) }}
                                onClientAddressChoose={this.clientAddressChoose}/>
          </Col>
          <Col span={6}>
            <FormItem name="count" label="Кол-во" required={false} initialValue={1}
                      layout="horizontal2" {...itemProps}>
              <InputNumber min={1} max={10} step={1}/>
            </FormItem>
          </Col>
          <Col span={10}>
            <FormItem name="duration" label="Длительность" required={false} initialValue={1}
                      layout="horizontal2" {...itemProps}>
              <InputNumber min={0.5} max={24} step={0.5} /*onChange={this.durationChange}*//>
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem name="price" label="Цена" required={false} initialValue={1000}
                      layout="horizontal" {...itemProps}>
              <Input size="large" addonAfter="руб."/>
            </FormItem>
          </Col>
        </Row>

        <FormItem name="note" label="Комментарий" required={false} layout="horizontal2" {...itemProps}>
          <Input.TextArea size="large" autoSize={{ minRows: 3 }}/>
        </FormItem>
      </div>
    );
  }
}
