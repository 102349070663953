import { createAction } from "redux-actions";
import { createRequestActions, createRequestTypes } from 'utils';

export const CURRENT_USER_LOAD = createRequestTypes('CURRENT_USER_LOAD');
export const CURRENT_USER_UPDATE = createRequestTypes('CURRENT_USER_UPDATE');

export const USER_LIST_LOAD = createRequestTypes('USER_LIST_LOAD');
export const USER_DETAIL_LOAD = createRequestTypes('USER_DETAIL_LOAD');
export const USER_UPDATE = createRequestTypes('USER_UPDATE');
export const USER_DELETE = createRequestTypes('USER_DELETE');
export const USER_SHOW_EDIT_FORM = 'USER_SHOW_EDIT_FORM';
export const USER_SELECT = 'USER_SELECT';
export const USER_SHOW_PASSWORD_FORM = 'USER_SHOW_PASSWORD_FORM';


export const actions = {
  currentUserLoad: createRequestActions(CURRENT_USER_LOAD),
  currentUserUpdate: createRequestActions(CURRENT_USER_UPDATE),

  showEditForm: createAction(USER_SHOW_EDIT_FORM),
  select: createAction(USER_SELECT),
  showPasswordForm: createAction(USER_SHOW_PASSWORD_FORM),

  listLoad: createRequestActions(USER_LIST_LOAD),
  detailLoad: createRequestActions(USER_DETAIL_LOAD),
  update: createRequestActions(USER_UPDATE),
  delete: createRequestActions(USER_DELETE),
};
