import React, { Component } from 'react';
import { Route } from 'react-router';

import AddressListScreen from './AddressListScreen';
import AddressDetailScreen from './AddressDetailScreen';


export default class AddressScreen extends Component {
  render() {
    return (
      <div className="page-main">
        <Route exact path="/agency/:agencyId/address" component={AddressListScreen}/>
        <Route exact path="/agency/:agencyId/address/:addressId" component={AddressDetailScreen}/>
        <Route exact path="/agency/:agencyId/address/:addressId/history" component={AddressDetailScreen}/>
      </div>
    );
  }
}
