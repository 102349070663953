import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Col, Tabs } from 'antd';

import { actions } from "store";
import { Page } from 'components/layout';
import {
  AnalyticsHeader,
  OrderSummary,
  OrderRejected,
  ClientSummary,
  OrderPieCard,
  AgencyStats,
  ManagerStats,
  AddressStats,
  DriverStats,
  WorkerStats,
  PhoneStats,
  ClientStats,
} from 'components/blocks/Analytics';
import styles from './analytics.module.css';


class AnalyticsScreen extends Component {
  render() {
    return (
      <Page>
        <div className="page-inner">
          <AnalyticsHeader/>

          <div className="page-main">
            <Row type="flex" align="top" gutter={16}>
              <Col span={8}>
                <OrderSummary stroke="#8884d8" fill="#8884d8"/>
              </Col>

              <Col span={8}>
                <OrderRejected stroke="#8884d8" fill="#8884d8"/>
              </Col>

              <Col span={8}>
                <ClientSummary stroke="#8884d8" fill="#8884d8"/>
              </Col>
            </Row>

            <br/>

            <Row type="flex" align="top" gutter={16}>
              <Col span={12}>
                <OrderPieCard/>
              </Col>

              <Col span={12}>

              </Col>
            </Row>

            <br/>

            <div className={styles.card}>
              <Tabs className="simple-tabs">
                <Tabs.TabPane tab="Агентства" key="1">
                  <AgencyStats/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Диспетчеры" key="2">
                  <ManagerStats/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Апартаменты" key="3">
                  <AddressStats/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Водители" key="4">
                  <DriverStats/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Девушки" key="5">
                  <WorkerStats/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Телефоны" key="6">
                  <PhoneStats/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Клиенты" key="7">
                  <ClientStats/>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    analytics: state.analytics,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      analytics: bindActionCreators(Object.assign({}, actions.analytics), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsScreen);
