import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const DRIVER_LIST_LOAD = createRequestTypes('DRIVER_LIST_LOAD');
export const DRIVER_DETAIL_LOAD = createRequestTypes('DRIVER_DETAIL_LOAD');
export const DRIVER_UPDATE = createRequestTypes('DRIVER_UPDATE');
export const DRIVER_DELETE = createRequestTypes('DRIVER_DELETE');
export const DRIVER_SHOW_EDIT_FORM = 'DRIVER_SHOW_EDIT_FORM';
export const DRIVER_SELECT = 'DRIVER_SELECT';


export const actions = {
  showEditForm: createAction(DRIVER_SHOW_EDIT_FORM),
  select: createAction(DRIVER_SELECT),

  listLoad: createRequestActions(DRIVER_LIST_LOAD),
  detailLoad: createRequestActions(DRIVER_DETAIL_LOAD),
  update: createRequestActions(DRIVER_UPDATE),
  delete: createRequestActions(DRIVER_DELETE),
};
