import React, { Component } from 'react';

import { AgencyDetail } from "components/blocks/Agency";


export default class DetailScreen extends Component {
  render() {
    return (
      <div className="page-main">
        <AgencyDetail/>
      </div>
    );
  }
}
