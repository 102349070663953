import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';
import classNames from "classnames";

import styles from './descriptions.module.css';


class DescriptionsItem extends PureComponent {
  render() {
    const { className, defaultSpan, span, gutter, label, children } = this.props;
    const cx = classNames([
      styles.item,
      className,
    ]);

    const cxContent = classNames([
      styles.itemContent,
      (!children || children.length === 0) && styles.itemContentEmpty,
    ]);

    return (
      <Col className={cx} span={span || defaultSpan} gutter={gutter}>
        <div className={styles.itemWrap}>
          <div className={styles.itemLabel}>{label}</div>
          <div className={cxContent}>{children}</div>
        </div>
      </Col>
    );
  }
}

export default class Descriptions extends PureComponent {
  static Item = DescriptionsItem;

  render() {
    const { className, gutter, column } = this.props;
    const cx = classNames([
      styles.container,
      className,
    ]);

    const childrenWithExtraProp = React.Children.map(this.props.children, child => {
      return child && React.cloneElement(child, {
        defaultSpan: 24 / column,
        gutter,
      });
    });

    return (
      <Row className={cx} type="flex" gutter={gutter}>
        {childrenWithExtraProp}
      </Row>
    );
  }
}
