import { all } from 'redux-saga/effects'
import * as app from 'store/app';
import * as auth from 'store/auth';
import * as user from 'store/user';
import * as userAudit from 'store/userAudit';
import * as history from 'store/history';
import * as city from 'store/city';
import * as district from 'store/district';
import * as worker from 'store/worker';
import * as driver from 'store/driver';
import * as shift from 'store/shift';
import * as apartment from 'store/apartment';
import * as car from 'store/car';
import * as client from 'store/client';
import * as clientAddress from 'store/clientAddress';
import * as profile from 'store/profile';
import * as order from 'store/order';
import * as orderApartment from 'store/orderApartment';
import * as orderCar from 'store/orderCar';
import * as orderWorker from 'store/orderWorker';
import * as phone from 'store/phone';
import * as address from 'store/address';
import * as agency from 'store/agency';
import * as service from 'store/service';
import * as analytics from 'store/analytics';

const stores = [
  ['app', app],
  ['auth', auth],
  ['user', user],
  ['userAudit', userAudit],
  ['history', history],
  ['city', city],
  ['district', district],
  ['worker', worker],
  ['driver', driver],
  ['shift', shift],
  ['apartment', apartment],
  ['car', car],
  ['client', client],
  ['clientAddress', clientAddress],
  ['profile', profile],
  ['order', order],
  ['orderApartment', orderApartment],
  ['orderCar', orderCar],
  ['orderWorker', orderWorker],
  ['phone', phone],
  ['address', address],
  ['agency', agency],
  ['service', service],
  ['analytics', analytics],
];

const actions = stores.reduce((result, item) => {
  result[item[0]] = item[1].reduxActions.actions;
  return result;
}, {});

const reducers = stores.reduce((result, item) => {
  result[item[0]] = item[1].reduxReducer;
  return result;
}, {});

const sagas = stores.map(item => (item[1].sagas.watch()));

function* rootSaga() {
  yield all(sagas)
}


export {
  actions,
  reducers,
  rootSaga,
}
