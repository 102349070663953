import React, { Component } from 'react';

import { AddressSelect } from "components/blocks/Address";
import { WorkerTransferField } from "components/blocks/Worker";


export default class MainFieldSet extends Component {
  state = {
    layout: 'horizontal',
  };

  render() {
    const { form, instance, errorData, city } = this.props;
    const { layout } = this.state;
    const itemProps = { form, instance, errorData };

    const apartmentId = instance && parseInt(instance.id);

    return (
      <div className="form-block">
        <AddressSelect
          name="address" label="Адрес" required={true} layout={layout}
          activeFilter={{ free: true, apartment: apartmentId, city: city.activeId }}
          {...itemProps}
        />

        <WorkerTransferField
          name="workers" label="Девушки" required={true}
          activeFilter={{ free: true, apartment: apartmentId, city: city.activeId }}
          {...itemProps}
        />
      </div>
    );
  }
}
