import React, { Component } from 'react';
import { Input } from "antd";

import { FormItem, isString } from "utils";
import { CitySelect } from "components/blocks/City";
import { AgencySelect } from "components/blocks/Agency";


export default class MainFieldSet extends Component {
  state = {
    layout: 'horizontal',
  };

  render() {
    const { form, instance, errorData, city, currentUser, agencyId } = this.props;
    const { layout } = this.state;
    const itemProps = { form, instance, errorData, layout };

    let _agencyId = agencyId || (currentUser.agency && currentUser.agency.id);
    if (_agencyId && isString(_agencyId)) {
      _agencyId = parseInt(_agencyId);
    }

    return (
      <div className="form-block">
        <CitySelect name="city" label="Город" required={true} disabled={true}
                    initialValue={parseInt(city.activeId)} {...itemProps}/>

        <AgencySelect name="agency" label="Агентство" required={true} disabled={!!_agencyId}
                      initialValue={_agencyId} {...itemProps}/>

        <FormItem name="address" label="Адрес" required={true} {...itemProps}>
          <Input size="large"/>
        </FormItem>
      </div>
    );
  }
}
