import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const DISTRICT_LIST_LOAD = createRequestTypes('DISTRICT_LIST_LOAD');
export const DISTRICT_DETAIL_LOAD = createRequestTypes('DISTRICT_DETAIL_LOAD');
export const DISTRICT_UPDATE = createRequestTypes('DISTRICT_UPDATE');
export const DISTRICT_DELETE = createRequestTypes('DISTRICT_DELETE');
export const DISTRICT_SHOW_EDIT_FORM = 'DISTRICT_SHOW_EDIT_FORM';
export const DISTRICT_SELECT = 'DISTRICT_SELECT';


export const actions = {
  showEditForm: createAction(DISTRICT_SHOW_EDIT_FORM),
  select: createAction(DISTRICT_SELECT),

  listLoad: createRequestActions(DISTRICT_LIST_LOAD),
  detailLoad: createRequestActions(DISTRICT_DETAIL_LOAD),
  update: createRequestActions(DISTRICT_UPDATE),
  delete: createRequestActions(DISTRICT_DELETE),
};
