import React, { Component } from 'react';
import { Input } from "antd";

import { FormItem } from "utils";
import { CitySelect } from 'components/blocks/City';


export default class MainFieldSet extends Component {
  state = {
    layout: 'horizontal',
  };

  render() {
    const { form, instance, errorData, cityId } = this.props;
    const { layout } = this.state;
    const itemProps = { form, instance, errorData, layout };

    return (
      <div className="form-block">
        <CitySelect name="city" label="Город" required={true} initialValue={cityId} {...itemProps}/>

        <FormItem name="name" label="Название" required={true} {...itemProps}>
          <Input size="large"/>
        </FormItem>
      </div>
    );
  }
}
