import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { tableColumn, arrayIsEqual } from "utils";
import { TableList, DateTimeText, OnOff } from "components/common";
import { CityValue } from "components/blocks/City";
import { ClientAddressValue } from "../index";


class ClientAddressList extends Component {
  state = {
    updateItemId: null,
    columnList: ['address', 'city', 'date_created', 'is_banned'],
    columnByName: {
      address: tableColumn('Адрес', 'address', {
        render: (val, item) => <ClientAddressValue detail={item} asLink={true} isBack={true}/>,
        sorter: true,
      }),
      date_created: tableColumn('Дата создания', 'date_created', {
        render: (val, item) => <DateTimeText date={val}/>,
        sorter: true,
        width: 200,
      }),
      city: tableColumn('Город', 'city', {
        render: (val, item) => <CityValue detail={val} asLink={true} isBack={true}/>,
        width: 130,
      }),
      is_banned: tableColumn('Забанен', 'is_banned', {
        render: (val, item) => <OnOff
          param="is_banned"
          preventLoadDetail={true}
          item={item}
          updateItemId={this.state.updateItemId}
          updateStatus={this.props.clientAddress.updateStatus}
          onChange={this.itemUpdate}

        />,
        sorter: true,
        width: 140,
      })
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.clientAddress.listLoad({ query, activeFilter });
  };

  showEditForm = (item) => {
    this.props.actions.clientAddress.showEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.clientAddress.delete({ id: item.id })
  };

  itemUpdate = (data) => {
    this.setState({
      updateItemId: data.id,
    });
    this.props.actions.clientAddress.update(data);
  };

  render() {
    const { clientAddress, defaultFilter, activeFilter, history, topActions } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={clientAddress}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        showSearch={true}
        topActions={topActions}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    clientAddress: state.clientAddress,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      clientAddress: bindActionCreators(Object.assign({}, actions.clientAddress), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientAddressList);
