import { connect } from 'react-redux';

import { addressDetailLoad } from "store/api/address";
import { ValueComponent } from 'components/common';


class AddressValue extends ValueComponent {
  apiDetailLoad = addressDetailLoad;

  getValue() {
    const { id } = this.props;
    const { detail } = this.state;
    return (detail && detail.address) || id;
  }

  getPath() {
    const { detail } = this.state;

    const agencyId = detail && detail.agency && detail.agency.id;
    const detailId = detail && detail.id;
    return `/agency/${agencyId}/address/${detailId}`;
  }
}

function mapStateToProps(state) {
  return {
    router: state.router,
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(AddressValue);
