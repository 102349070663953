import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Icon } from "antd";

import { actions } from "store";
import { PageHeader } from "components/layout";
import { OrderList } from "components/blocks/Order";
import { OrderApartmentForm } from "components/blocks/OrderApartment";
import { OrderCarForm } from "components/blocks/OrderCar";
import { OrderWorkerForm, OrderWorkerReturnForm } from "components/blocks/OrderWorker";


class OrderListScreen extends Component {
  state = {
    activeStatus: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { history } = nextProps;
    let activeStatus = null;
    if (history.location.pathname.startsWith('/order')) {
      const path = history.location.pathname.replace('/order', '');
      if (path.startsWith('/status')) {
        activeStatus = path.replace('/status/', '');
      }
    }

    if (prevState.activeStatus !== activeStatus) {
      return {
        activeStatus: activeStatus,
      };
    }
    return null;
  };

  onTabChange = (key) => {
    const url = key === 'detail' ? `/order` : `/order${key}`;
    this.props.history.push(url);
  };

  showEditForm = () => {
    this.props.actions.order.showEditForm({ show: true })
  };

  render() {
    const { history, city, currentShiftId, order } = this.props;
    const info = order.info || {};
    const { activeStatus } = this.state;
    let activeFilter = { city: city.activeId };
    if (activeStatus) {
      activeFilter.status = activeStatus;
    }

    return (
      <div className="page-inner">
        <PageHeader
          history={history}
          title="Заказы"
          actions={currentShiftId && [
            <Button type="primary" onClick={() => this.showEditForm()}>
              <Icon type="plus"/>
              <span>Добавить</span>
            </Button>
          ]}
          basePath="/order"
          onTabChange={this.onTabChange}
          tabs={[
            { title: 'Все', count: info['all'] || 0, key: 'detail' },
            { title: 'Новые', count: info['0'] || 0, key: '/status/0' },
            { title: 'Назначено', count: info['1'] || 0, key: '/status/1' },
            { title: 'Принято', count: info['20'] || 0, key: '/status/20' },
            //{ title: 'Продлено', count: info['21'], key: '/status/21' },
            { title: 'Завершено', count: info['29'] || 0, key: '/status/29' },
            { title: 'Возврат', count: info['32'] || 0, key: '/status/32' },
            { title: 'Отказ', count: info['31'] || 0, key: '/status/31' },
            { title: 'Отбой', count: info['30'] || 0, key: '/status/30' },
            { title: 'Пустые', count: info['empty'] || 0, key: '/status/empty' },
          ]}
        />

        <div className="page-main">
          <OrderList activeFilter={activeFilter}/>
        </div>

        <OrderApartmentForm/>
        <OrderCarForm/>
        <OrderWorkerForm/>
        <OrderWorkerReturnForm/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    city: state.city,
    currentShiftId: state.shift.currentId,
    order: state.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      order: bindActionCreators(Object.assign({}, actions.order), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderListScreen);
