import React, { Component } from 'react';
import { Table } from 'antd';

import { getColumns, arrayIsEqual } from "utils";


class SimpleTableList extends Component {
  constructor(props, context) {
    super(props, context);
    const { columnList, columnByName } = props;
    this.state = {
      columnList: columnList,
      columns: getColumns(columnByName, columnList, ''),
      orderField: '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList, columnByName } = nextProps;
    if (!arrayIsEqual(columnList, prevState.columnList)) {
      return {
        columnList: columnList,
        columns: getColumns(columnByName, columnList, ''),
      }
    }
    return null;
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = () => {
    this.props.onLoadData();
  };

  onChange = (pagination, filters, sorter, extra) => {
    const { columnList, columnByName } = this.props;
    let orderField = sorter.field;
    if (sorter.order === 'descend') {
      orderField = '-' + orderField;
    }
    this.setState({
      columnList: columnList,
      columns: getColumns(columnByName, columnList, orderField),
    })
  };

  render() {
    const { title, dataSource, ...props } = this.props;

    return (
      <div className="page-block-content">
        <Table
          size="small"
          title={title}
          columns={this.state.columns}
          dataSource={dataSource}
          rowKey="id"
          pagination={false}
          onChange={this.onChange}
          {...props}
        />
      </div>
    );
  }
}

export default SimpleTableList;
