import React, { Component } from 'react';
import { Route } from 'react-router';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { PageHeader } from "components/layout";
import { Loader, ActionButton } from "components/common";
import { ProfileForm } from "components/blocks/Profile";
import DetailScreen from './DetailScreen';
import HistoryScreen from './HistoryScreen';


class ProfileDetailScreen extends Component {
  componentDidMount() {
    const { profileId } = this.props.match.params;
    if (profileId) {
      this.props.actions.profile.detailLoad({ id: profileId });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevId = prevProps.match.params.profileId;
    const nextId = this.props.match.params.profileId;
    if (nextId && prevId !== nextId) {
      this.props.actions.profile.detailLoad({ id: nextId });
    }
  }

  showEditForm = (item = null) => {
    this.props.actions.profile.showEditForm({ show: true, object: item });
  };

  delete = () => {
    const { profileId } = this.props.match.params;
    this.props.actions.profile.delete({ id: profileId });
  };

  render() {
    const { history, profile } = this.props;
    const { profileId } = this.props.match.params;
    const detail = profile.detail || {};

    return (
      <div className="page-inner">
        {profileId &&
        <PageHeader
          mode="detail"
          history={history}
          title={detail.name}
          breadcrumbs={[
            { title: '', icon: 'home', path: '/', isBack: false },
            { title: 'Анкеты на сайтах', icon: '', path: '/profile', isBack: true },
          ]}
          actions={[
            <Button type="primary" onClick={() => this.showEditForm(detail)}>
              <Icon type="edit"/>
              <span>Редактировать</span>
            </Button>,
            <ActionButton
              actions={[
                {
                  key: 'delete',
                  icon: 'delete',
                  title: 'Удалить',
                  action: this.delete,
                  confirm: {
                    title: 'Уверены, что хотите удалить?',
                    okText: 'Удалить',
                    cancelText: 'Отмена'
                  }
                },
              ]}
            />
          ]}
          basePath={`/profile/${profileId}`}
          tabs={[
            { title: 'Основное', key: 'detail' },
            { title: 'История изменений', key: 'history' },
          ]}
        />}

        <Route exact path="/profile/:profileId" component={DetailScreen}/>
        <Route exact path="/profile/:profileId/history" component={HistoryScreen}/>

        <ProfileForm/>
        <Loader active={profile.loadDetailStatus.isLoading}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      profile: bindActionCreators(Object.assign({}, actions.profile), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetailScreen);
