import React, { Component } from 'react';
import { Input } from "antd";

import { FormItem } from "utils";


export default class MainFieldSet extends Component {
  state = {
    layout: 'horizontal',
  };

  render() {
    const { form, instance, errorData } = this.props;
    const { layout } = this.state;
    const itemProps = { form, instance, errorData, layout };

    return (
      <div className="form-block">
        <FormItem name="name" label="Название" required={true} {...itemProps}>
          <Input size="large"/>
        </FormItem>
      </div>
    );
  }
}
