import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const PHONE_LIST_LOAD = createRequestTypes('PHONE_LIST_LOAD');
export const PHONE_DETAIL_LOAD = createRequestTypes('PHONE_DETAIL_LOAD');
export const PHONE_UPDATE = createRequestTypes('PHONE_UPDATE');
export const PHONE_DELETE = createRequestTypes('PHONE_DELETE');
export const PHONE_SHOW_EDIT_FORM = 'PHONE_SHOW_EDIT_FORM';
export const PHONE_SELECT = 'PHONE_SELECT';


export const actions = {
  showEditForm: createAction(PHONE_SHOW_EDIT_FORM),
  select: createAction(PHONE_SELECT),

  listLoad: createRequestActions(PHONE_LIST_LOAD),
  detailLoad: createRequestActions(PHONE_DETAIL_LOAD),
  update: createRequestActions(PHONE_UPDATE),
  delete: createRequestActions(PHONE_DELETE),
};
