import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import BaseStats from '../BaseStats';


class WorkerStats extends BaseStats {
  dataName = 'workerList';

  load = () => {
    this.props.actions.analytics.workerListLoad();
  };
}

function mapStateToProps(state) {
  return {
    cityActiveId: state.city.activeId,
    analytics: state.analytics,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      analytics: bindActionCreators(Object.assign({}, actions.analytics), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkerStats);
