import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';

import { reducers, rootSaga, actions } from 'store';
import { offlineOptions } from 'store/offline';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  ...reducers,
});


export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const initialState = {};
const enhancers = [
  offline({
    ...offlineConfig,
    persistCallback: () => {
      const { token } = store.getState().auth;
      store.dispatch(actions.app.appInit({ token }));
    },
    persistOptions: offlineOptions
  }),
];
const middleware = [
  routerMiddleware(history),
  sagaMiddleware,
];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers
);


sagaMiddleware.run(rootSaga);

export default store;
