import React, { Component } from 'react';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import CityListScreen from './CityListScreen';
import CityDetailScreen from './CityDetailScreen';


export default class CityScreen extends Component {
  render() {
    return (
      <Page>
        <Route exact path="/city" component={CityListScreen}/>
        <Route exact path="/city/:cityId" component={CityDetailScreen}/>
        <Route exact path="/city/:cityId/history" component={CityDetailScreen}/>
        <Route exact path="/city/:cityId/district" component={CityDetailScreen}/>
      </Page>
    );
  }
}
