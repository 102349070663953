import React, { Component } from 'react';
import { Route } from 'react-router';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { PageHeader } from "components/layout";
import { Loader } from "components/common";
import { AgencyForm } from "components/blocks/Agency";
import DetailScreen from './DetailScreen';
import HistoryScreen from './HistoryScreen';
import PhoneScreen from "./Phone";
import AddressScreen from "./Address";


class AgencyDetailScreen extends Component {
  componentDidMount() {
    const { agencyId } = this.props.match.params;
    if (agencyId) {
      this.props.actions.agency.detailLoad({ id: agencyId });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevId = prevProps.match.params.agencyId;
    const nextId = this.props.match.params.agencyId;
    if (nextId && prevId !== nextId) {
      this.props.actions.agency.detailLoad({ id: nextId });
    }
  }

  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.agency.showEditForm({ show: true, object: item });
  };

  render() {
    const { history, agency } = this.props;
    const { agencyId } = this.props.match.params;
    const detail = agency.detail || {};

    return (
      <div className="page-inner">
        {agencyId &&
        <PageHeader
          mode="detail"
          history={history}
          breadcrumbs={[
            { title: '', icon: 'home', path: '/', isBack: false },
            { title: 'Агентства', icon: '', path: '/agency', isBack: true },
          ]}
          title={detail.name}
          actions={[
            <Button type="primary" onClick={() => this.showEditForm(detail)}>
              <Icon type="edit"/>
              <span>Редактировать</span>
            </Button>
          ]}
          basePath={`/agency/${agencyId}`}
          tabs={[
            { title: 'Основное', key: 'detail' },
            { title: 'Телефоны', key: 'phone' },
            { title: 'Адреса апартаментов', key: 'address' },
            { title: 'История изменений', key: 'history' },
          ]}
        />}

        <Route exact path="/agency/:agencyId" component={DetailScreen}/>
        <Route exact path="/agency/:agencyId/history" component={HistoryScreen}/>

        <Route exact path="/agency/:agencyId/phone" component={PhoneScreen}/>
        <Route path="/agency/:agencyId/phone/:phoneId" component={PhoneScreen}/>

        <Route exact path="/agency/:agencyId/address" component={AddressScreen}/>
        <Route path="/agency/:agencyId/address/:addressId" component={AddressScreen}/>

        <Route exact path="/agency/:agencyId/shift" component={DetailScreen}/>

        <AgencyForm/>
        <Loader active={agency.loadDetailStatus.isLoading}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agency: state.agency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      agency: bindActionCreators(Object.assign({}, actions.agency), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyDetailScreen);
