import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Icon, Popconfirm } from 'antd';
import Timer from 'react-compound-timer';

import { actions } from 'store';
import { GirlSvg } from 'components/common/SvgIcons';
import styles from './header_shift.module.css';


const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
  <Timer {...timerProps}>
    {timerRenderProps =>
      <WrappedComponent {...wrappedComponentProps} timer={timerRenderProps}/>}
  </Timer>
);

class DynamicTimer extends React.Component {
  render() {
    return (
      <Fragment>
        <Timer.Days
          formatValue={value => value > 0 ? `${value} дн. ` : ''}/><Timer.Hours/>:<Timer.Minutes/>:<Timer.Seconds/>
      </Fragment>
    );
  }
}

class HeaderShiftPanel extends Component {
  start = () => {
    const { city } = this.props;
    this.props.actions.shift.currentUpdate({ start: true, city: city.activeId })
  };

  stop = () => {
    const { city } = this.props;
    this.props.actions.shift.currentUpdate({ stop: true, city: city.activeId })
  };

  goToShift = () => {
    this.props.history.push('/');
  };

  renderCurrent() {
    const { shift } = this.props;
    const current = shift.current;
    const apartmentCount = current.apartments.length;
    const carCount = current.cars.length;
    const workerCount =
      current.apartments.reduce((r, item) => r + item.workers.length, 0) +
      current.cars.reduce((r, item) => r + item.workers.length, 0);

    const TimerHOC = withTimer({
      initialTime: current.duration * 1000,
      startImmediately: true,
      formatValue: (value) => `${(value < 10 ? `0${value}` : value)}`,
    })(DynamicTimer);

    return (
      <div className={styles.currentWrap}>
        <div className={styles.current} onClick={this.goToShift}>
          <div className={styles.currentTitle}>Текущая смена</div>
          <TimerHOC className={styles.currentTimer}/>

          <div className={styles.currentBottom}>
            <div className={styles.carBtn} onClick={this.goToShift}>
              <Icon className={styles.carBtnIcon} type="environment"/>
              <span>{apartmentCount}</span>
            </div>

            <div className={styles.carBtn} onClick={this.goToShift}>
              <Icon className={styles.carBtnIcon} type="car"/>
              <span>{carCount}</span>
            </div>

            <div className={styles.carBtn} onClick={this.goToShift}>
              <Icon className={styles.carBtnIcon} component={GirlSvg}/>
              <span>{workerCount}</span>
            </div>
          </div>
        </div>

        <Popconfirm
          placement="bottomLeft" title="Уверены, что хотите завершить смену?"
          okText="Завершить" cancelText="Отмена"
          onConfirm={this.stop}
        >
          <div className={styles.stopBtn}>
            <Icon type="stop"/>
          </div>
        </Popconfirm>
      </div>
    )
  }

  renderStart() {
    return (
      <Button className={styles.startBtn} type="primary" size="large" onClick={this.start}>
        <Icon className={styles.startBtnIcon} type="caret-right"/>
        <span>Начать смену</span>
      </Button>
    )
  }

  render() {
    const { currentUser, shift } = this.props;
    if (currentUser.role !== 'manager') return null;
    const current = shift.current;

    return (
      <div className={styles.main}>
        {current.id ? this.renderCurrent() : this.renderStart()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.fields,
    city: state.city,
    shift: state.shift,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      shift: bindActionCreators(Object.assign({}, actions.shift), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderShiftPanel);
