import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ResponsiveContainer, AreaChart, Area, Tooltip } from 'recharts';

import { actions } from "store";
import { parseDate } from "utils";
import { Loader } from "components/common";
import styles from './card.module.css';


class OrderRejected extends Component {
  render() {
    const { analytics, stroke, fill } = this.props;
    const { dateFormat, orderStatus, order } = analytics;
    const data = order.groups;
    const rejectedCount = order.counts ? order.counts['31']: 0;
    const percent = order.count > 0 ? Math.round(rejectedCount / order.count * 100): -1;

    return (
      <div className={styles.card}>
        <div className={styles.cardTitle}>Отказы</div>
        <div className={styles.cardCount}>{percent > -1 && `${percent}%`}</div>
        <div className={styles.cardChart}>
          <ResponsiveContainer width="100%" height={50}>
            <AreaChart data={data}>
              <Tooltip
                labelFormatter={index =>
                  data && data[index] && parseDate(data[index].date, "YYYY-MM-DD HH:mm").format(dateFormat)
                }
                formatter={(value, name, props) => ([value, "заказы"])}
              />
              <Area type="monotone" dataKey="rejected_count" stroke={stroke} fillOpacity={1} fill={fill}/>
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div className={styles.cardBottom}>Всего отказов: {rejectedCount}</div>

        <Loader active={orderStatus.isLoading}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cityActiveId: state.city.activeId,
    analytics: state.analytics,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      analytics: bindActionCreators(Object.assign({}, actions.analytics), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderRejected);
