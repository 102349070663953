import React from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';

import { workerListLoad } from "store/api/worker";
import { FormItem } from "utils";
import { DataLoadComponent } from 'components/common';


class WorkerSelect extends DataLoadComponent {
  apiListLoad = workerListLoad;

  render() {
    const { form, instance, name, label, required, errorData, disabled, onChange, layout } = this.props;
    const { items, loadStatus } = this.state;
    const initialValue = instance && instance.id ? null : this.props.initialValue;

    return (
      <FormItem
        form={form}
        instance={instance}
        name={name}
        label={label}
        required={required}
        errorData={errorData}
        initialValue={initialValue}
        convertValue={(obj) => obj && obj.id}
        layout={layout}
      >
        <Select
          style={{ width: 200 }}
          loading={loadStatus.isLoading}
          onChange={onChange}
          showSearch={true}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          disabled={disabled}
        >
          {!required && <Select.Option value={null}>---</Select.Option>}
          {items.map(item => (
            <Select.Option key={`worker${item.id}`} value={item.id}>{item.name}</Select.Option>
          ))}
        </Select>
      </FormItem>
    );
  }
}


function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(WorkerSelect);
