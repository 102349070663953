import { call, put, select, takeEvery } from 'redux-saga/effects';
import moment from "moment";

import { isString } from "utils";
import * as reduxActions from './reduxActions';
import * as api from 'store/api/analytics';


export function* watch() {
  yield takeEvery(reduxActions.ANALYTICS_ORDER_LOAD.START, actionOrderLoad);
  yield takeEvery(reduxActions.ANALYTICS_CLIENT_LOAD.START, actionClientLoad);
  yield takeEvery(reduxActions.ANALYTICS_CLIENT_LIST_LOAD.START, actionClientListLoad);
  yield takeEvery(reduxActions.ANALYTICS_AGENCY_LIST_LOAD.START, actionAgencyListLoad);
  yield takeEvery(reduxActions.ANALYTICS_MANAGER_LIST_LOAD.START, actionManagerListLoad);
  yield takeEvery(reduxActions.ANALYTICS_ADDRESS_LIST_LOAD.START, actionAddressListLoad);
  yield takeEvery(reduxActions.ANALYTICS_DRIVER_LIST_LOAD.START, actionDriverListLoad);
  yield takeEvery(reduxActions.ANALYTICS_WORKER_LIST_LOAD.START, actionWorkerListLoad);
  yield takeEvery(reduxActions.ANALYTICS_PHONE_LIST_LOAD.START, actionPhoneListLoad);
}


function* Request(action, apiRequest, actions) {
  const token = yield select((state) => state.auth.token);
  const cityActiveId = yield select((state) => state.city.activeId);
  const period = yield select((state) => state.analytics.period);
  const dateStart = yield select((state) => state.analytics.dateStart);
  const dateEnd = yield select((state) => state.analytics.dateEnd);

  let activeFilter = {};
  if (dateStart && dateEnd) {
    let start = dateStart;
    let end = dateEnd;
    if (start && isString(start)) {
      start = moment(start);
    }
    if (end && isString(end)) {
      end = moment(end);
    }
    activeFilter.date_start = start.format('YYYY-MM-DD');
    activeFilter.date_end = end.format('YYYY-MM-DD');
  } else {
    activeFilter.period = period || 'today';
  }

  yield put(actions.request());
  //yield put(replace({ search: '?' + getUrlSearch(activeFilter) }));

  try {
    const { error, data, statusCode } = yield call(() => apiRequest(token, {
      activeFilter: Object.assign(activeFilter, { city: cityActiveId })
    }));

    if (error) {
      yield put(actions.error({ data, statusCode }));
    } else {
      yield put(actions.complete(data));
    }
  } catch (error) {
    yield put(actions.error());
  }
}


export function* actionOrderLoad(action) {
  yield Request(action, api.analyticsOrderLoad, reduxActions.actions.orderLoad);
}

export function* actionClientLoad(action) {
  yield Request(action, api.analyticsClientLoad, reduxActions.actions.clientLoad);
}

export function* actionClientListLoad(action) {
  yield Request(action, api.analyticsClientListLoad, reduxActions.actions.clientListLoad);
}

export function* actionAgencyListLoad(action) {
  yield Request(action, api.analyticsAgencyListLoad, reduxActions.actions.agencyListLoad);
}

export function* actionManagerListLoad(action) {
  yield Request(action, api.analyticsManagerListLoad, reduxActions.actions.managerListLoad);
}

export function* actionAddressListLoad(action) {
  yield Request(action, api.analyticsAddressListLoad, reduxActions.actions.addressListLoad);
}

export function* actionDriverListLoad(action) {
  yield Request(action, api.analyticsDriverListLoad, reduxActions.actions.driverListLoad);
}

export function* actionWorkerListLoad(action) {
  yield Request(action, api.analyticsWorkerListLoad, reduxActions.actions.workerListLoad);
}

export function* actionPhoneListLoad(action) {
  yield Request(action, api.analyticsPhoneListLoad, reduxActions.actions.phoneListLoad);
}