import React, { PureComponent } from 'react';
import { Layout } from 'antd';

import { Logo } from 'components/common';
import HeaderUserPanel from 'components/blocks/User/HeaderUserPanel';
import { HeaderCityPanel } from 'components/blocks/City';
import { HeaderShiftPanel } from 'components/blocks/Shift';
import { HeaderOrderPanel } from 'components/blocks/Order';
import './styles.css';


export default class Header extends PureComponent {
  render() {
    const { history } = this.props;
    return (
      <Layout.Header>
        <Logo/>

        <HeaderShiftPanel history={history}/>
        <HeaderOrderPanel/>

        <div className="header-divider"/>

        <HeaderCityPanel/>
        <HeaderUserPanel/>
      </Layout.Header>
    );
  }
}
