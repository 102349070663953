import React, { Component } from 'react';
import { Icon, Button, Upload } from "antd";
import classNames from "classnames";

import { arrayIsEqual, getUrl } from 'utils';
import { endpoints } from 'store/api/file';
import styles from './upload.module.css';


export default class FileUpload extends Component {
    state = {
        defaultFileList: [],
        fileList: [],
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextFileList = nextProps.fileList || [];
        if (arrayIsEqual(nextFileList, prevState.defaultFileList)) {
            return null;
        }

        let newState = {
            defaultFileList: nextFileList,
        };

        if (prevState.fileList.length === 0) {
            newState.fileList = nextFileList.map(item => {
                item.uid = item.id;
                item.url = getUrl(item.url);
                return item;
            });
        }

        return newState
    };

    handleChange = (info) => {
        const { mode, onChange } = this.props;

        let fileList = [];
        if (mode === 'single') {
            let item = info.file;
            if (item.response) {
                item.uid = item.response.id;
                item.id = item.response.id;
                item.url = item.response.url;
                item.name = item.response.name;
                item.sha1 = item.response.sha1;
            }
            fileList = [item]
        } else {
            let length = info.fileList.length, seen = new Set();
            for (let i = 0; i < length; i++) {
                let item = info.fileList[i];
                if (item.response) {
                    item.uid = item.response.id;
                    item.id = item.response.id;
                    item.url = item.response.url;
                    item.name = item.response.name;
                    item.sha1 = item.response.sha1;
                }

                if (seen.has(item.uid)) continue;
                seen.add(item.uid);
                fileList.push(item);
            }
        }

        this.setState({ fileList: fileList });
        if (info.file.status === 'done' || info.file.status === 'removed') {
            onChange && onChange(fileList.map(item => ({
                uid: item.uid,
                id: item.id,
                url: item.url,
                name: item.name,
                sha1: item.sha1,
            })));
        }
    };

    render() {
        const { className, mode, onChange, fileList, ...otherProps } = this.props;

        const cx = classNames([
            styles.main,
            className,
        ]);

        return (
            <Upload
                className={cx}
                fileList={this.state.fileList}
                action={endpoints.upload}
                {...otherProps}
                onChange={this.handleChange}
            >
                <Button>
                    <Icon type="upload"/> Загрузить файл
                </Button>
            </Upload>
        );
    }
}
