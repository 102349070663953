import React from 'react';
import { withRouter } from 'react-router-dom';
import './styles.css';
import './page_transitions.css';


function Page({ children }) {
  return (
    <section className="page">
      {children}
    </section>
  );
}

export default withRouter(Page);