import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { Icon } from 'antd';
import classNames from 'classnames';

import { actions } from 'store';
import { getFullNameOrEmail } from 'utils/helpers';
import styles from './user_panel.module.css';


class HeaderUserPanel extends Component {
  constructor(props, context) {
    super(props, context);

    this.wrapRef = React.createRef();
    this.state = {
      opened: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.state.opened && this.wrapRef && !this.wrapRef.current.contains(event.target)) {
      this.setState({
        opened: false
      });
    }
  };

  toggle = () => {
    this.setState({
      opened: !this.state.opened
    });
  };

  logout = () => {
    this.props.actions.auth.logout();
  };

  render() {
    const { user_fields } = this.props;
    const { opened } = this.state;

    const cx = classNames([
      styles.container,
      opened && styles.active,
    ]);

    return (
      <div ref={this.wrapRef} className={cx}>
        <div className={styles.main} onClick={this.toggle}>
          <div className={styles.mainPhoto}>
            <Icon type="user"/>
          </div>
          <div className={styles.mainText}>
            {getFullNameOrEmail(user_fields)}
          </div>
          <div className={styles.mainIcon}>
            <Icon type="caret-down"/>
          </div>
        </div>

        {opened &&
        <div className={styles.menu}>
          <div className={styles.menuInner}>
            <NavLink className={styles.menuItem} to="/settings">
              <Icon className={styles.menuIcon} type="setting"/>
              <span className={styles.menuText}>Настройки</span>
            </NavLink>

            <div className={styles.menuItem} onClick={this.logout}>
              <Icon className={styles.menuIcon} type="logout"/>
              <span className={styles.menuText}>Выход</span>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user_fields: state.user.fields,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      auth: bindActionCreators(Object.assign({}, actions.auth), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserPanel);
