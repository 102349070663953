import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const SERVICE_LIST_LOAD = createRequestTypes('SERVICE_LIST_LOAD');
export const SERVICE_DETAIL_LOAD = createRequestTypes('SERVICE_DETAIL_LOAD');
export const SERVICE_UPDATE = createRequestTypes('SERVICE_UPDATE');
export const SERVICE_DELETE = createRequestTypes('SERVICE_DELETE');
export const SERVICE_SHOW_EDIT_FORM = 'SERVICE_SHOW_EDIT_FORM';
export const SERVICE_SELECT = 'SERVICE_SELECT';


export const actions = {
  showEditForm: createAction(SERVICE_SHOW_EDIT_FORM),
  select: createAction(SERVICE_SELECT),

  listLoad: createRequestActions(SERVICE_LIST_LOAD),
  detailLoad: createRequestActions(SERVICE_DETAIL_LOAD),
  update: createRequestActions(SERVICE_UPDATE),
  delete: createRequestActions(SERVICE_DELETE),
};
