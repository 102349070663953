import React, { Component } from 'react';
import { Button, Modal, Form, Spin } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";

import MainFieldSet from './MainFieldSet';


class UserForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { user, preventLoadDetail } = this.props;
        const instance = user.editObject;

        let data = Object.assign({}, values);
        if (instance && instance.id) {
          data.id = instance.id;
        }
        if (preventLoadDetail) {
          data.preventLoadDetail = preventLoadDetail;
        }
        this.props.actions.user.update(data);
      }
    });
  };

  close = () => {
    this.props.actions.user.showEditForm({ show: false });
  };

  render() {
    const { user } = this.props;
    const { showEditForm, updateStatus, editObject } = user;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const isLoading = updateStatus.isLoading;

    return (
      <Modal
        width={600}
        visible={showEditForm}
        title="Редактирование пользователя"
        onCancel={this.close}
        maskClosable={false}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={this.close} disabled={isLoading}>Отмена</Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
            Сохранить
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form onSubmit={this.handleSubmit} autoComplete="off">
            {errorData.non_field_errors &&
              <div className="form-error">{errorData.non_field_errors}</div>}

            <MainFieldSet errorData={errorData} instance={instance} {...this.props} />
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const WrappedForm = Form.create()(UserForm);

function mapStateToProps(state) {
  return {
    currentUser: state.user.fields,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      user: bindActionCreators(Object.assign({}, actions.user), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm);
