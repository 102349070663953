import React, { Component } from 'react';

import { ClientAddressDetail } from "components/blocks/ClientAddress";


export default class ClientAddressDetailScreen extends Component {
  render() {
    return (
      <div className="page-main">
        <ClientAddressDetail/>
      </div>
    );
  }
}
