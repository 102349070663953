import { createAction } from "redux-actions";
import { createRequestTypes, createRequestActions } from 'utils';

export const ANALYTICS_PERIOD_SELECT = 'ANALYTICS_PERIOD_SELECT';
export const ANALYTICS_ORDER_LOAD = createRequestTypes('ANALYTICS_ORDER_LOAD');
export const ANALYTICS_CLIENT_LOAD = createRequestTypes('ANALYTICS_CLIENT_LOAD');
export const ANALYTICS_CLIENT_LIST_LOAD = createRequestTypes('ANALYTICS_CLIENT_LIST_LOAD');
export const ANALYTICS_AGENCY_LIST_LOAD = createRequestTypes('ANALYTICS_AGENCY_LIST_LOAD');
export const ANALYTICS_MANAGER_LIST_LOAD = createRequestTypes('ANALYTICS_MANAGER_LIST_LOAD');
export const ANALYTICS_ADDRESS_LIST_LOAD = createRequestTypes('ANALYTICS_ADDRESS_LIST_LOAD');
export const ANALYTICS_DRIVER_LIST_LOAD = createRequestTypes('ANALYTICS_DRIVER_LIST_LOAD');
export const ANALYTICS_WORKER_LIST_LOAD = createRequestTypes('ANALYTICS_WORKER_LIST_LOAD');
export const ANALYTICS_PHONE_LIST_LOAD = createRequestTypes('ANALYTICS_PHONE_LIST_LOAD');


export const actions = {
  periodSelect: createAction(ANALYTICS_PERIOD_SELECT),

  orderLoad: createRequestActions(ANALYTICS_ORDER_LOAD),
  clientLoad: createRequestActions(ANALYTICS_CLIENT_LOAD),
  clientListLoad: createRequestActions(ANALYTICS_CLIENT_LIST_LOAD),
  agencyListLoad: createRequestActions(ANALYTICS_AGENCY_LIST_LOAD),
  managerListLoad: createRequestActions(ANALYTICS_MANAGER_LIST_LOAD),
  addressListLoad: createRequestActions(ANALYTICS_ADDRESS_LIST_LOAD),
  driverListLoad: createRequestActions(ANALYTICS_DRIVER_LIST_LOAD),
  workerListLoad: createRequestActions(ANALYTICS_WORKER_LIST_LOAD),
  phoneListLoad: createRequestActions(ANALYTICS_PHONE_LIST_LOAD),
};
