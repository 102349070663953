import React, { Component } from 'react';
import { Route } from 'react-router';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { PageHeader } from "components/layout";
import { Loader } from "components/common";
import { AddressForm } from "components/blocks/Address";
import DetailScreen from './DetailScreen';
import HistoryScreen from './HistoryScreen';


class AddressDetailScreen extends Component {
  componentDidMount() {
    const { addressId } = this.props.match.params;
    if (addressId) {
      this.props.actions.address.detailLoad({ id: addressId });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevId = prevProps.match.params.addressId;
    const nextId = this.props.match.params.addressId;
    if (nextId && prevId !== nextId) {
      this.props.actions.address.detailLoad({ id: nextId });
    }
  }

  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.address.showEditForm({ show: true, object: item });
  };

  render() {
    const { history, address } = this.props;
    const { agencyId, addressId } = this.props.match.params;
    const detail = address.detail || {};
    const agency = detail.agency || {};

    return (
      <div className="page-inner">
        {addressId &&
        <PageHeader
          mode="detail"
          history={history}
          title={detail.address}
          breadcrumbs={[
            { title: '', icon: 'home', path: '/', isBack: false },
            { title: 'Агентства', icon: '', path: '/agency', isBack: true },
            { title: agency.name, icon: '', path: `/agency/${agency.id}/address`, isBack: true },
          ]}
          actions={[
            <Button type="primary" onClick={() => this.showEditForm(detail)}>
              <Icon type="edit"/>
              <span>Редактировать</span>
            </Button>
          ]}
          basePath={`/agency/${agencyId}/address/${addressId}`}
          tabs={[
            { title: 'Основное', key: 'detail' },
            { title: 'История изменений', key: 'history' },
          ]}
        />}

        <Route exact path="/agency/:agencyId/address/:addressId" component={DetailScreen}/>
        <Route exact path="/agency/:agencyId/address/:addressId/history" component={HistoryScreen}/>

        <AddressForm/>
        <Loader active={address.loadDetailStatus.isLoading}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    address: state.address,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      address: bindActionCreators(Object.assign({}, actions.address), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressDetailScreen);
