import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Icon, Popconfirm } from "antd";
import classNames from "classnames";

import { actions } from "store";
import { ActionButton, DateTimeText, Descriptions, Status } from 'components/common';
import { WorkerValue } from 'components/blocks/Worker';
import styles from "./card.module.css";


class WorkerCard extends PureComponent {
  showEditForm = (item) => {
    this.props.actions.worker.showEditForm({ show: true, object: item })
  };

  showOrderWorkerEditFrom = (orderWorker) => {
    this.props.actions.orderWorker.showEditForm({ show: true, object: orderWorker, orderId: orderWorker.order });
  };

  showOrderWorkerReturnFrom = (orderWorker) => {
    this.props.actions.orderWorker.showReturnForm({ show: true, object: orderWorker, orderId: orderWorker.order });
  };

  orderWorkerComplete = (orderWorker) => {
    this.props.actions.orderWorker.update({ id: orderWorker.id, status: Status.COMPLETED });
  };

  /*orderWorkerReturn = (orderWorker) => {
    this.props.actions.orderWorker.update({ id: orderWorker.id, status: Status.RETURN });
  };*/

  render() {
    const { className, style, size, detail, orderWorker, workerActions, showActions, isManager } = this.props;
    if (!detail || !detail.id) return null;

    const cx = classNames([
      styles.container,
      className,
      styles[`size--${size}`],
    ]);

    let
      actionButtons = [],
      prolongDurations = [],
      date_created, date_planned, date_completed, date_rejected, date_return, return_duration, return_price;
    if (orderWorker) {
      if (isManager && [Status.APPROVED, Status.PROLONGED].includes(orderWorker.status)) {
        actionButtons.push(
          <Button key="prolong" type="xs" size="small" onClick={() => this.showOrderWorkerEditFrom(orderWorker)}
                  title="Продлить">
            <Icon type="reload"/>{size !== 'xs' ? 'Продлить' : 'прод.'}
          </Button>,
          <Popconfirm key="complete"
                      placement="bottomLeft"
                      title="Уверены, что хотите завершить?" okText="Завершить" cancelText="Не надо"
                      onConfirm={() => this.orderWorkerComplete(orderWorker)}>
            <Button type="xs" size="small" title="Завершить">
              <Icon type="check"/>{size !== 'xs' ? 'Завершить' : 'зав.'}
            </Button>
          </Popconfirm>,
          <Button key="return"
                  type="xs" size="small" title="Возврат" onClick={() => this.showOrderWorkerReturnFrom(orderWorker)}>
            <Icon type="retweet"/>{size !== 'xs' ? 'Возврат' : 'возв.'}
          </Button>
        );
      }

      detail.status = orderWorker.status;
      prolongDurations = orderWorker.prolong_durations.slice();
      date_created = orderWorker.date_created;
      date_completed = orderWorker.date_completed;
      date_rejected = orderWorker.date_rejected;
      date_return = orderWorker.date_return;
      return_duration = orderWorker.return_duration;
      return_price = orderWorker.return_price;

      if (!date_completed && !date_rejected && !date_return) {
        date_planned = orderWorker.date_planned;
      }
    }

    return (
      <div className={cx} style={style}>
        <div className={styles.header}>
          <div className={styles.headerMain}>
            <div className={styles.headerTitle}>
              <WorkerValue detail={detail} size={size} actions={isManager && workerActions}/>
            </div>
          </div>
          <div className={styles.headerActions}>
            {isManager && showActions && <ActionButton
              actions={[
                { title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(detail) },
              ]}
            />}
          </div>
        </div>

        {size !== 'xs' && orderWorker &&
        <div className={styles.content}>
          <Descriptions column={3} gutter={[16, 16]}>
            <Descriptions.Item label="Начало"><DateTimeText date={date_created}/></Descriptions.Item>
            <Descriptions.Item label="Длительность">{orderWorker.duration} ч.</Descriptions.Item>

            {date_planned &&
            <Descriptions.Item label="План. заверш."><DateTimeText date={date_planned}/></Descriptions.Item>}

            {date_completed &&
            <Descriptions.Item label="Завершение"><DateTimeText date={date_completed}/></Descriptions.Item>}

            {date_rejected &&
            <Descriptions.Item label="Дата отказа"><DateTimeText date={date_rejected}/></Descriptions.Item>}

            {date_return &&
            <Descriptions.Item label="Дата возврата"><DateTimeText date={date_return}/></Descriptions.Item>}

            {date_return &&
            <Descriptions.Item label="Возврат">{return_duration}ч. {return_price}руб.</Descriptions.Item>}
          </Descriptions>

          {prolongDurations.length > 0 &&
          <div className={styles.prolong}>
            <div className={styles.prolongTitle}>Продления:</div>
            {prolongDurations.map((item, idx) => (
              <div key={`p${idx}`} className={styles.prolongItem}>
                <div className={styles.prolongItemCol}>На {item.duration} ч.</div>
                <DateTimeText className={styles.prolongItemCol} date={item.date_created}/>
              </div>
            ))}
          </div>}
        </div>}

        {actionButtons.length > 0 && <div className={styles.bottomActions}>{actionButtons}</div>}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      worker: bindActionCreators(Object.assign({}, actions.worker), dispatch),
      orderCar: bindActionCreators(Object.assign({}, actions.orderCar), dispatch),
      orderWorker: bindActionCreators(Object.assign({}, actions.orderWorker), dispatch),
    }
  }
}

export default connect(null, mapDispatchToProps)(WorkerCard);
