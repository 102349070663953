import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Radio, DatePicker } from 'antd';
import moment from "moment";

import { actions } from "store";
import { isString } from "utils";
import styles from './header.module.css';


class AnalyticsHeader extends Component {
  periodChange = (e) => {
    this.props.actions.analytics.periodSelect({ period: e.target.value });
  };

  dateChange = (dates) => {
    console.log(dates);
    this.props.actions.analytics.periodSelect({ dateStart: dates[0], dateEnd: dates[1] });
  };

  render() {
    const { analytics } = this.props;
    const { period, dateStart, dateEnd } = analytics;
    const today = moment();
    let _period = period;
    let start = dateStart;
    let end = dateEnd;
    if (start && isString(start)) {
      start = moment(start);
    }
    if (end && isString(end)) {
      end = moment(end);
    }
    if (!start && !end) {
      switch (period) {
        case "yesterday":
          let dt = today.add(-1, 'day')
          start = dt
          end = dt
          break;
        case "week":
          start = today.add(-7, 'day')
          end = today
          break;
        case "month":
          start = today.add(-30, 'day')
          end = today
          break;
        case "year":
          start = today.add(-365, 'day')
          end = today
          break;
        default:
          start = today;
          end = today;
      }
    } else {
      _period = '';
    }

    return (
      <header className="page-header page-header--detail">
        <div className="page-header-main">
          <div className={styles.nav}>
            <div className={styles.navBtns}>
              <Radio.Group defaultValue={_period} buttonStyle="solid" onChange={this.periodChange}>
                <Radio.Button value="today">Сегодня</Radio.Button>
                <Radio.Button value="yesterday">Вчера</Radio.Button>
                <Radio.Button value="week">Неделя</Radio.Button>
                <Radio.Button value="month">Месяц</Radio.Button>
                <Radio.Button value="year">Год</Radio.Button>
              </Radio.Group>
            </div>

            <div className={styles.navDate}>
              <DatePicker.RangePicker
                value={[start, end]}
                format={"DD.MM.YYYY"}
                allowClear={false}
                onChange={this.dateChange}
              />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

function mapStateToProps(state) {
  return {
    analytics: state.analytics,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      analytics: bindActionCreators(Object.assign({}, actions.analytics), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsHeader);
