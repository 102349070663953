import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Icon } from "antd";

import { actions } from "store";
import { PageHeader } from "components/layout";
import { AgencyList, AgencyForm } from "components/blocks/Agency";
import { bindActionCreators } from "redux";


class AgencyListScreen extends Component {
  showEditForm = () => {
    this.props.actions.agency.showEditForm({ show: true })
  };

  render() {
    const { history, cityActiveId } = this.props;
    return (
      <div className="page-inner">
        <PageHeader
          history={history}
          title="Агентства"
          breadcrumbs={null}
        />

        <div className="page-main">
          <AgencyList
            activeFilter={{ city: cityActiveId }}
            topActions={[
              <Button type="primary" onClick={() => this.showEditForm()}>
                <Icon type="plus"/>
                <span>Добавить</span>
              </Button>
            ]}
          />
        </div>

        <AgencyForm/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cityActiveId: state.city.activeId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      agency: bindActionCreators(Object.assign({}, actions.agency), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyListScreen);
