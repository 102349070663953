import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const ORDER_APARTMENT_LIST_LOAD = createRequestTypes('ORDER_APARTMENT_LIST_LOAD');
export const ORDER_APARTMENT_DETAIL_LOAD = createRequestTypes('ORDER_APARTMENT_DETAIL_LOAD');
export const ORDER_APARTMENT_UPDATE = createRequestTypes('ORDER_APARTMENT_UPDATE');
export const ORDER_APARTMENT_DELETE = createRequestTypes('ORDER_APARTMENT_DELETE');
export const ORDER_APARTMENT_SHOW_EDIT_FORM = 'ORDER_APARTMENT_SHOW_EDIT_FORM';
export const ORDER_APARTMENT_SELECT = 'ORDER_APARTMENT_SELECT';


export const actions = {
  showEditForm: createAction(ORDER_APARTMENT_SHOW_EDIT_FORM),
  select: createAction(ORDER_APARTMENT_SELECT),

  listLoad: createRequestActions(ORDER_APARTMENT_LIST_LOAD),
  detailLoad: createRequestActions(ORDER_APARTMENT_DETAIL_LOAD),
  update: createRequestActions(ORDER_APARTMENT_UPDATE),
  delete: createRequestActions(ORDER_APARTMENT_DELETE),
};
