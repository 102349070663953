import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Icon } from "antd";

import { actions } from "store";
import { PageHeader } from "components/layout";
import { ServiceList, ServiceForm } from "components/blocks/Service";
import { bindActionCreators } from "redux";


class ServiceListScreen extends Component {
  showEditForm = () => {
    this.props.actions.service.showEditForm({ show: true })
  };

  render() {
    return (
      <div className="page-inner">
        <PageHeader
          title="Услуги"
          actions={[
            <Button type="primary" onClick={() => this.showEditForm()}>
              <Icon type="plus"/>
              <span>Добавить</span>
            </Button>
          ]}
        />

        <div className="page-main">
          <ServiceList />
        </div>

        <ServiceForm/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    city: state.city,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      service: bindActionCreators(Object.assign({}, actions.service), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceListScreen);
