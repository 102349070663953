import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const SHIFT_LIST_LOAD = createRequestTypes('SHIFT_LIST_LOAD');
export const SHIFT_DETAIL_LOAD = createRequestTypes('SHIFT_DETAIL_LOAD');
export const SHIFT_UPDATE = createRequestTypes('SHIFT_UPDATE');
export const SHIFT_DELETE = createRequestTypes('SHIFT_DELETE');
export const SHIFT_SHOW_EDIT_FORM = 'SHIFT_SHOW_EDIT_FORM';
export const SHIFT_SELECT = 'SHIFT_SELECT';

export const SHIFT_CURRENT_LOAD = createRequestTypes('SHIFT_CURRENT_LOAD');
export const SHIFT_CURRENT_UPDATE = createRequestTypes('SHIFT_CURRENT_UPDATE');


export const actions = {
  showEditForm: createAction(SHIFT_SHOW_EDIT_FORM),
  select: createAction(SHIFT_SELECT),

  listLoad: createRequestActions(SHIFT_LIST_LOAD),
  detailLoad: createRequestActions(SHIFT_DETAIL_LOAD),
  update: createRequestActions(SHIFT_UPDATE),
  delete: createRequestActions(SHIFT_DELETE),

  currentLoad: createRequestActions(SHIFT_CURRENT_LOAD),
  currentUpdate: createRequestActions(SHIFT_CURRENT_UPDATE),
};
