import { handleActions } from 'redux-actions';
import { loadStates, loadStatus } from 'utils/redux';
import * as base from 'store/BaseReducers';

import * as reduxActions from './reduxActions';


const initialState = Object.assign({
    current: null,
    currentId: null,
    currentLoadStatus: loadStatus(loadStates.notAsked),
  },
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [reduxActions.SHIFT_SELECT]: (state, action) => base.select(state, action),
    [reduxActions.SHIFT_SHOW_EDIT_FORM]: (state, action) => base.showEditForm(state, action),

    [reduxActions.SHIFT_LIST_LOAD.REQUEST]: (state, action) => base.listLoadRequest(state, action),
    [reduxActions.SHIFT_LIST_LOAD.COMPLETE]: (state, action) => base.listLoadComplete(state, action),
    [reduxActions.SHIFT_LIST_LOAD.ERROR]: (state, action) => base.listLoadError(state, action),

    [reduxActions.SHIFT_DETAIL_LOAD.REQUEST]: (state, action) => base.detailLoadRequest(state, action),
    [reduxActions.SHIFT_DETAIL_LOAD.COMPLETE]: (state, action) => base.detailLoadComplete(state, action),
    [reduxActions.SHIFT_DETAIL_LOAD.ERROR]: (state, action) => base.detailLoadError(state, action),

    [reduxActions.SHIFT_UPDATE.REQUEST]: (state, action) => base.updateRequest(state, action),
    [reduxActions.SHIFT_UPDATE.COMPLETE]: (state, action) => base.updateComplete(state, action),
    [reduxActions.SHIFT_UPDATE.ERROR]: (state, action) => base.updateError(state, action),

    [reduxActions.SHIFT_DELETE.REQUEST]: (state, action) => base.deleteRequest(state, action),
    [reduxActions.SHIFT_DELETE.COMPLETE]: (state, action) => base.deleteComplete(state, action),
    [reduxActions.SHIFT_DELETE.ERROR]: (state, action) => base.deleteError(state, action),

    [reduxActions.SHIFT_CURRENT_LOAD.REQUEST]: (state, action) => ({
      ...state,
      currentLoadStatus: loadStatus(loadStates.loading),
    }),
    [reduxActions.SHIFT_CURRENT_LOAD.COMPLETE]: (state, action) => ({
      ...state,
      currentLoadStatus: loadStatus(loadStates.loaded),
      current: action.payload,
      currentId: action.payload.id,
    }),
    [reduxActions.SHIFT_CURRENT_LOAD.ERROR]: (state, action) => ({
      ...state,
      currentLoadStatus: loadStatus(loadStates.failed, action.payload),
    }),

    [reduxActions.SHIFT_CURRENT_UPDATE.REQUEST]: (state, action) => ({
      ...state,
      currentLoadStatus: loadStatus(loadStates.loading),
    }),
    [reduxActions.SHIFT_CURRENT_UPDATE.COMPLETE]: (state, action) => ({
      ...state,
      currentLoadStatus: loadStatus(loadStates.loaded),
      current: action.payload,
      currentId: action.payload.id,
    }),
    [reduxActions.SHIFT_CURRENT_UPDATE.ERROR]: (state, action) => ({
      ...state,
      currentLoadStatus: loadStatus(loadStates.failed, action.payload),
    }),
  },
  initialState
);
