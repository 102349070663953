import React, { Component } from 'react';
import { Route } from 'react-router';

import PhoneListScreen from './PhoneListScreen';
import PhoneDetailScreen from './PhoneDetailScreen';


export default class PhoneScreen extends Component {
  render() {
    return (
      <div className="page-main">
        <Route exact path="/agency/:agencyId/phone" component={PhoneListScreen}/>
        <Route exact path="/agency/:agencyId/phone/:phoneId" component={PhoneDetailScreen}/>
        <Route exact path="/agency/:agencyId/phone/:phoneId/history" component={PhoneDetailScreen}/>
      </div>
    );
  }
}
