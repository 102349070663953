import React, { forwardRef } from 'react';
import { Input, Menu, Icon } from 'antd';
import { connect } from 'react-redux';
import ReactInputMask from 'react-input-mask';

import { clientListLoad } from "store/api/client";
import { FormItem, loadStates, loadStatus } from "utils";
import { DataLoadComponent } from 'components/common';
import styles from './field.module.css';


const InputMask = forwardRef((props, ref) => {
  return (
    <ReactInputMask {...props}>
      {(inputProps) => <Input {...inputProps} ref={ref} disabled={props.disabled ? props.disabled : null}/>}
    </ReactInputMask>
  )
});

const cleanPhone = (value) => {
  if (!value) return '';
  return value.replace('(', '').replace('(', '').split('-').join('').split(' ').join('').split('_').join('');
};


class ClientField extends DataLoadComponent {
  state = {
    items: [],
    client: null,
    loadListStatus: loadStatus(loadStates.notAsked),
  };

  apiListLoad = clientListLoad;

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  load = (phone) => {
    const { auth_token, onClientChoose } = this.props;

    this.setState({
      client: null,
      loadListStatus: loadStatus(loadStates.loading),
    });
    onClientChoose && onClientChoose(null);

    this.apiListLoad(auth_token, { activeFilter: { search: phone, limit: 5 } })
      .then(({ error, data }) => {
        if (error) {
          console.log(error);
          this.setState({
            loadListStatus: loadStatus(loadStates.failed, error),
          });
        } else {
          this.setState({
            items: data.results,
            loadListStatus: loadStatus(loadStates.loaded),
          });
        }
      })
  };

  handlePhoneChange = (e) => {
    const phone = cleanPhone(e.target.value);
    const { client } = this.state;
    if (phone && (!client || client.phone !== phone)) {
      this.load(phone);
    }
  };

  chooseClient = ({ item }) => {
    const { form, name, onClientChoose } = this.props;
    const { client } = item.props;
    form.setFieldsValue({
      [name]: client.phone,
    });
    this.setState({
      client: client,
      items: [],
      loadListStatus: loadStatus(loadStates.notAsked),
    });
    onClientChoose && onClientChoose(client);
  };

  render() {
    const { form, instance, name, label, required, disabled, errorData, layout } = this.props;
    //const { items, loadListStatus } = this.state;
    const initialValue = instance && instance.client ? instance.client.phone : this.props.initialValue;

    return (
      <div className={styles.field}>
        <FormItem
          form={form}
          instance={instance}
          name={name}
          label={label}
          required={required}
          disabled={disabled}
          errorData={errorData}
          initialValue={initialValue}
          layout={layout}
          rules={[
            {
              required: required,
              pattern: /^((\+7|7)+([0-9]){10})$/gm,
              transform: (value) => {
                return cleanPhone(value);
              },
              message: 'Неправильный формат телефона'
            },
          ]}
          normalize={(value, prevValue, allValues) => {
            return cleanPhone(value);
          }}
          getValueFromEvent={e => {
            const { target } = e;
            return cleanPhone(target.value);
          }}
          getValueProps={value => {
            return { value };
          }}
        >
          <InputMask
            size="large"
            mask="+7 999 999-99-99"
            onChange={this.handlePhoneChange}
            disabled={disabled}
          />
        </FormItem>
        {this.state.items.length > 0 &&
        <div className={styles.dropdown}>
          <Menu onClick={this.chooseClient}>
            {this.state.items.map(item =>
              <Menu.Item key={item.id} client={item} className={item.is_banned ? styles.itemBanned: styles.item}>
                <div className={styles.inner}>
                  <div>
                    <div className={styles.phone}>{item.phone} {item.is_banned && <span>забанен</span>}</div>
                    <div className={styles.stat}>
                      <div className={styles.statItem1}>
                        <Icon type="check-circle" className={styles.statIcon}/>Успешно: {item.stats.order_completed_count}
                      </div>
                      <div className={styles.statItem2}>
                        <Icon type="sync" className={styles.statIcon}/>Продлено: {item.stats.order_prolong}ч.
                      </div>
                      <div className={styles.statItem3}>
                        <Icon type="stop" className={styles.statIcon}/>Отказ: {item.stats.order_rejected_count}
                      </div>
                    </div>
                  </div>
                </div>
              </Menu.Item>
            )}
          </Menu>
        </div>}
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(ClientField);
