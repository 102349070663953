import { createBlacklistFilter } from "redux-persist-transform-filter";

const offlineOptions = {
  blacklist: ['phone', 'agency', 'profile', 'service', 'client', 'clientAddress', 'order', 'worker', 'driver', 'car'],
  transforms: [
    createBlacklistFilter('app', ['loadStatus']),
    createBlacklistFilter('auth', ['loginStatus']),
    createBlacklistFilter('city', [
      'loadListStatus', 'loadDetailStatus', 'updateStatus', 'deleteStatus', 'detail', 'list', 'byId',
    ]),
    createBlacklistFilter('user', [
      'loadListStatus', 'loadDetailStatus', 'updateStatus', 'deleteStatus', 'detail', 'list', 'byId',
    ]),
  ],
};

export {
  offlineOptions,
};
