import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu, Icon } from 'antd';

import { OrderSvg, DriverSvg, GirlSvg, PinSvg, AgencySvg, ServiceSvg } from 'components/common/SvgIcons';
import './styles.css';


class Navigation extends Component {
  getCurrentRootNav() {
    const { pathname } = this.props.history.location;
    return pathname.split('/')[1] || 'dashboard';
  }

  navigate = ({ item, key, keyPath }) => {
    if (key === 'dashboard') {
      this.props.history.push(`/`);
    } else {
      this.props.history.push(`/${key}`);
    }
  };

  render() {
    const { currentUser } = this.props;
    const currentRootNav = this.getCurrentRootNav();

    let adminMenuItems = [
      <Menu.Item key="shift"><Icon type="usergroup-add" /><span>Смены</span></Menu.Item>,
      <Menu.Divider key="d1" style={{ margin: '16px 0' }} />,
    ];

    if (!currentUser.city) {
      adminMenuItems.push(
        <Menu.Item key="city"><Icon component={PinSvg} /><span>Города</span></Menu.Item>
      )
    }

    adminMenuItems = adminMenuItems.concat([
      <Menu.Item key="agency"><Icon component={AgencySvg} /><span>Агентства</span></Menu.Item>,
      <Menu.Item key="profile"><Icon type="idcard" /><span>Анкеты на сайтах</span></Menu.Item>,
      <Menu.Item key="service"><Icon component={ServiceSvg} /><span>Услуги</span></Menu.Item>,
      <Menu.Item key="client"><Icon type="solution" /><span>Клиенты</span></Menu.Item>,
      <Menu.Item key="user"><Icon type="team" /><span>Пользователи</span></Menu.Item>,
    ]);


    if (!currentUser.city) {
      adminMenuItems.push(
        <Menu.Item key="audit"><Icon type="team" /><span>Лог входов</span></Menu.Item>,
      )
    }

    return (
      <Menu
        className="navigation"
        defaultOpenKeys={['admin']}
        defaultSelectedKeys={[currentRootNav]}
        selectedKeys={[currentRootNav]}
        onClick={this.navigate}
        mode="inline"
        inlineIndent={16}
      >
        <Menu.Item key="dashboard"><Icon type="dashboard" /><span>Рабочий стол</span></Menu.Item>
        <Menu.Item key="order"><Icon component={OrderSvg} /><span>Заказы</span></Menu.Item>
        <Menu.Item key="worker"><Icon component={GirlSvg} /><span>Девушки</span></Menu.Item>
        <Menu.Item key="driver"><Icon component={DriverSvg} /><span>Водители</span></Menu.Item>

        {currentUser.role === 'administrator' && adminMenuItems}
      </Menu>
    );
  }
}

function mapStateToProps(state) {
  return {
    router: state.router,
    currentUser: state.user.fields,
  };
}

export default connect(mapStateToProps)(Navigation);
