import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as reduxActions from './reduxActions';


const initialState = Object.assign({},
  base.ListState,
);

export default handleActions(
  {
    [reduxActions.HISTORY_LIST_LOAD.REQUEST]: (state, action) => base.listLoadRequest(state, action),
    [reduxActions.HISTORY_LIST_LOAD.COMPLETE]: (state, action) => base.listLoadComplete(state, action),
    [reduxActions.HISTORY_LIST_LOAD.ERROR]: (state, action) => base.listLoadError(state, action),
  },
  initialState
);
