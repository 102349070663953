import React, { PureComponent } from 'react';
import { Switch } from "antd";


export default class OnOff extends PureComponent {
  render() {
    const { item, param, onChange, updateItemId, updateStatus, preventLoadDetail } = this.props;

    return (
      <Switch
        checkedChildren="вкл" unCheckedChildren="выкл"
        checked={item[param]}
        loading={updateStatus.isLoading && updateItemId === item.id}
        onClick={() => {
          const data = { id: item.id, [param]: !item[param], preventLoadDetail };
          onChange(data);
        }}
      />
    );
  }
}
