import React, { Component } from 'react';
import { Checkbox, Input } from "antd";

import { FormItem } from "utils";
import { CitySelect } from "components/blocks/City";


export default class MainFieldSet extends Component {
  state = {
    layout: 'horizontal',
  };

  render() {
    const { form, instance, errorData, cityActiveId } = this.props;
    const { layout } = this.state;
    const itemProps = { form, instance, errorData, layout };

    return (
      <div className="form-block">
        <CitySelect name="city" label="Город" required={true} disabled={true}
                    initialValue={parseInt(cityActiveId)} {...itemProps}/>

        <FormItem name="address" label="Адрес" required={true} {...itemProps}>
          <Input size="large"/>
        </FormItem>

        <FormItem name="is_banned" label="Забанен" required={false} valuePropName="checked" {...itemProps}>
          <Checkbox/>
        </FormItem>
      </div>
    );
  }
}
