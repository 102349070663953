import React, { Component } from 'react';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import ShiftListScreen from './ShiftListScreen';
import ShiftDetailScreen from './ShiftDetailScreen';


export default class ShiftScreen extends Component {
  render() {
    return (
      <Page>
        <Route exact path="/shift" component={ShiftListScreen}/>
        <Route exact path="/shift/:shiftId" component={ShiftDetailScreen}/>
        <Route exact path="/shift/:shiftId/history" component={ShiftDetailScreen}/>
        <Route exact path="/shift/:shiftId/order" component={ShiftDetailScreen}/>
      </Page>
    );
  }
}
