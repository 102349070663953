import React from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { apartmentDetailLoad } from "store/api/apartment";
import { DataLoadComponent } from 'components/common';
import { AddressValue } from "components/blocks/Address";


class ApartmentValue extends DataLoadComponent {
  apiDetailLoad = apartmentDetailLoad;

  render() {
    const { id, asLink, state } = this.props;
    const { detail, loadStatus } = this.state;
    const address = detail && detail.address;
    const _id = (detail && detail.id) || id;

    return (
      <Spin spinning={loadStatus.isLoading}>
        {asLink && _id ?
          <Link to={{ pathname: `/apartment/${_id}`, state: state }}>
            {address ? <AddressValue detail={address}/>: _id}
          </Link> :
          <span>{address ? <AddressValue detail={address}/>: _id}</span>}
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(ApartmentValue);
