import React, { Component } from 'react';

import { historyLoad } from "store/api/driver";
import { HistoryList } from 'components/common';


export default class HistoryScreen extends Component {
  render() {
    const { driverId } = this.props.match.params;

    return (
      <div className="page-main">
        <HistoryList historyLoad={historyLoad} objectId={driverId}/>
      </div>
    );
  }
}
