import React, { Component } from 'react';
import { Route } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Icon, Popconfirm } from 'antd';

import { actions } from "store";
import { PageHeader } from "components/layout";
import { Loader, Status } from "components/common";
import { OrderApartmentForm } from 'components/blocks/OrderApartment';
import { OrderCarForm } from 'components/blocks/OrderCar';
import { OrderWorkerForm, OrderWorkerReturnForm } from 'components/blocks/OrderWorker';
import DetailScreen from './DetailScreen';
import HistoryScreen from './HistoryScreen';


class OrderDetailScreen extends Component {
  state = {
    id: null,
    cars: [],
    activeCarsCount: 0,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { order } = nextProps;
    const detail = order.detail || {};

    if (prevState.id !== detail.id) {
      const cars = detail.cars || [];
      const activeCarsCount = cars.filter(car => car.status === 10).length;

      return {
        id: detail.id,
        cars,
        activeCarsCount,
      }
    }

    return null;
  }

  componentDidMount() {
    const { orderId } = this.props.match.params;
    if (orderId) {
      this.props.actions.order.detailLoad({ id: orderId });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevId = prevProps.match.params.orderId;
    const nextId = this.props.match.params.orderId;
    if (nextId && prevId !== nextId) {
      this.props.actions.order.detailLoad({ id: nextId });
    }
  }

  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.order.showEditForm({ show: true, object: item });
  };

  showOrderApartmentEditForm = (item = null) => {
    const { actions, order } = this.props;
    actions.orderApartment.showEditForm({ show: true, object: item, order: order.detail });
  };

  showOrderCarEditForm = (item = null) => {
    const { actions, order } = this.props;
    actions.orderCar.showEditForm({ show: true, object: item, order: order.detail });
  };

  complete = () => {
    const { orderId } = this.props.match.params;
    this.props.actions.order.update({ id: orderId, status: 29 });
  };

  cancel = () => {
    const { orderId } = this.props.match.params;
    this.props.actions.order.update({ id: orderId, status: 30 });
  };

  render() {
    const { history, currentUser, order } = this.props;
    const { orderId } = this.props.match.params;
    const detail = order.detail || {};
    const isManager = currentUser.role === 'manager';

    let actions = [];
    if (isManager && [0, 10, 31].includes(detail.status)) {
      actions.push(
        <Button type="default" onClick={() => this.showOrderCarEditForm()}>
          <Icon type="car"/>
          <span>Назначить машину</span>
        </Button>
      );
    }

    if (isManager && [0, 31].includes(detail.status)) {
      actions.push(
        <Button type="default" onClick={() => this.showOrderApartmentEditForm()}>
          <Icon type="environment"/>
          <span>Назначить апартаменты</span>
        </Button>
      )
    }

    if (isManager && [20, 21, 32].includes(detail.status)) {
      actions.push(
        <Popconfirm placement="bottomLeft"
                    title="Уверены, что хотите завершить?" okText="Завершить" cancelText="Отмена"
                    onConfirm={() => this.complete(detail)}>
          <Button type="default"><Icon type="check"/>Завершить</Button>
        </Popconfirm>
      )
    }

    if (isManager && [0, 10, 11].includes(detail.status)) {
      actions.push(
        <Popconfirm placement="bottomLeft"
                    title="Уверены, что отбой?" okText="Отбой" cancelText="Отмена"
                    onConfirm={() => this.cancel(detail)}>
          <Button type="default"><Icon type="stop"/>Отбой</Button>
        </Popconfirm>
      )
    }

    isManager && actions.push(
      <Button type="primary" size="large" onClick={() => this.showEditForm(detail)}>
        <Icon type="edit"/>
        <span>Редактировать</span>
      </Button>
    );

    return (
      <div className="page-inner">
        {orderId &&
        <PageHeader
          mode="detail"
          history={history}
          title={`Заказ №${orderId}`}
          info={<Status status={detail.status}/>}
          breadcrumbs={[
            { title: '', icon: 'home', path: '/', isBack: false },
            { title: 'Заказы', icon: '', path: '/order', isBack: true },
          ]}
          actions={actions}
          basePath={`/order/${orderId}`}
          tabs={[
            { title: 'Основное', key: 'detail' },
            { title: 'История изменений', key: 'history' },
          ]}
        />}

        <Route exact path="/order/:orderId" component={DetailScreen}/>
        <Route exact path="/order/:orderId/history" component={HistoryScreen}/>

        <OrderApartmentForm/>
        <OrderCarForm/>
        <OrderWorkerForm/>
        <OrderWorkerReturnForm/>

        <Loader active={order.loadDetailStatus.isLoading}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.fields,
    order: state.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      order: bindActionCreators(Object.assign({}, actions.order), dispatch),
      orderApartment: bindActionCreators(Object.assign({}, actions.orderApartment), dispatch),
      orderCar: bindActionCreators(Object.assign({}, actions.orderCar), dispatch),
      orderWorker: bindActionCreators(Object.assign({}, actions.orderWorker), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailScreen);
