import React, { Component } from 'react';
import { Icon } from "antd";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { tableColumn, arrayIsEqual, dictIsEqual } from "utils";
import { Loader, SimpleTableList } from "components/common";
import styles from "./stats.module.css";


export default class BaseStats extends Component {
  dataName = 'agencyList';
  nameLabel = 'Название';

  state = {
    columnList: ['name', 'order_count', 'order_count_completed', 'order_count_rejected', 'order_prolong'],
    columnByName: {
      name: tableColumn(this.nameLabel, 'name', {
        sorter: (a, b) => a.name.localeCompare(b.name),
        //width: 150,
      }),
      order_count: tableColumn('Заказы', 'order_all_count', {
        sorter: (a, b) => a.order_all_count - b.order_all_count,
        width: 130,
      }),
      order_count_completed: tableColumn(<div><Icon type="check"/> Успешные</div>, 'order_completed_count', {
        sorter: (a, b) => a.order_completed_count - b.order_completed_count,
        width: 130,
      }),
      order_count_rejected: tableColumn(<div><Icon type="stop"/> Отказы</div>, 'order_rejected_count', {
        sorter: (a, b) => a.order_rejected_count - b.order_rejected_count,
        width: 130,
      }),
      order_prolong: tableColumn(<div><Icon type="clock-circle"/> Продления</div>, 'order_prolong', {
        sorter: (a, b) => a.order_prolong - b.order_prolong,
        width: 130,
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { period, dateStart, dateEnd } = this.props.analytics;
    const prevPeriod = prevProps.analytics.period;
    const prevDateStart = prevProps.analytics.dateStart;
    const prevDateEnd = prevProps.analytics.dateEnd;
    if (!dictIsEqual(this.props.cityActiveId, prevProps.cityActiveId) ||
      period !== prevPeriod || dateStart !== prevDateStart || dateEnd !== prevDateEnd ) {
      this.load()
    }
  }

  load = () => {

  };

  renderChart() {
    const { analytics } = this.props;
    const data = analytics[`${this.dataName}`];

    const labels = {
      order_completed_count: 'Успешные заказы',
      order_rejected_count: 'Отказы',
    };

    return (
      <div className={styles.chart}>
        <div className={styles.chartWrap}>
          <ResponsiveContainer>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="name"/>
              <YAxis/>
              <Tooltip formatter={(value, name, props) => ([value, labels[name]])}/>
              <Legend formatter={v => labels[v]}/>
              <Bar dataKey="order_completed_count" fill="#82ca9d"/>
              <Bar dataKey="order_rejected_count" fill="#FF625C"/>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }

  render() {
    const { analytics, history } = this.props;
    const { columnList, columnByName } = this.state;
    const data = analytics[`${this.dataName}`];
    const isLoading = analytics[`${this.dataName}Status`].isLoading;

    return (
      <div className={styles.container}>
        <div className={styles.table}>
          <SimpleTableList
            history={history}
            dataSource={data}
            columnList={columnList}
            columnByName={columnByName}
            onLoadData={this.load}
          />
        </div>

        <Loader active={isLoading}/>
      </div>
    );
  }
}
