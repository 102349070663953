import React, { Component } from 'react';
import { Icon, Modal, Upload } from "antd";
import classNames from "classnames";

import { getUrl, arrayIsEqual } from 'utils';
import { endpoints } from 'store/api/file';
import './photo_upload.css';


export default class PhotoUpload extends Component {
  state = {
    previewVisible: false,
    previewImage: '',
    defaultFileList: [],
    fileList: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let nextFileList = nextProps.fileList || [];
    if (!Array.isArray(nextFileList)) {
      nextFileList = [nextFileList];
    }
    if (arrayIsEqual(nextFileList, prevState.defaultFileList)) {
      return null;
    }

    let newState = {
      defaultFileList: nextFileList,
    };

    //if (prevState.fileList.length === 0) {
    newState.fileList = nextFileList.map(item => {
      item.uid = item.id;
      item.url = getUrl(item.url);
      return item;
    });
    //}

    return newState;
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  /*handleChange = (info) => {
    const { onChange } = this.props;
    this.setState({ fileList: info.fileList });
    if (info.file.status === 'done') {
      onChange && onChange(info.fileList.map(item => {
        if (item.response) {
          return item.response
        } else {
          return item
        }
      }));
    }
  };*/

  handleChange = (info) => {
    const { mode, onChange } = this.props;
    console.log('handleChange', info);

    let fileList = [];
    if (mode === 'single') {
      let item = info.file;
      if (item.response) {
        item.uid = item.response.id;
        item.id = item.response.id;
        item.url = item.response.url;
        item.name = item.response.name;
        item.sha1 = item.response.sha1;
      }
      if (item.status !== 'removed') {
        fileList = [item]
      }
    } else {
      let length = info.fileList.length, seen = new Set();
      for (let i = 0; i < length; i++) {
        let item = info.fileList[i];
        if (item.response) {
          item.uid = item.response.id;
          item.id = item.response.id;
          item.url = item.response.url;
          item.name = item.response.name;
          item.sha1 = item.response.sha1;
        }

        if (seen.has(item.uid)) continue;
        seen.add(item.uid);
        fileList.push(item);
      }
    }

    this.setState({ fileList: fileList });
    if (info.file.status === 'done' || info.file.status === 'removed') {
      const files = fileList.map(item => ({
        uid: item.uid,
        id: item.id,
        url: item.url,
        name: item.name,
        sha1: item.sha1,
      }));
      onChange && onChange(mode === 'single' && files.length > 0 ? files[0]: files);
    }
  };

  render() {
    const { className, mode, title, auth_token, onChange, fileList, ...otherProps } = this.props;
    const { previewVisible, previewImage } = this.state;

    const cx = classNames([
      "photo-upload",
      mode === 'single' && "photo-upload--full",
      className
    ]);

    return (
      <div className={cx}>
        <Upload
          className={mode === 'single' ? 'ant-upload-full' : ''}
          listType="picture-card"
          fileList={this.state.fileList}
          action={endpoints.upload}
          {...otherProps}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
        >
          {(mode !== 'single' || this.state.fileList.length === 0) &&
          <div className="photo-upload-btn">
            <Icon className="photo-upload-btn-icon" type="plus"/>
            <div className="photo-upload-btn-text">{title}</div>
          </div>
          }
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img style={{ width: '100%' }} src={previewImage} alt=""/>
        </Modal>
      </div>
    );
  }
}
