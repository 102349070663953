import React, { Component } from 'react';
import { Button, Modal, Form, Spin } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { Error } from 'components/common';
import MainFieldSet from "./MainFieldSet";


class ClientAddressForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { clientAddress } = this.props;
        const instance = clientAddress.editObject;

        let data = Object.assign({}, values);
        if (instance && instance.id) {
          data.id = instance.id;
        }
        this.props.actions.clientAddress.update(data);
      }
    });
  };

  close = () => {
    const { actions } = this.props;
    actions.clientAddress.showEditForm({ show: false });
  };

  render() {
    const { clientAddress } = this.props;
    const { showEditForm, updateStatus, editObject } = clientAddress;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const isLoading = clientAddress.updateStatus.isLoading;

    return (
      <Modal
        width={640}
        visible={showEditForm}
        title={instance ? `Редактирование ${instance.name}` : 'Новый адрес'}
        onCancel={this.close}
        maskClosable={false}
        destroyOnClose={true}
        footer={[
          <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit} size="large">
            Сохранить
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form onSubmit={this.handleSubmit} autoComplete="off"
                onKeyPress={(e) => e.key === 'Enter' && this.handleSubmit(e)}>
            <Error errors={errorData.non_field_errors}/>

            <MainFieldSet errorData={errorData} instance={instance} {...this.props}/>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const WrappedForm = Form.create()(ClientAddressForm);

function mapStateToProps(state) {
  return {
    cityActiveId: state.city.activeId,
    clientAddress: state.clientAddress,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      clientAddress: bindActionCreators(Object.assign({}, actions.clientAddress), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm);

