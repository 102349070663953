import { connect } from 'react-redux';

import { clientDetailLoad } from "store/api/client";
import { ValueComponent } from 'components/common';


class ClientValue extends ValueComponent {
  apiDetailLoad = clientDetailLoad;

  getValue() {
    const { id } = this.props;
    const { detail } = this.state;
    return (detail && detail.phone) || id;
  }

  getPath() {
    const { detail } = this.state;
    const detailId = detail && detail.id;
    return `/client/${detailId}`;
  }
}

function mapStateToProps(state) {
  return {
    router: state.router,
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(ClientValue);
