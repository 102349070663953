import React, { Component } from 'react';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import DriverListScreen from './DriverListScreen';
import DriverDetailScreen from './DriverDetailScreen';


export default class DriverScreen extends Component {
  render() {
    return (
      <Page>
        <Route exact path="/driver" component={DriverListScreen}/>
        <Route exact path="/driver/:driverId" component={DriverDetailScreen}/>
        <Route exact path="/driver/:driverId/history" component={DriverDetailScreen}/>
        <Route exact path="/driver/:driverId/order" component={DriverDetailScreen}/>
      </Page>
    );
  }
}
