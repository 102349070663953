import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Descriptions } from 'antd';

import { DateTimeText } from 'components/common';


class ServiceDetail extends Component {
  render() {
    const { service } = this.props;
    const detail = service.detail || {};

    return (
      <Row gutter={16}>
        <Col span={12}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Название">{detail.name}</Descriptions.Item>
            <Descriptions.Item label="Дата создания"><DateTimeText date={detail.date_created}/></Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    service: state.service,
  };
}

export default connect(mapStateToProps)(ServiceDetail);
