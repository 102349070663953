import { connect } from 'react-redux';

import { clientAddressDetailLoad } from "store/api/clientAddress";
import { ValueComponent } from 'components/common';


class ClientAddressValue extends ValueComponent {
  apiDetailLoad = clientAddressDetailLoad;

  getValue() {
    const { id } = this.props;
    const { detail } = this.state;
    return (detail && detail.address) || id;
  }

  getPath() {
    const { detail } = this.state;
    const detailId = detail && detail.id;
    return `/clientAddress/${detailId}`;
  }
}

function mapStateToProps(state) {
  return {
    router: state.router,
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(ClientAddressValue);
