import React, { Component } from 'react';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import AgencyListScreen from './AgencyListScreen';
import AgencyDetailScreen from './AgencyDetailScreen';
import PhoneDetailScreen from './AgencyDetailScreen/Phone/PhoneDetailScreen';
import AddressDetailScreen from './AgencyDetailScreen/Address/AddressDetailScreen';


export default class AgencyScreen extends Component {
  render() {
    return (
      <Page>
        <Route exact path="/agency" component={AgencyListScreen}/>
        <Route exact path="/agency/:agencyId" component={AgencyDetailScreen}/>
        <Route exact path="/agency/:agencyId/history" component={AgencyDetailScreen}/>

        <Route exact path="/agency/:agencyId/phone" component={AgencyDetailScreen}/>
        <Route exact path="/agency/:agencyId/phone/:phoneId" component={PhoneDetailScreen}/>
        <Route exact path="/agency/:agencyId/phone/:phoneId/history" component={PhoneDetailScreen}/>

        <Route exact path="/agency/:agencyId/address" component={AgencyDetailScreen}/>
        <Route exact path="/agency/:agencyId/address/:addressId" component={AddressDetailScreen}/>
        <Route exact path="/agency/:agencyId/address/:addressId/history" component={AddressDetailScreen}/>
      </Page>
    );
  }
}
