import React, { Component } from 'react';

import { PhoneDetail } from 'components/blocks/Phone';


export default class DetailScreen extends Component {
  render() {
    return (
      <div className="page-main">
        <PhoneDetail/>
      </div>
    );
  }
}
