import React, { Component } from 'react';

import { DriverDetail } from 'components/blocks/Driver';


export default class DetailScreen extends Component {
  render() {
    return (
      <div className="page-main">
        <DriverDetail/>
      </div>
    );
  }
}
