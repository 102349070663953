import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { actions } from "store";
import { Descriptions, DateTimeText, OnOff, Loader } from 'components/common';


class ClientAddressDetail extends Component {
  itemUpdate = (data) => {
    this.props.actions.clientAddress.update(data);
  };

  render() {
    const { clientAddress } = this.props;
    const detail = clientAddress.detail || {};

    return (
      <div>
        <Row gutter={16}>
          <Col span={12}>
            <Descriptions column={2} gutter={[16, 16]}>
              <Descriptions.Item label="Адрес">{detail.address}</Descriptions.Item>
              <Descriptions.Item label="Дата создания"><DateTimeText date={detail.date_created}/></Descriptions.Item>
              <Descriptions.Item label="Забанен">
                <OnOff
                  item={detail}
                  updateItemId={detail.id}
                  param="is_banned"
                  updateStatus={clientAddress.updateStatus}
                  onChange={this.itemUpdate}
                />
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        <Loader active={clientAddress.loadDetailStatus.isLoading} fixed={true} color="white"/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clientAddress: state.clientAddress,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      clientAddress: bindActionCreators(Object.assign({}, actions.clientAddress), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientAddressDetail);
