import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { getUrl } from "utils";
import { PARAMETERS, getParametetsValue } from "utils/choices";
import { Descriptions, DateTimeText, Fieldset } from 'components/common';
import { CityValue } from "components/blocks/City";
import { PhoneValue } from "components/blocks/Phone";
import { DistrictValue } from "components/blocks/District";
import { ServiceValue } from "components/blocks/Service";
import styles from './detail.module.css';


class ProfileDetail extends Component {
  render() {
    const { profile } = this.props;
    const detail = profile.detail || {};
    const parameters = detail.parameters || {};
    const prices = detail.prices || {};
    const photos = detail.photos;

    const districts = Array.isArray(detail.districts) ? detail.districts : [];
    const services = Array.isArray(detail.services) ? detail.services : [];

    return (
      <Row gutter={16}>
        <Col span={16}>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Город" span={24}>
              <CityValue detail={detail.city} asLink={true} isBack={true}/>
            </Descriptions.Item>
            <Descriptions.Item label="Имя">{detail.name}</Descriptions.Item>
            <Descriptions.Item label="Телефон">
              <PhoneValue detail={detail.phone} asLink={true} isBack={true}/>
            </Descriptions.Item>
            <Descriptions.Item label="Районы">
              {districts.length > 0 && <DistrictValue activeFilter={{ id: districts.join(',') }}/>}
            </Descriptions.Item>
            <Descriptions.Item label="Услуги">
              {services.length > 0 && <ServiceValue activeFilter={{ id: services.join(',') }}/>}
            </Descriptions.Item>
            <Descriptions.Item label="Дата создания"><DateTimeText date={detail.date_created}/></Descriptions.Item>
          </Descriptions>

          <br/>
          <br/>

          <Fieldset title="Параметры">
            <Descriptions column={2} gutter={[16, 16]}>
              {PARAMETERS.map(p => (
                <Descriptions.Item key={`${p.name}`}
                                   label={p.label}>{getParametetsValue(parameters, p.name)}</Descriptions.Item>
              ))}
            </Descriptions>
          </Fieldset>

          <br/>
          <br/>

          <Row gutter={32}>
            <Col span={12}>
              <Fieldset title="Апартаменты:">
                <Descriptions column={1} gutter={[16, 16]}>
                  <Descriptions.Item label="1 час">
                    {prices.in_hour && `${prices.in_hour}руб`}
                  </Descriptions.Item>
                  <Descriptions.Item label="2 часа">
                    {prices.in_hour2 && `${prices.in_hour2}руб`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Ночь">
                    {prices.in_night && `${prices.in_night}руб`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Экспресс">
                    {prices.in_express && `${prices.in_express}руб`}
                  </Descriptions.Item>
                </Descriptions>
              </Fieldset>
            </Col>

            <Col span={12}>
              <Fieldset title="Выезд:">
                <Descriptions column={1} gutter={[16, 16]}>
                  <Descriptions.Item label="1 час">
                    {prices.out_hour && `${prices.out_hour}руб`}
                  </Descriptions.Item>
                  <Descriptions.Item label="2 часа">
                    {prices.out_hour2 && `${prices.out_hour2}руб`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Ночь">
                    {prices.out_night && `${prices.out_night}руб`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Экспресс">
                    {prices.out_express && `${prices.out_express}руб`}
                  </Descriptions.Item>
                </Descriptions>
              </Fieldset>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          {photos && photos.length > 0 &&
          <div>
            <div className={styles.photoDetail}>
              <img src={getUrl(photos[0].url)} alt=""/>
            </div>

            {photos.length > 1 &&
            <div className={styles.photos}>
              {photos.slice(1).map(p => (
                <div key={`photo${p.id}`} className={styles.photo}>
                  <img src={getUrl(p.url)} alt=""/>
                </div>
              ))}
            </div>
            }
          </div>
          }
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  };
}

export default connect(mapStateToProps)(ProfileDetail);
