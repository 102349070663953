import React, { Component } from 'react';
import { Route } from 'react-router';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { PageHeader } from "components/layout";
import { Loader } from "components/common";
import { DriverForm } from "components/blocks/Driver";
import DetailScreen from './DetailScreen';
import HistoryScreen from './HistoryScreen';
import OrderScreen from './OrderScreen';


class DriverDetailScreen extends Component {
  componentDidMount() {
    const { driverId } = this.props.match.params;
    if (driverId) {
      this.props.actions.driver.detailLoad({ id: driverId });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevId = prevProps.match.params.driverId;
    const nextId = this.props.match.params.driverId;
    if (nextId && prevId !== nextId) {
      this.props.actions.driver.detailLoad({ id: nextId });
    }
  }

  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.driver.showEditForm({ show: true, object: item });
  };

  render() {
    const { history, driver } = this.props;
    const { driverId } = this.props.match.params;
    const detail = driver.detail || {};

    return (
      <div className="page-inner">
        {driverId &&
        <PageHeader
          mode="detail"
          history={history}
          title={detail.name}
          breadcrumbs={[
            { title: '', icon: 'home', path: '/', isBack: false },
            { title: 'Водители', icon: '', path: '/driver', isBack: true },
          ]}
          actions={[
            <Button type="primary" onClick={() => this.showEditForm(detail)}>
              <Icon type="edit"/>
              <span>Редактировать</span>
            </Button>
          ]}
          basePath={`/driver/${driverId}`}
          tabs={[
            { title: 'Основное', key: 'detail' },
            { title: 'Заказы', key: 'order' },
            { title: 'История изменений', key: 'history' },
          ]}
        />}

        <Route exact path="/driver/:driverId" component={DetailScreen}/>
        <Route exact path="/driver/:driverId/history" component={HistoryScreen}/>
        <Route exact path="/driver/:driverId/order" component={OrderScreen}/>

        <DriverForm/>
        <Loader active={driver.loadDetailStatus.isLoading}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    driver: state.driver,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      driver: bindActionCreators(Object.assign({}, actions.driver), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverDetailScreen);
