import { handleActions } from 'redux-actions';

import { loadStates, loadStatus } from "utils";
import * as reduxActions from './reduxActions';


const initialState = {
  period: 'today',
  dateFormat: "HH:mm",
  dateStart: null,
  dateEnd: null,

  order: {},
  orderStatus: loadStatus(loadStates.notAsked),

  client: {},
  clientStatus: loadStatus(loadStates.notAsked),
  clientList: [],
  clientListStatus: loadStatus(loadStates.notAsked),

  agencyList: [],
  agencyListStatus: loadStatus(loadStates.notAsked),
  managerList: [],
  managerListStatus: loadStatus(loadStates.notAsked),
  addressList: [],
  addressListStatus: loadStatus(loadStates.notAsked),
  driverList: [],
  driverListStatus: loadStatus(loadStates.notAsked),
  workerList: [],
  workerListStatus: loadStatus(loadStates.notAsked),
  phoneList: [],
  phoneListStatus: loadStatus(loadStates.notAsked),
};

const request = (state, action, name, defaultValue) => ({
  ...state,
  [`${name}Status`]: loadStatus(loadStates.loading),
  [name]: defaultValue,
});

const complete = (state, action, name) => ({
  ...state,
  [`${name}Status`]: loadStatus(loadStates.loaded),
  [name]: action.payload,
});

const error = (state, action, name) => ({
  ...state,
  [`${name}Status`]: loadStatus(loadStates.failed, action.payload),
});

export default handleActions(
  {
    [reduxActions.ANALYTICS_PERIOD_SELECT]: (state, action) => {
      const {dateStart, dateEnd} = action.payload;
      let byHour = ['today', 'yesterday'].includes(action.payload.period);
      if (!byHour && dateStart && dateEnd && dateEnd.diff(dateStart, 'days') <= 1) {
        byHour = true;
      }
      return {
        ...state,
        period: action.payload.period,
        dateFormat: byHour ? 'HH:mm': 'DD.MM.YYYY',
        dateStart: dateStart,
        dateEnd: dateEnd,
      };
    },

    [reduxActions.ANALYTICS_ORDER_LOAD.REQUEST]: (state, action) => request(state, action, 'order', {}),
    [reduxActions.ANALYTICS_ORDER_LOAD.COMPLETE]: (state, action) => complete(state, action, 'order'),
    [reduxActions.ANALYTICS_ORDER_LOAD.ERROR]: (state, action) => error(state, action, 'order'),

    [reduxActions.ANALYTICS_CLIENT_LOAD.REQUEST]: (state, action) => request(state, action, 'client', {}),
    [reduxActions.ANALYTICS_CLIENT_LOAD.COMPLETE]: (state, action) => complete(state, action, 'client'),
    [reduxActions.ANALYTICS_CLIENT_LOAD.ERROR]: (state, action) => complete(state, action, 'client'),

    [reduxActions.ANALYTICS_AGENCY_LIST_LOAD.REQUEST]: (state, action) => request(state, action, 'agencyList', []),
    [reduxActions.ANALYTICS_AGENCY_LIST_LOAD.COMPLETE]: (state, action) => complete(state, action, 'agencyList'),
    [reduxActions.ANALYTICS_AGENCY_LIST_LOAD.ERROR]: (state, action) => complete(state, action, 'agencyList'),

    [reduxActions.ANALYTICS_CLIENT_LIST_LOAD.REQUEST]: (state, action) => request(state, action, 'clientList', []),
    [reduxActions.ANALYTICS_CLIENT_LIST_LOAD.COMPLETE]: (state, action) => complete(state, action, 'clientList'),
    [reduxActions.ANALYTICS_CLIENT_LIST_LOAD.ERROR]: (state, action) => complete(state, action, 'clientList'),

    [reduxActions.ANALYTICS_MANAGER_LIST_LOAD.REQUEST]: (state, action) => request(state, action, 'managerList', []),
    [reduxActions.ANALYTICS_MANAGER_LIST_LOAD.COMPLETE]: (state, action) => complete(state, action, 'managerList'),
    [reduxActions.ANALYTICS_MANAGER_LIST_LOAD.ERROR]: (state, action) => complete(state, action, 'managerList'),

    [reduxActions.ANALYTICS_ADDRESS_LIST_LOAD.REQUEST]: (state, action) => request(state, action, 'addressList', []),
    [reduxActions.ANALYTICS_ADDRESS_LIST_LOAD.COMPLETE]: (state, action) => complete(state, action, 'addressList'),
    [reduxActions.ANALYTICS_ADDRESS_LIST_LOAD.ERROR]: (state, action) => complete(state, action, 'addressList'),

    [reduxActions.ANALYTICS_DRIVER_LIST_LOAD.REQUEST]: (state, action) => request(state, action, 'driverList', []),
    [reduxActions.ANALYTICS_DRIVER_LIST_LOAD.COMPLETE]: (state, action) => complete(state, action, 'driverList'),
    [reduxActions.ANALYTICS_DRIVER_LIST_LOAD.ERROR]: (state, action) => complete(state, action, 'driverList'),

    [reduxActions.ANALYTICS_WORKER_LIST_LOAD.REQUEST]: (state, action) => request(state, action, 'workerList', []),
    [reduxActions.ANALYTICS_WORKER_LIST_LOAD.COMPLETE]: (state, action) => complete(state, action, 'workerList'),
    [reduxActions.ANALYTICS_WORKER_LIST_LOAD.ERROR]: (state, action) => complete(state, action, 'workerList'),

    [reduxActions.ANALYTICS_PHONE_LIST_LOAD.REQUEST]: (state, action) => request(state, action, 'phoneList', []),
    [reduxActions.ANALYTICS_PHONE_LIST_LOAD.COMPLETE]: (state, action) => complete(state, action, 'phoneList'),
    [reduxActions.ANALYTICS_PHONE_LIST_LOAD.ERROR]: (state, action) => complete(state, action, 'phoneList'),
  },
  initialState
);
