import { handleActions } from 'redux-actions';
import { loadStates, loadStatus } from 'utils/redux';

import * as reduxActions from './reduxActions';


const initialState = {
  loadStatus: loadStatus(loadStates.notAsked),
};

export default handleActions(
  {
    [reduxActions.APP_DATA_LOAD.REQUEST]: (state, action) => ({
      ...state,
      loadStatus: loadStatus(loadStates.loading),
    }),
    [reduxActions.APP_DATA_LOAD.COMPLETE]: (state, action) => ({
      ...state,
      loadStatus: loadStatus(loadStates.loaded),
    }),
    [reduxActions.APP_DATA_LOAD.ERROR]: (state, action) => ({
      ...state,
      loadStatus: loadStatus(loadStates.failed, action.payload),
    }),
  },
  initialState
);
