import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { tableColumn, arrayIsEqual } from "utils";
import { TableList, DateTimeText } from "components/common";
import { UserValue } from "components/blocks/User";


class HistoryList extends Component {
  state = {
    columnList: ['date_created', 'user', 'changed_fields'],
    columnByName: {
      date_created: tableColumn('Дата', 'name', {
        render: (val, item) => <DateTimeText date={item.revision.date_created}/>,
        width: 150,
      }),
      user: tableColumn('Пользователь', 'user', {
        render: (val, item) => <UserValue detail={item.revision.user}/>,
        width: 200,
      }),
      changed_fields: tableColumn('Изменения', 'changed_fields', {
        render: (val, item) => val.map((item, idx) => (
          <div key={`${item[0]}${idx}`}>
            <span>{item[0]}: </span><span>{item[1].toString()}</span>
          </div>
        )),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = () => {
    const { objectId, historyLoad } = this.props;
    this.props.actions.history.listLoad({ apiLoad: historyLoad, id: objectId });
  };

  render() {
    const { version, history } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={version}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    version: state.history,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      history: bindActionCreators(Object.assign({}, actions.history), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryList);
