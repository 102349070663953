import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const PROFILE_LIST_LOAD = createRequestTypes('PROFILE_LIST_LOAD');
export const PROFILE_DETAIL_LOAD = createRequestTypes('PROFILE_DETAIL_LOAD');
export const PROFILE_UPDATE = createRequestTypes('PROFILE_UPDATE');
export const PROFILE_DELETE = createRequestTypes('PROFILE_DELETE');
export const PROFILE_SHOW_EDIT_FORM = 'PROFILE_SHOW_EDIT_FORM';
export const PROFILE_SELECT = 'PROFILE_SELECT';


export const actions = {
  showEditForm: createAction(PROFILE_SHOW_EDIT_FORM),
  select: createAction(PROFILE_SELECT),

  listLoad: createRequestActions(PROFILE_LIST_LOAD),
  detailLoad: createRequestActions(PROFILE_DETAIL_LOAD),
  update: createRequestActions(PROFILE_UPDATE),
  delete: createRequestActions(PROFILE_DELETE),
};
