import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const ORDER_LIST_LOAD = createRequestTypes('ORDER_LIST_LOAD');
export const ORDER_DETAIL_LOAD = createRequestTypes('ORDER_DETAIL_LOAD');
export const ORDER_UPDATE = createRequestTypes('ORDER_UPDATE');
export const ORDER_DELETE = createRequestTypes('ORDER_DELETE');
export const ORDER_SHOW_EDIT_FORM = 'ORDER_SHOW_EDIT_FORM';
export const ORDER_SELECT = 'ORDER_SELECT';


export const actions = {
  showEditForm: createAction(ORDER_SHOW_EDIT_FORM),
  select: createAction(ORDER_SELECT),

  listLoad: createRequestActions(ORDER_LIST_LOAD),
  detailLoad: createRequestActions(ORDER_DETAIL_LOAD),
  update: createRequestActions(ORDER_UPDATE),
  delete: createRequestActions(ORDER_DELETE),
};
