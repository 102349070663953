import React, { Component } from 'react';

import { OrderDetail } from "components/blocks/Order";


export default class DetailScreen extends Component {
  render() {
    const { orderId } = this.props.match.params;

    return (
      <div className="page-main">
        <OrderDetail orderId={orderId}/>
      </div>
    );
  }
}
