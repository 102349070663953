import React, { Component } from 'react';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import UserAuditListScreen from './UserAuditListScreen';


export default class UserAuditScreen extends Component {
  render() {
    return (
      <Page>
        <Route exact path="/audit" component={UserAuditListScreen}/>
      </Page>
    );
  }
}
