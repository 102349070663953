import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Icon } from "antd";

import { actions } from "store";
import { PageHeader } from "components/layout";
import { ProfileList, ProfileForm } from "components/blocks/Profile";


class ProfileListScreen extends Component {
  showEditForm = () => {
    this.props.actions.profile.showEditForm({ show: true })
  };

  render() {
    const { history, city } = this.props;

    return (
      <div className="page-inner">
        <PageHeader
          history={history}
          title="Анкеты на сайтах"
          breadcrumbs={null}
        />

        <div className="page-main">
          <ProfileList
            activeFilter={{ city: city.activeId }}
            topActions={[
              <Button type="primary" onClick={() => this.showEditForm()}>
                <Icon type="plus"/>
                <span>Добавить</span>
              </Button>
            ]}
          />
        </div>

        <ProfileForm/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    city: state.city,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      profile: bindActionCreators(Object.assign({}, actions.profile), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileListScreen);
