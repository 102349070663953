import { put, call, takeEvery, select } from 'redux-saga/effects';
import { push, replace } from "connected-react-router";

import * as reduxActions from './reduxActions';
import * as api from 'store/api/user';
import * as base from "../BaseSagas";


export function* watch() {
  yield takeEvery(reduxActions.CURRENT_USER_LOAD.START, actionCurrentUserLoad);
  yield takeEvery(reduxActions.CURRENT_USER_UPDATE.START, actionCurrentUserUpdate);

  yield takeEvery(reduxActions.USER_LIST_LOAD.START, actionListLoad);
  yield takeEvery(reduxActions.USER_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(reduxActions.USER_UPDATE.START, actionUpdate);
  yield takeEvery(reduxActions.USER_DELETE.START, actionDelete);
  yield takeEvery(reduxActions.USER_SELECT, actionSelect);
}

function* actionCurrentUserLoad(action) {
  yield call(currentUserLoad, action.payload)
}

export function* currentUserLoad({ token }) {
  yield put(reduxActions.actions.currentUserLoad.request());

  try {
    const { error, data, statusCode } = yield call(() => api.currentUserLoad({ token }));

    if (error) {
      yield put(reduxActions.actions.currentUserLoad.error({ data, statusCode }));
    } else {
      yield put(reduxActions.actions.currentUserLoad.complete(data));
    }

  } catch (error) {
    console.log(error);
    yield put(reduxActions.actions.currentUserLoad.error());
  }
}

function* actionCurrentUserUpdate(action) {
  yield base.Request(action, api.currentUserUpdate, reduxActions.actions.currentUserUpdate);
}

function* actionSelect(action) {
  yield put(push(`/`));
}

export function* actionListLoad(action) {
  yield base.Request(action, api.userListLoad, reduxActions.actions.listLoad);
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.userDetailLoad, reduxActions.actions.detailLoad);
}

function* actionUpdate(action) {
  yield base.Request(action, api.userUpdate, reduxActions.actions.update,
    function* (data) {
      yield put(reduxActions.actions.showEditForm({ show: false }));
      yield put(reduxActions.actions.showPasswordForm({ show: false }));
      if (!action.payload.preventLoadDetail) {
        yield put(reduxActions.actions.detailLoad({ id: data.id }));
        yield put(push(`/user/${data.id}`));
      } else {
        const filter = yield select((state) => state.user.filter);
        yield put(reduxActions.actions.listLoad(filter));

        const currentUser = yield select((state) => state.user.fields);
        if (data.id === currentUser.id) {
          const token = yield select((state) => state.auth.token);
          yield put(reduxActions.actions.currentUserLoad({ token }));
        }
      }
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.userDelete, reduxActions.actions.delete,
    function* (data) {
      const filter = yield select((state) => state.user.filter);
      yield put(replace('/user'));
      yield put(reduxActions.actions.listLoad(filter));
    }
  );
}
