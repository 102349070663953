import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { arrayIsEqual, tableColumn } from "utils";
import { TableList, DateTimeText } from 'components/common';


class UserAuditList extends Component {
  state = {
    updateItemId: null,
    columnList: ['action', 'username', 'ip', 'user_agent', 'date_created'],
    columnByName: {
      action: tableColumn('Действие', 'action', {
        sorter: true,
      }),
      username: tableColumn('Логин', 'username', {
        sorter: true,
        width: 150,
      }),
      ip: tableColumn('IP', 'ip', {
        sorter: true,
        width: 150,
      }),
      user_agent: tableColumn('User Agent', 'user_agent', {
        sorter: true,
      }),
      date_created: tableColumn('Дата', 'date_created', {
        render: (val, item) => <DateTimeText date={val}/>,
        sorter: true,
        width: 150,
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    const { userAudit } = this.props;
    if (activeFilter === undefined) {
      activeFilter = userAudit.filter.activeFilter
    }
    this.props.actions.userAudit.listLoad({ query, activeFilter, locationChange: true });
  };

  render() {
    const { userAudit, defaultFilter, activeFilter, history, topActions } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={userAudit}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        showSearch={true}
        topActions={topActions}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    userAudit: state.userAudit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      userAudit: bindActionCreators(Object.assign({}, actions.userAudit), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAuditList);
