import React, { Component } from 'react';
import { connect } from "react-redux";

import { PageHeader } from "components/layout";
import { ShiftList } from "components/blocks/Shift";


class ShiftListScreen extends Component {
  render() {
    const { city } = this.props;

    return (
      <div className="page-inner">
        <PageHeader
          title="Смены"
          breadcrumbs={null}
        />

        <div className="page-main">
          <ShiftList activeFilter={{ city: city.activeId }}/>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    city: state.city,
  };
}

export default connect(mapStateToProps)(ShiftListScreen);
