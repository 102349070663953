import { put, takeEvery, select } from 'redux-saga/effects';
import { push, replace } from "connected-react-router";

import * as base from 'store/BaseSagas';
import * as reduxActions from './reduxActions';
import * as api from 'store/api/orderApartment';
import * as orderReduxActions from '../order/reduxActions';


export function* watch() {
  yield takeEvery(reduxActions.ORDER_APARTMENT_LIST_LOAD.START, actionListLoad);
  yield takeEvery(reduxActions.ORDER_APARTMENT_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(reduxActions.ORDER_APARTMENT_UPDATE.START, actionUpdate);
  yield takeEvery(reduxActions.ORDER_APARTMENT_DELETE.START, actionDelete);
  yield takeEvery(reduxActions.ORDER_APARTMENT_SELECT, actionSelect);
}

function* actionSelect(action) {
  yield put(push(`/`));
}

export function* actionListLoad(action) {
  yield base.Request(action, api.orderApartmentListLoad, reduxActions.actions.listLoad);
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.orderApartmentDetailLoad, reduxActions.actions.detailLoad);
}

function* actionUpdate(action) {
  yield base.Request(action, api.orderApartmentUpdate, reduxActions.actions.update,
    function* (data) {
      yield put(reduxActions.actions.showEditForm({ show: false }));

      yield put(orderReduxActions.actions.detailLoad({ id: data.order }));
      yield put(push(`/order/${data.order}`));
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.orderApartmentDelete, reduxActions.actions.delete,
    function* (data) {
      const filter = yield select((state) => state.orderApartment.filter);
      yield put(replace('/order'));
      yield put(reduxActions.actions.listLoad(filter));
    }
  );
}
