import { connect } from 'react-redux';

import { profileDetailLoad } from "store/api/profile";
import { ValueComponent } from 'components/common';


class ProfileValue extends ValueComponent {
  apiDetailLoad = profileDetailLoad;

  getValue() {
    const { id } = this.props;
    const { detail } = this.state;
    return (detail && detail.name) || id;
  }

  getPath() {
    const { detail } = this.state;
    const detailId = detail && detail.id;
    return `/profile/${detailId}`;
  }
}

function mapStateToProps(state) {
  return {
    router: state.router,
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(ProfileValue);
