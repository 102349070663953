import React, { Component } from 'react';
import { Col, Row, Input } from "antd";

import { FormItem } from 'utils';
import styles from './fieldset.module.css';


export default class TariffFieldSet extends Component {
  state = {
    layout: 'horizontal',
  };

  render() {
    const { form, instance, errorData } = this.props;
    const { layout } = this.state;
    const itemProps = { form, instance, errorData, layout };

    return (
      <Row gutter={32}>
        <Col span={12}>
          <div className="form-block">
            <div className={styles.fieldsetTitle}>Апартаменты</div>

            <FormItem name="prices.in_hour" label="1 час" required={false} {...itemProps}>
              <Input size="large" addonAfter="руб"/>
            </FormItem>

            <FormItem name="prices.in_hour2" label="2 часа" required={false} {...itemProps}>
              <Input size="large" addonAfter="руб"/>
            </FormItem>

            <FormItem name="prices.in_night" label="Ночь" required={false} {...itemProps}>
              <Input size="large" addonAfter="руб"/>
            </FormItem>

            <FormItem name="prices.in_express" label="Экспресс" required={false} {...itemProps}>
              <Input size="large" addonAfter="руб"/>
            </FormItem>
          </div>
        </Col>

        <Col span={12}>
          <div className="form-block">
            <div className={styles.fieldsetTitle}>Выезд</div>

            <FormItem name="prices.out_hour" label="1 час" required={false} {...itemProps}>
              <Input size="large" addonAfter="руб"/>
            </FormItem>

            <FormItem name="prices.out_hour2" label="2 часа" required={false} {...itemProps}>
              <Input size="large" addonAfter="руб"/>
            </FormItem>

            <FormItem name="prices.out_night" label="Ночь" required={false} {...itemProps}>
              <Input size="large" addonAfter="руб"/>
            </FormItem>

            <FormItem name="prices.out_express" label="Экспресс" required={false} {...itemProps}>
              <Input size="large" addonAfter="руб"/>
            </FormItem>
          </div>
        </Col>
      </Row>
    );
  }
}
