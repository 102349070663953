import { handleActions } from 'redux-actions';
import { loadStates, loadStatus } from 'utils/redux';

import * as reduxActions from './reduxActions';


const initialState = {
  token: null,
  isAuthenticated: false,
  loginStatus: loadStatus(loadStates.notAsked),
};

export default handleActions(
  {
    [reduxActions.AUTH_LOGIN.START]: (state, action) => ({
      ...state,
      loginStatus: loadStatus(loadStates.loading),
    }),
    [reduxActions.AUTH_LOGIN.COMPLETE]: (state, action) => ({
      ...state,
      loginStatus: loadStatus(loadStates.loaded),
      token: action.payload.token,
      isAuthenticated: true,
    }),
    [reduxActions.AUTH_LOGIN.ERROR]: (state, action) => ({
      ...state,
      loginStatus: loadStatus(loadStates.failed, action.payload),
    }),

    [reduxActions.AUTH_LOGOUT.START]: (state, action) => ({
      ...state,
      loginStatus: loadStatus(loadStates.loading),
    }),
    [reduxActions.AUTH_LOGOUT.COMPLETE]: (state, action) => ({
      ...state,
      ...initialState
    }),
    [reduxActions.AUTH_LOGOUT.ERROR]: (state, action) => ({
      ...state,
      loginStatus: loadStatus(loadStates.failed, action.payload),
    }),
  },
  initialState
);
