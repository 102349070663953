import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { tableColumn, arrayIsEqual } from "utils";
import { TableList, ActionButton, DateTimeText } from "components/common";
import { CityValue } from "components/blocks/City";
import { AgencyValue } from "components/blocks/Agency";
import DriverValue from "../DriverValue";


class DriverList extends Component {
  state = {
    columnList: ['name', 'city', 'agency', 'date_created', 'actions'],
    columnByName: {
      name: tableColumn('Название', 'name', {
        render: (val, item) => <DriverValue detail={item} asLink={true} isBack={true}/>,
        sorter: true,
      }),
      city: tableColumn('Город', 'city', {
        render: (val, item) => <CityValue detail={val} asLink={true} isBack={true}/>,
        sorter: true,
      }),
      agency: tableColumn('Агентство', 'agency', {
        render: (val, item) => <AgencyValue detail={val} asLink={true} isBack={true}/>,
        sorter: true,
      }),
      date_created: tableColumn('Дата создания', 'date_created', {
        render: (val, item) => <DateTimeText date={val}/>,
        width: 130,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        width: 50,
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.driver.listLoad({ query, activeFilter });
  };

  showEditForm = (item) => {
    this.props.actions.driver.showEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.driver.delete({ id: item.id })
  };

  render() {
    const { driver, defaultFilter, activeFilter, history, topActions } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={driver}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        showSearch={true}
        topActions={topActions}
      />
    );
  }
}


function mapStateToProps(state) {
  return {
    driver: state.driver,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      driver: bindActionCreators(Object.assign({}, actions.driver), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverList);
