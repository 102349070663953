import React from 'react';
import { Form } from 'antd';
import { isDict } from './helpers';


function getValidateStatus(fieldName, errorData) {
  if (!isDict(errorData)) return {};
  if (fieldName in errorData) {
    return {
      validateStatus: "error",
      help: errorData[fieldName]
    }
  }
  return {};
}


export const FormItem = props => {
  const { form, name, label, required, errorData, instance, initialValue, valuePropName, getValueFromEvent, getValueProps, className, layout, rules, normalize, convertValue } = props;
  const { getFieldDecorator } = form;

  let value;
  if (instance) {
    value = instance;
    name.split('.').forEach(n => {
      value = value && value[n];
    });

    if (convertValue) {
      value = convertValue(value);
    }
  }

  let _rules = [
    { required: required, message: 'Обязательное поле' },
  ];
  if (rules) {
    _rules = rules;
  }

  let params = {
    rules: _rules,
    initialValue: value || initialValue
  };
  if (value === '') {
    params.initialValue = value;
  }
  if (valuePropName) {
    params.valuePropName = valuePropName;
  }
  if (getValueFromEvent) {
    params.getValueFromEvent = getValueFromEvent;
  }
  if (getValueProps) {
    params.getValueProps = getValueProps;
  }

  if (normalize) {
    params.normalize = normalize;
  }

  let formItemLayout = null;

  switch (layout) {
    case 'horizontal':
      formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      };
      break;
    case 'horizontal2':
      formItemLayout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
      };
      break;
    default:
      formItemLayout = null;
  }

  return (
    <Form.Item
      className={className}
      {...getValidateStatus(name, errorData || {})}
      label={label && <span>{label}</span>}
      {...formItemLayout}
    >
      {getFieldDecorator(name, params)(
        props.children
      )}
    </Form.Item>
  )
};
