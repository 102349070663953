import React from 'react';
import { Spin, Transfer } from 'antd';
import { connect } from 'react-redux';

import { workerListLoad } from "store/api/worker";
import { FormItem } from "utils";
import { DataLoadComponent } from 'components/common';


class WorkerTransferField extends DataLoadComponent {
  apiListLoad = workerListLoad;

  render() {
    const { form, instance, name, label, required, errorData, initialValue } = this.props;
    const { items, loadStatus } = this.state;

    const dataSource = items.map(item => {
      item.key = item.id;
      return item;
    });

    return (
      <Spin spinning={loadStatus.isLoading}>
        <FormItem
          form={form}
          instance={instance}
          name={name}
          label={label}
          required={required}
          errorData={errorData}
          initialValue={initialValue}
          convertValue={values => values && values.map(obj => (obj && obj.id) || obj)}
          valuePropName='targetKeys'
        >
          <Transfer
            dataSource={dataSource}
            showSearch
            listStyle={{
              width: 250,
              height: 300,
            }}
            render={item => `${item.name}`}
            filterOption={(input, option) =>
              option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </FormItem>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(WorkerTransferField);
