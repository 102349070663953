import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const CLIENT_ADDRESS_LIST_LOAD = createRequestTypes('CLIENT_ADDRESS_LIST_LOAD');
export const CLIENT_ADDRESS_DETAIL_LOAD = createRequestTypes('CLIENT_ADDRESS_DETAIL_LOAD');
export const CLIENT_ADDRESS_UPDATE = createRequestTypes('CLIENT_ADDRESS_UPDATE');
export const CLIENT_ADDRESS_DELETE = createRequestTypes('CLIENT_ADDRESS_DELETE');
export const CLIENT_ADDRESS_SHOW_EDIT_FORM = 'CLIENT_ADDRESS_SHOW_EDIT_FORM';
export const CLIENT_ADDRESS_SELECT = 'CLIENT_ADDRESS_SELECT';


export const actions = {
  showEditForm: createAction(CLIENT_ADDRESS_SHOW_EDIT_FORM),
  select: createAction(CLIENT_ADDRESS_SELECT),

  listLoad: createRequestActions(CLIENT_ADDRESS_LIST_LOAD),
  detailLoad: createRequestActions(CLIENT_ADDRESS_DETAIL_LOAD),
  update: createRequestActions(CLIENT_ADDRESS_UPDATE),
  delete: createRequestActions(CLIENT_ADDRESS_DELETE),
};
