import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Breadcrumb } from "antd";
import classNames from 'classnames';

import { Photo } from "components/common";
import { PageHeaderTabs } from 'components/layout';
import './styles.css';


export default function PageHeader({ mode, photo, title, info, actions, basePath, tabs, onTabChange,
                                     history, breadcrumbs }) {
  const cx = classNames([
    'page-header',
    mode && `page-header--${mode}`,
  ]);
  const state = history && history.location.state;
  const backUrl = state && state.backUrl;
  const backSearch = state && state.backSearch;

  return (
    <header className={cx}>
      {backUrl ?
        <div className="page-header-top">
          <Link className="back-btn"
                to={{ pathname: backUrl, search: backSearch, state: { slideOut: true } }} replace={true}>
            <Icon className="icon" type="arrow-left"/>
            <span className="text">назад</span>
          </Link>
        </div> :
        breadcrumbs &&
        <div className="page-header-top">
          <Breadcrumb>
            {breadcrumbs.map((item, idx) => (
              <Breadcrumb.Item key={`item${idx}`}>
                <Link to={{ pathname: item.path, state: { slideOut: item.isBack } }}>
                  {item.icon && <Icon type={item.icon}/>}
                  {item.title && <span>{item.title}</span>}
                </Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>
      }

      <div className="page-header-main">
        {photo !== undefined && <div className="page-header-photo"><Photo url={photo}/></div>}

        <div className="page-header-content">
          <div className="page-header-title">{title}</div>
          {info && <div className="page-header-info">{info}</div>}
        </div>

        <div className="page-header-actions">
          {actions && actions.map((item, index) =>
            <div className="action" key={`action${index}`}>{item}</div>
          )}
        </div>
      </div>

      {tabs &&
      <PageHeaderTabs
        basePath={basePath}
        tabs={tabs}
        onTabChange={onTabChange}
        history={history}
      />}
    </header>
  );
}
