import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from "react-router";

import { actions } from "store";
import { PageHeader } from 'components/layout';
import { ActionButton } from "components/common";
import { ClientForm } from 'components/blocks/Client';
import DetailScreen from './DetailScreen';
import HistoryScreen from './HistoryScreen';
import OrderScreen from './OrderScreen';


class ClientDetailScreen extends Component {
  componentDidMount() {
    const { clientId } = this.props.match.params;
    if (clientId) {
      this.props.actions.client.detailLoad({ id: clientId });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevId = prevProps.match.params.clientId;
    const nextId = this.props.match.params.clientId;
    if (nextId && prevId !== nextId) {
      this.props.actions.client.detailLoad({ id: nextId });
    }
  }

  showEditForm = (item = null) => {
    this.props.actions.client.showEditForm({ show: true, object: item });
  };

  delete = () => {
    const { clientId } = this.props.match.params;
    this.props.actions.client.delete({ id: clientId });
  };

  render() {
    const { client, history } = this.props;
    const { clientId } = this.props.match.params;
    const detail = client.detail || {};

    return (
      <div className="page-inner">
        <PageHeader
          mode="detail"
          history={history}
          title={detail.phone}
          breadcrumbs={[
            { title: '', icon: 'home', path: '/', isBack: false },
            { title: 'Клиенты', icon: '', path: '/client', isBack: true },
          ]}
          actions={[
            <Button type="primary" size="large" onClick={() => this.showEditForm(detail)}>
              <Icon type="edit"/>
              <span>Редактировать</span>
            </Button>,
            <ActionButton
              actions={[
                {
                  key: 'delete',
                  icon: 'delete',
                  title: 'Удалить',
                  action: this.delete,
                  confirm: {
                    title: 'Уверены, что хотите удалить?',
                    okText: 'Удалить',
                    cancelText: 'Отмена'
                  }
                },
              ]}

            />
          ]}
          basePath={`/client/${clientId}`}
          tabs={[
            { title: 'Основное', key: 'detail' },
            { title: 'Заказы', key: 'order' },
            { title: 'История изменений', key: 'history' },
          ]}
        />

        <Route exact path="/client/:clientId" component={DetailScreen}/>
        <Route exact path="/client/:clientId/history" component={HistoryScreen}/>
        <Route exact path="/client/:clientId/order" component={OrderScreen}/>

        <ClientForm/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    client: state.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      client: bindActionCreators(Object.assign({}, actions.client), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetailScreen);
