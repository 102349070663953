import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { DistrictList, DistrictForm } from 'components/blocks/District';


class DistrictListScreen extends Component {
  showEditForm = (item = null) => {
    this.props.actions.district.showEditForm({ show: true, object: item });
  };

  render() {
    const { history, match } = this.props;
    const cityId = parseInt(match.params.cityId);

    return (
      <div className="page-main">
        <DistrictList basePath={`/city/${cityId}/district`} history={history} activeFilter={{ city: cityId }}/>

        <DistrictForm cityId={cityId}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    city: state.city,
    district: state.district,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      district: bindActionCreators(Object.assign({}, actions.district), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DistrictListScreen);
