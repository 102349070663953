import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { tableColumn, arrayIsEqual } from "utils";
import { TableList, ActionButton, DateTimeText, OnOff } from "components/common";
import { ClientValue } from "../index";


class ClientList extends Component {
  state = {
    updateItemId: null,
    columnList: ['phone', 'date_created', 'is_banned', 'actions'],
    columnByName: {
      phone: tableColumn('Телефон', 'phone', {
        render: (val, item) => <ClientValue detail={item} asLink={true} isBack={true}/>,
        sorter: true,
      }),
      date_created: tableColumn('Дата создания', 'date_created', {
        render: (val, item) => <DateTimeText date={val}/>,
        sorter: true,
        width: 200,
      }),
      is_banned: tableColumn('Забанен', 'is_banned', {
        render: (val, item) => <OnOff
          param="is_banned"
          preventLoadDetail={true}
          item={item}
          updateItemId={this.state.updateItemId}
          updateStatus={this.props.client.updateStatus}
          onChange={this.itemUpdate}

        />,
        sorter: true,
        width: 140,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        width: 50,
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }, {
              title: 'Удалить', key: 'delete', icon: 'delete', action: () => this.delete(item),
              confirm: {
                title: 'Уверены, что хотите удалить?',
                okText: 'Удалить',
                cancelText: 'Отмена'
              }
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.client.listLoad({ query, activeFilter });
  };

  showEditForm = (item) => {
    this.props.actions.client.showEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.client.delete({ id: item.id })
  };

  itemUpdate = (data) => {
    this.setState({
      updateItemId: data.id,
    });
    this.props.actions.client.update(data);
  };

  render() {
    const { client, defaultFilter, activeFilter, history, topActions } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={client}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        showSearch={true}
        topActions={topActions}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    client: state.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      client: bindActionCreators(Object.assign({}, actions.client), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientList);
