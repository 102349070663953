import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Icon } from "antd";

import { actions } from "store";
import { PageHeader } from "components/layout";
import { ClientList, ClientForm } from 'components/blocks/Client';


class ClientListScreen extends Component {
  showEditForm = () => {
    this.props.actions.client.showEditForm({ show: true })
  };

  render() {
    const { history, city } = this.props;

    return (
      <div className="page-inner">
        <PageHeader
          history={history}
          title="Клиенты"
          breadcrumbs={null}
          basePath=""
          tabs={[
            { title: 'Клиенты', key: 'client' },
            { title: 'Адреса клиентов', key: 'clientAddress' },
          ]}
        />

        <div className="page-main">
          <ClientList
            activeFilter={{ city: city.activeId }}
            topActions={[
              <Button type="primary" onClick={() => this.showEditForm()}>
                <Icon type="plus"/>
                <span>Добавить</span>
              </Button>
            ]}
          />
        </div>

        <ClientForm/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    city: state.city,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      client: bindActionCreators(Object.assign({}, actions.client), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientListScreen);
