import React, { Component } from 'react';
import { Input, Row, Col } from "antd";

import { FormItem } from "utils";
import { PhotoUpload } from 'components/common';
import { CitySelect } from "components/blocks/City";
import { PhoneSelect } from 'components/blocks/Phone';


export default class MainFieldSet extends Component {
  state = {
    layout: 'horizontal',
  };

  render() {
    const { form, instance, errorData, city } = this.props;
    const { layout } = this.state;
    const itemProps = { form, instance, errorData, layout };

    return (
      <Row gutter={32}>
        <Col span={16}>
          <div className="form-block">
            <CitySelect name="city" label="Город" required={true} disabled={true}
                        initialValue={parseInt(city.activeId)} {...itemProps}/>

            <FormItem name="name" label="Имя" required={true} {...itemProps}>
              <Input size="large"/>
            </FormItem>

            <PhoneSelect name="phone" label="Телефон" required={true} {...itemProps}
                         activeFilter={{city: parseInt(city.activeId)}} showAgency={true}/>
          </div>
        </Col>
        <Col span={8}>
          <FormItem name="photos" required={false} valuePropName="fileList" {...itemProps}>
            <PhotoUpload title="Загрузить фото"/>
          </FormItem>
        </Col>
      </Row>
    );
  }
}
