import React, { Component } from 'react';
import classNames from 'classnames';

import './time_range.css';


class Time extends Component {
  onHover = e => {
    const { onHover, time } = this.props;
    onHover(e, time);
  };

  onClick = e => {
    const { onClick, time } = this.props;
    onClick(e, time);
  };

  onKeyDown = e => {
    const { onClick, time } = this.props;
    if (e.keyCode === 13) {
      onClick(e, time);
    }
  };

  isActive = () => {
    const { time, value } = this.props;
    switch (value.length) {
      case 2:
        return time >= value[0] && time <= value[1];
      case 1:
        return time === value[0];
      default:
        return false;
    }
  };

  render() {
    const { time, disabled } = this.props;

    const cx = classNames([
      'time_range_time',
      this.isActive() && 'active',
      disabled.includes(time) && 'disabled',
    ]);

    return (
      <div className={cx} onClick={this.onClick} onKeyDown={this.onKeyDown} onMouseMove={this.onHover}>{time}</div>
    );
  }
}


export default class TimeRange extends Component {
  constructor(props) {
    super(props);

    this.times = {};

    this.state = {
      value: props.value || [],
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if ('value' in nextProps && nextProps.value !== undefined) {
      return {
        ...state,
        value: nextProps.value,
      };
    }
    return state;
  }

  saveRef = time => node => {
    this.times[time] = node;
  };

  getValue(time, oldValue) {
    switch (oldValue.length) {
      case 2:
        return [time];
      case 1:
        return [oldValue[0], time];
      default:
        return [time];
    }
  }

  changeValue(value) {
    const { onChange } = this.props;
    if (!('value' in this.props)) {
      this.setState({
        value,
      });
    }
    onChange && onChange(value);
  }

  onHover = (event, time) => {
    const { onHoverChange } = this.props;
    const { value } = this.state;

    const hoverValue = this.getValue(time, value);
    this.setState({
      hoverValue,
    });
    onHoverChange && onHoverChange(hoverValue);
  };

  onMouseLeave = () => {
    const { onHoverChange } = this.props;
    this.setState({
      hoverValue: undefined,
    });
    onHoverChange && onHoverChange(undefined);
  };

  onClick = (event, time) => {
    const disabled = this.props.disabled || [];
    if (disabled.includes(time)) return;

    const { value } = this.state;
    const newValue = this.getValue(time, value);
    let isReset = newValue === value;

    this.onMouseLeave(true);
    this.changeValue(isReset ? [] : newValue);
  };

  render() {
    const { range, disabled } = this.props;

    return (
      <div className="time_range">
        {range.map((time, idx) =>
          <Time
            key={`time${idx}`}
            ref={this.saveRef(time)}
            time={time}
            disabled={disabled}
            value={this.state.value}
            onClick={this.onClick}
            onHover={this.onHover}
          />
        )}
      </div>
    );
  }
}
