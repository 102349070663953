import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Table } from 'antd';

import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { getObjectList, getUrlSearch, getUrlParams, dictIsEqual, getColumns, arrayIsEqual } from "utils";
import { TableTop, Filter } from 'components/common';


let dragingIndex = -1;

class BodyRow extends React.Component {
  render() {
    const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
    const style = { ...restProps.style, cursor: 'move' };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style}/>),
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
      key: props['data-row-key'],
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const dragKey = monitor.getItem().key;
    const hoverIndex = props.index;
    const hoverKey = props['data-row-key'];
    const dropPos = hoverIndex > dragingIndex ? 'after' : 'before';

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragKey, hoverKey, dropPos);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    //monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);


class TableList extends Component {
  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  constructor(props, context) {
    super(props, context);
    const { columnList, columnByName } = props;
    this.printRef = React.createRef();
    this.state = {
      columnList: columnList,
      columns: getColumns(columnByName, columnList, ''),
      orderField: '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data, columnList, columnByName } = nextProps;
    const { params } = data.filter;
    if (!arrayIsEqual(columnList, prevState.columnList) || (params.ordering !== prevState.orderField)) {
      return {
        columnList: columnList,
        columns: getColumns(columnByName, columnList, params.ordering),
        orderField: params.ordering,
      }
    }
    return null;
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!dictIsEqual(this.props.activeFilter, prevProps.activeFilter)) {
      this.loadData()
    }
  }

  loadData = () => {
    const { history, data, defaultFilter, activeFilter } = this.props;
    let query = data.filter.query;
    if (query === null) {
      if (!history.location.search) {
        query = `?${getUrlSearch(defaultFilter)}`;
      } else {
        query = history.location.search;
      }
    }
    this.props.onLoadData({ query, activeFilter });
  };

  onChange = (pagination, filters, sorter) => {
    const { history, activeFilter } = this.props;

    let params = getUrlParams(history.location.search);
    const order = o => o === 'descend' ? '-' : '';
    params.ordering = sorter.field ? `${order(sorter.order)}${sorter.field}` : '';
    params.offset = (pagination.current - 1) * pagination.pageSize;

    const query = `?${getUrlSearch(params)}`;
    this.props.onLoadData({ query, activeFilter });
  };

  renderTable = () => {
    const { history, data, filterFields, onLoadData, topActions, showSearch, showPrint, title, dataSource, dragSorting, hideNav, ...props } = this.props;
    const { loadListStatus, pageSize, count, filter } = data;
    const dataList = dataSource ? dataSource : getObjectList(data);
    const offset = parseInt(filter.params.offset) || 0;

    const pageSize2 = pageSize < dataList.length ? dataList.length : pageSize;

    const pagination = hideNav ? false : { pageSize: pageSize2, total: count, current: offset / pageSize + 1 };

    return (
      <Table
        ref={this.printRef}
        title={title}
        columns={this.state.columns}
        dataSource={dataList}
        rowKey="id"
        loading={loadListStatus.isLoading}
        pagination={pagination}
        onChange={this.onChange}

        components={dragSorting ? this.components : undefined}

        {...props}
      />
    )
  };

  render() {
    const { data, filterFields, onLoadData, topActions, rowActions, selected, showSearch, showPrint, dragSorting, hideNav } = this.props;
    const { pageSize, count } = data;

    return (
      <div className="page-block-content">
        <TableTop
          onLoadData={onLoadData}
          data={data}
          filterFields={filterFields}
          topActions={topActions}
          rowActions={rowActions}
          selected={selected}
          showSearch={showSearch}
          showPrint={showPrint}
          printContent={() => this.printRef.current}
        />

        {dragSorting ?
          <DndProvider backend={HTML5Backend}>
            {this.renderTable()}
          </DndProvider>
          : this.renderTable()}

        {!hideNav &&
        <div className="table-stat">Показано {pageSize} из {count}</div>}

        {filterFields &&
        <Filter onLoadData={onLoadData} data={data} fields={filterFields}/>}
      </div>
    );
  }
}

export default withRouter(TableList);
