import React, { Component } from 'react';
import { Route } from 'react-router';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { PageHeader } from "components/layout";
import { Loader } from "components/common";
import { ServiceForm } from "components/blocks/Service";
import DetailScreen from './DetailScreen';
import HistoryScreen from './HistoryScreen';


class ServiceDetailScreen extends Component {
  componentDidMount() {
    const { serviceId } = this.props.match.params;
    if (serviceId) {
      this.props.actions.service.detailLoad({ id: serviceId });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevId = prevProps.match.params.serviceId;
    const nextId = this.props.match.params.serviceId;
    if (nextId && prevId !== nextId) {
      this.props.actions.service.detailLoad({ id: nextId });
    }
  }

  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.service.showEditForm({ show: true, object: item });
  };

  render() {
    const { history, service } = this.props;
    const { serviceId } = this.props.match.params;
    const detail = service.detail || {};

    return (
      <div className="page-inner">
        {serviceId &&
        <PageHeader
          mode="detail"
          history={history}
          title={detail.name}
          breadcrumbs={[
            { title: '', icon: 'home', path: '/', isBack: false },
            { title: 'Услуги', icon: '', path: '/service', isBack: true },
          ]}
          actions={[
            <Button type="primary" onClick={() => this.showEditForm(detail)}>
              <Icon type="edit"/>
              <span>Редактировать</span>
            </Button>
          ]}
          basePath={`/service/${serviceId}`}
          tabs={[
            { title: 'Основное', key: 'detail' },
            { title: 'История изменений', key: 'history' },
          ]}
        />}

        <Route exact path="/service/:serviceId" component={DetailScreen}/>
        <Route exact path="/service/:serviceId/history" component={HistoryScreen}/>

        <ServiceForm/>
        <Loader active={service.loadDetailStatus.isLoading}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    service: state.service,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      service: bindActionCreators(Object.assign({}, actions.service), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetailScreen);
