import React from 'react';
import { Input, Menu, Icon } from 'antd';
import { connect } from 'react-redux';

import { clientAddressListLoad } from "store/api/clientAddress";
import { FormItem, loadStates, loadStatus } from "utils";
import { DataLoadComponent } from 'components/common';
import styles from './field.module.css';


class ClientAddressField extends DataLoadComponent {
  state = {
    items: [],
    clientAddress: null,
    loadListStatus: loadStatus(loadStates.notAsked),
  };

  apiListLoad = clientAddressListLoad;

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  load = (address) => {
    const { auth_token, activeFilter, onClientAddressChoose } = this.props;

    this.setState({
      clientAddress: null,
      loadListStatus: loadStatus(loadStates.loading),
    });
    onClientAddressChoose && onClientAddressChoose(null);

    const newActiveFilter = Object.assign(activeFilter || {}, {search: address, limit: 5})

    this.apiListLoad(auth_token, { activeFilter: newActiveFilter })
      .then(({ error, data }) => {
        if (error) {
          console.log(error);
          this.setState({
            loadListStatus: loadStatus(loadStates.failed, error),
          });
        } else {
          this.setState({
            items: data.results,
            loadListStatus: loadStatus(loadStates.loaded),
          });
        }
      })
  };

  handleAddressChange = (e) => {
    const address = e.target.value;
    const { clientAddress } = this.state;
    if (address && (!clientAddress || clientAddress.address !== address)) {
      this.load(address);
    }
  };

  chooseClientAddress = ({ item }) => {
    const { form, name, onClientAddressChoose } = this.props;
    const { clientAddress } = item.props;
    form.setFieldsValue({
      [name]: clientAddress.address,
    });
    this.setState({
      clientAddress: clientAddress,
      items: [],
      loadListStatus: loadStatus(loadStates.notAsked),
    });
    onClientAddressChoose && onClientAddressChoose(clientAddress);
  };

  render() {
    const { form, instance, name, label, required, disabled, errorData, layout } = this.props;
    //const { items, loadListStatus } = this.state;
    const initialValue = instance && instance.clientAddress ? instance.clientAddress.address : this.props.initialValue;

    return (
      <div className={styles.field}>
        <FormItem
          form={form}
          instance={instance}
          name={name}
          label={label}
          required={required}
          disabled={disabled}
          errorData={errorData}
          initialValue={initialValue}
          layout={layout}
        >
          <Input size="large" onChange={this.handleAddressChange}/>
        </FormItem>
        {this.state.items.length > 0 &&
        <div className={styles.dropdown}>
          <Menu onClick={this.chooseClientAddress}>
            {this.state.items.map(item =>
              <Menu.Item key={item.id} clientAddress={item} className={item.is_banned ? styles.itemBanned: styles.item}>
                <div className={styles.inner}>
                  <div>
                    <div className={styles.address}>{item.address} {item.is_banned && <span>забанен</span>}</div>
                    <div className={styles.stat}>
                      <div className={styles.statItem1}>
                        <Icon type="check-circle" className={styles.statIcon}/>Успешно: {item.stats.order_completed_count}
                      </div>
                      <div className={styles.statItem2}>
                        <Icon type="sync" className={styles.statIcon}/>Продлено: {item.stats.order_prolong}ч.
                      </div>
                      <div className={styles.statItem3}>
                        <Icon type="stop" className={styles.statIcon}/>Отказ: {item.stats.order_rejected_count}
                      </div>
                    </div>
                  </div>
                </div>
              </Menu.Item>
            )}
          </Menu>
        </div>}
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(ClientAddressField);
