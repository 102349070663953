import React, { Component } from 'react';

import { OrderList } from 'components/blocks/Order';


export default class OrderScreen extends Component {
  render() {
    const { workerId } = this.props.match.params;

    return (
      <div className="page-main">
        <OrderList
          activeFilter={{ worker: workerId }}
        />
      </div>
    );
  }
}
