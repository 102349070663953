import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { tableColumn, arrayIsEqual } from "utils";
import { TableList, ActionButton, DateTimeText } from "components/common";
import { CityValue } from "components/blocks/City";
import { AgencyValue } from "components/blocks/Agency";
import PhoneValue from "../PhoneValue";


class PhoneList extends Component {
  state = {
    columnList: ['name', 'phone', 'city', 'agency', 'date_created', 'actions'],
    columnByName: {
      name: tableColumn('Название', 'name', {
        render: (val, item) => <PhoneValue detail={item} asLink={true} isBack={true}/>,
        sorter: true,
      }),
      phone: tableColumn('Телефон', 'phone', {
        sorter: true,
      }),
      city: tableColumn('Город', 'city', {
        render: (val, item) => <CityValue detail={val} asLink={true} isBack={true}/>,
        sorter: true,
      }),
      agency: tableColumn('Агентство', 'agency', {
        render: (val, item) => <AgencyValue detail={val} asLink={true} isBack={true}/>,
        sorter: true,
      }),
      date_created: tableColumn('Дата создания', 'date_created', {
        render: (val, item) => <DateTimeText date={val}/>,
        width: 160,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        width: 50,
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }, {
              title: 'Удалить', key: 'delete', icon: 'delete', action: () => this.delete(item),
              confirm: {
                title: 'Уверены, что хотите удалить?',
                okText: 'Удалить',
                cancelText: 'Отмена'
              }
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.phone.listLoad({ query, activeFilter, locationChange: true });
  };

  showEditForm = (item) => {
    this.props.actions.phone.showEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.phone.delete({ id: item.id })
  };

  render() {
    const { phone, defaultFilter, activeFilter, history, topActions } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={phone}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        showSearch={true}
        topActions={topActions}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    phone: state.phone,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      phone: bindActionCreators(Object.assign({}, actions.phone), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhoneList);
