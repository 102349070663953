import { put, takeEvery, select } from 'redux-saga/effects';
import { push, replace } from "connected-react-router";

import * as base from 'store/BaseSagas';
import * as reduxActions from './reduxActions';
import * as api from 'store/api/orderWorker';
import * as orderReduxActions from "../order/reduxActions";


export function* watch() {
  yield takeEvery(reduxActions.ORDER_WORKER_LIST_LOAD.START, actionListLoad);
  yield takeEvery(reduxActions.ORDER_WORKER_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(reduxActions.ORDER_WORKER_UPDATE.START, actionUpdate);
  yield takeEvery(reduxActions.ORDER_WORKER_DELETE.START, actionDelete);
  yield takeEvery(reduxActions.ORDER_WORKER_SELECT, actionSelect);
}

function* actionSelect(action) {
  yield put(push(`/`));
}

export function* actionListLoad(action) {
  yield base.Request(action, api.orderWorkerListLoad, reduxActions.actions.listLoad);
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.orderWorkerDetailLoad, reduxActions.actions.detailLoad);
}

function* actionUpdate(action) {
  yield base.Request(action, api.orderWorkerUpdate, reduxActions.actions.update,
    function* (data) {
      yield put(reduxActions.actions.showEditForm({ show: false }));

      yield put(orderReduxActions.actions.detailLoad({ id: data.order }));
      yield put(push(`/order/${data.order}`));
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.orderWorkerDelete, reduxActions.actions.delete,
    function* (data) {
      const filter = yield select((state) => state.orderWorker.filter);
      yield put(replace('/order'));
      yield put(reduxActions.actions.listLoad(filter));
    }
  );
}
