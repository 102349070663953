import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from "react-router";

import { actions } from "store";
import { PageHeader } from 'components/layout';
import { ActionButton } from "components/common";
import { ClientAddressForm } from 'components/blocks/ClientAddress';
import DetailScreen from './DetailScreen';
import HistoryScreen from './HistoryScreen';
import OrderScreen from './OrderScreen';


class ClientAddressDetailScreen extends Component {
  componentDidMount() {
    const { clientAddressId } = this.props.match.params;
    if (clientAddressId) {
      this.props.actions.clientAddress.detailLoad({ id: clientAddressId });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevId = prevProps.match.params.clientAddressId;
    const nextId = this.props.match.params.clientAddressId;
    if (nextId && prevId !== nextId) {
      this.props.actions.clientAddress.detailLoad({ id: nextId });
    }
  }

  showEditForm = (item = null) => {
    this.props.actions.clientAddress.showEditForm({ show: true, object: item });
  };

  delete = () => {
    const { clientAddressId } = this.props.match.params;
    this.props.actions.clientAddress.delete({ id: clientAddressId });
  };

  render() {
    const { clientAddress, history } = this.props;
    const { clientAddressId } = this.props.match.params;
    const detail = clientAddress.detail || {};

    return (
      <div className="page-inner">
        <PageHeader
          mode="detail"
          history={history}
          title={detail.address}
          breadcrumbs={[
            { title: '', icon: 'home', path: '/', isBack: false },
            { title: 'Адреса клиентов', icon: '', path: '/clientAddress', isBack: true },
          ]}
          actions={[
            <Button type="primary" size="large" onClick={() => this.showEditForm(detail)}>
              <Icon type="edit"/>
              <span>Редактировать</span>
            </Button>,
            <ActionButton
              actions={[
                {
                  key: 'delete',
                  icon: 'delete',
                  title: 'Удалить',
                  action: this.delete,
                  confirm: {
                    title: 'Уверены, что хотите удалить?',
                    okText: 'Удалить',
                    cancelText: 'Отмена'
                  }
                },
              ]}

            />
          ]}
          basePath={`/clientAddress/${clientAddressId}`}
          tabs={[
            { title: 'Основное', key: 'detail' },
            { title: 'Заказы', key: 'order' },
            { title: 'История изменений', key: 'history' },
          ]}
        />

        <Route exact path="/clientAddress/:clientAddressId" component={DetailScreen}/>
        <Route exact path="/clientAddress/:clientAddressId/history" component={HistoryScreen}/>
        <Route exact path="/clientAddress/:clientAddressId/order" component={OrderScreen}/>

        <ClientAddressForm/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clientAddress: state.clientAddress,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      clientAddress: bindActionCreators(Object.assign({}, actions.clientAddress), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientAddressDetailScreen);
