import React, { Component } from 'react';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { getFullNameOrEmail } from "utils";
import { PageHeader } from "components/layout";
import { Loader, ActiveStatus } from "components/common";
import { ShiftForm } from "components/blocks/Shift";
import DetailScreen from './DetailScreen';
import HistoryScreen from './HistoryScreen';
import OrderScreen from './OrderScreen';


class ShiftDetailScreen extends Component {
  componentDidMount() {
    const { shiftId } = this.props.match.params;
    if (shiftId) {
      this.props.actions.shift.detailLoad({ id: shiftId });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevId = prevProps.match.params.shiftId;
    const nextId = this.props.match.params.shiftId;
    if (nextId && prevId !== nextId) {
      this.props.actions.shift.detailLoad({ id: nextId });
    }
  }

  showEditForm = (item = null) => {
    this.props.actions.shift.showEditForm({ show: true, object: item });
  };

  delete = () => {
    const { shiftId } = this.props.match.params;
    this.props.actions.shift.delete({ id: shiftId });
  };

  render() {
    const { history, shift } = this.props;
    const { shiftId } = this.props.match.params;
    const detail = shift.detail || {};

    return (
      <div className="page-inner">
        {shiftId &&
          <PageHeader
            mode="detail"
            history={history}
            title={`Смена №${detail.id}`}
            info={`Диспетчер: ${getFullNameOrEmail(detail.manager)}`}
            breadcrumbs={[
              { title: '', icon: 'home', path: '/', isBack: false },
              { title: 'Смены', icon: '', path: '/shift', isBack: true },
            ]}
            basePath={`/shift/${shiftId}`}
            tabs={[
              { title: 'Основное', key: 'detail' },
              { title: 'Заказы', key: 'order' },
              { title: 'История изменений', key: 'history' },
            ]}

            actions={[
              <div>
                <ActiveStatus
                  active={detail.id && detail.date_end === null}
                  titles={['открыта', 'закрыта', 'закрыть']}
                  buttonType="primary"
                  onAction={() => {
                    if (detail.active_orders > 0) {
                      alert('У смены остались не завершённые заказы!');
                    } else {
                      this.itemUpdate({ id: detail.id, stop: true });
                    }
                  }}
                />
                <div style={{ fontSize: '12px' }}>
                  Незакрытых заказов: {detail.active_orders}
                </div>
              </div>,
            ]}
          />}

        <Route exact path="/shift/:shiftId" component={DetailScreen} />
        <Route exact path="/shift/:shiftId/history" component={HistoryScreen} />
        <Route exact path="/shift/:shiftId/order" component={OrderScreen} />

        <ShiftForm />
        <Loader active={shift.loadDetailStatus.isLoading} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    shift: state.shift,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      shift: bindActionCreators(Object.assign({}, actions.shift), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftDetailScreen);
