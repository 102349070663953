import React, { PureComponent } from 'react';

import { PARAMETERS } from "utils/choices";
import { Photo, Props } from 'components/common';
import { DistrictValue } from "components/blocks/District";
import { ServiceValue } from "components/blocks/Service";
import styles from './card.module.css';


export default class ProfileCard extends PureComponent {
  render() {
    const { detail } = this.props;
    if (!detail || !detail.id) return null;

    const parameters = detail.parameters || {};
    const prices = detail.prices || {};
    const districts = Array.isArray(detail.districts) ? detail.districts : [];
    const services = Array.isArray(detail.services) ? detail.services : [];

    return (
      <div className={styles.card}>
        <div className={styles.body}>
          <div className={styles.photos}>
            <Photo className={styles.photo} src={detail.photos.length && detail.photos[0].url}
                   width={134} height={160}/>
          </div>

          <div className={styles.mainInfo}>
            <div className={styles.title}>{detail.name}</div>
            <Props className={styles.props} choices={PARAMETERS} info={parameters} column={1}/>
          </div>

          {districts.length > 0 &&
          <div className={styles.info}>
            <div className={styles.infoLabel}>Районы:</div>
            <DistrictValue activeFilter={{ id: districts.join(',') }}/>
          </div>}

          {services.length > 0 &&
          <div className={styles.info}>
            <div className={styles.infoLabel}>Услуги:</div>
            <ServiceValue activeFilter={{ id: services.join(',') }}/>
          </div>}

          <div className={styles.info}>
            <div className={styles.infoLabel}>Апартаменты:</div>

            <div><b>1 час: </b><span>{prices.in_hour} {prices.in_hour && 'р.'}</span></div>
            <div><b>2 часа: </b><span>{prices.in_hour2} {prices.in_hour2 && 'р.'}</span></div>
            <div><b>Ночь: </b><span>{prices.in_night} {prices.in_night && 'р.'}</span></div>
            <div><b>Экспресс: </b><span>{prices.in_express} {prices.in_express && 'р.'}</span></div>
          </div>

          <div className={styles.info}>
            <div className={styles.infoLabel}>Выезд:</div>

            <div><b>1 час: </b><span>{prices.out_hour} {prices.out_hour && 'р.'}</span></div>
            <div><b>2 часа: </b><span>{prices.out_hour2} {prices.out_hour2 && 'р.'}</span></div>
            <div><b>Ночь: </b><span>{prices.out_night} {prices.out_night && 'р.'}</span></div>
            <div><b>Экспресс: </b><span>{prices.out_express} {prices.out_express && 'р.'}</span></div>
          </div>
        </div>
      </div>
    );
  }
}
