import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Icon } from "antd";

import { actions } from "store";
import { tableColumn, arrayIsEqual } from "utils";
import { SimpleTableList, DateTimeText, Status } from "components/common";
import { ClientValue } from "components/blocks/Client";
import { OrderValue } from "components/blocks/Order";
import styles from "./card.module.css";


class LatestOrderList extends Component {
  state = {
    columnList: ['id', 'date_created', 'duration', 'status'],
    columnByName: {
      id: tableColumn('ID', 'id', {
        render: (val, item) => <OrderValue id={val} asLink={true} isBack={true}/>,
        sorter: true,
        width: 100,
      }),
      date_created: tableColumn('Дата и время', 'date_created', {
        render: (val, item) => <DateTimeText date={val}/>,
        width: 130,
      }),
      date_planned: tableColumn('План', 'date_planned', {
        render: (val, item) => <DateTimeText date={val}/>,
        width: 130,
      }),
      date_completed: tableColumn('Завершено', 'date_completed', {
        render: (val, item) => <DateTimeText date={val}/>,
        width: 130,
      }),
      client: tableColumn('Клиент', 'client', {
        render: (val, item) => <ClientValue detail={val} asLink={true} isBack={true}/>,
        width: 150,
      }),
      duration: tableColumn(<Icon type="clock-circle"/>, 'duration', {
        render: (val, item) => `${val}ч`,
        sorter: true,
        width: 40,
      }),
      status: tableColumn('Статус', 'status', {
        render: (val, item) => <Status status={val}/>,
        width: 120,
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.order.listLoad({
      query,
      activeFilter: Object.assign(activeFilter || {}, { limit: 5 })
    });
  };

  showEditForm = (item) => {
    this.props.actions.order.showEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.order.delete({ id: item.id })
  };

  render() {
    const { order, defaultFilter, activeFilter, history } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <div className={styles.card}>
        <div className={styles.cardTitle}>Последние заказы</div>
        <SimpleTableList
          history={history}
          data={order}
          defaultFilter={defaultFilter}
          activeFilter={activeFilter}
          columnList={columnList}
          columnByName={columnByName}
          onLoadData={this.load}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    order: state.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      order: bindActionCreators(Object.assign({}, actions.order), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LatestOrderList);
