import { takeEvery } from 'redux-saga/effects';

import * as reduxActions from './reduxActions';
import * as api from 'store/api/userAudit';
import * as base from "../BaseSagas";


export function* watch() {
  yield takeEvery(reduxActions.USER_AUDIT_LIST_LOAD.START, actionListLoad);
}

export function* actionListLoad(action) {
  yield base.Request(action, api.userAuditListLoad, reduxActions.actions.listLoad);
}
