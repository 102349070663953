import React, { Component } from 'react';
import { Route } from 'react-router';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { PageHeader } from "components/layout";
import { Loader, ActionButton } from "components/common";
import { CityForm } from "components/blocks/City";
import DetailScreen from './DetailScreen';
import HistoryScreen from './HistoryScreen';
import DistrictListScreen from './DistrictListScreen';



class CityDetailScreen extends Component {
  componentDidMount() {
    const { cityId } = this.props.match.params;
    if (cityId) {
      this.props.actions.city.detailLoad({ id: cityId });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevId = prevProps.match.params.cityId;
    const nextId = this.props.match.params.cityId;
    if (nextId && prevId !== nextId) {
      this.props.actions.city.detailLoad({ id: nextId });
    }
  }

  showEditForm = (item = null) => {
    this.props.actions.city.showEditForm({ show: true, object: item });
  };

  delete = () => {
    const { cityId } = this.props.match.params;
    this.props.actions.city.delete({ id: cityId });
  };

  render() {
    const { history, city } = this.props;
    const { cityId } = this.props.match.params;
    const detail = city.detail || {};

    return (
      <div className="page-inner">
        {cityId &&
        <PageHeader
          mode="detail"
          history={history}
          title={detail.name}
          breadcrumbs={[
            { title: '', icon: 'home', path: '/', isBack: false },
            { title: 'Города', icon: '', path: '/city', isBack: true },
          ]}
          actions={[
            <Button type="primary" onClick={() => this.showEditForm(detail)}>
              <Icon type="edit"/>
              <span>Редактировать</span>
            </Button>,
            <ActionButton
              actions={[
                {
                  key: 'delete',
                  icon: 'delete',
                  title: 'Удалить',
                  action: this.delete,
                  confirm: {
                    title: 'Уверены, что хотите удалить?',
                    okText: 'Удалить',
                    cancelText: 'Отмена'
                  }
                },
              ]}
            />
          ]}
          basePath={`/city/${cityId}`}
          tabs={[
            { title: 'Основное', key: 'detail' },
            { title: 'Районы', key: 'district' },
            { title: 'История изменений', key: 'history' },
          ]}
        />}

        <Route exact path="/city/:cityId" component={DetailScreen}/>
        <Route exact path="/city/:cityId/history" component={HistoryScreen}/>
        <Route exact path="/city/:cityId/district" component={DistrictListScreen}/>

        <CityForm/>
        <Loader active={city.loadDetailStatus.isLoading}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    city: state.city,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      city: bindActionCreators(Object.assign({}, actions.city), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CityDetailScreen);
