import React, { Component } from 'react';
import { Checkbox } from "antd";

import { FormItem } from "utils";
import { PhoneField } from 'components/common';
import { CitySelect } from "components/blocks/City";


export default class MainFieldSet extends Component {
  state = {
    layout: 'horizontal',
  };

  render() {
    const { form, instance, errorData, city } = this.props;
    const { layout } = this.state;
    const itemProps = { form, instance, errorData, layout };

    return (
      <div className="form-block">
        <CitySelect name="city" label="Город" required={true} disabled={true}
                    initialValue={parseInt(city.activeId)} {...itemProps}/>

        <PhoneField name="phone" label="Телефон" required={true} {...itemProps}/>

        <FormItem name="is_banned" label="Забанен" required={false} valuePropName="checked" {...itemProps}>
          <Checkbox/>
        </FormItem>
      </div>
    );
  }
}
