import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown, Menu, Icon } from 'antd';

import { actions } from 'store';
import styles from './header_city.module.css';


class HeaderCityPanel extends Component {
  select(id) {
    this.props.actions.city.select(id);
  }

  renderMenu() {
    const { city } = this.props;

    return (
      <Menu className={styles.menuInner}>
        {city.list.map(id => {
          const item = city.byId[id];
          return (
            <Menu.Item className={styles.menuItem} key={id} onClick={() => this.select(id)}>
              <div className={styles.menuLogo}>
                <Icon type="environment" />
              </div>

              <div className={styles.menuContent}>
                <div className={styles.menuTitle}>{item.name}</div>
              </div>
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  render() {
    const { currentUser, city } = this.props;
    const activeCity = city.byId[city.activeId] || {};

    return (
      <Dropdown overlay={() => this.renderMenu()} overlayClassName={styles.menu} placement="bottomCenter"
        trigger={['click']} disabled={currentUser.role === 'manager' || currentUser.city}>
        <div className={styles.main}>
          <div className={styles.mainLogo}>
            <Icon type="environment" />
          </div>
          <div className={styles.mainContent}>
            <div className={styles.mainTitle}>{activeCity.name}</div>
          </div>
          <div className={styles.mainIcon}>
            <Icon type="caret-down" />
          </div>
        </div>
      </Dropdown>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.fields,
    city: state.city,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      city: bindActionCreators(Object.assign({}, actions.city), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCityPanel);
