import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Icon } from "antd";

import { actions } from "store";
import { PageHeader } from "components/layout";
import { CityList, CityForm } from "components/blocks/City";


class CityListScreen extends Component {
  showEditForm = () => {
    this.props.actions.city.showEditForm({ show: true })
  };

  render() {
    return (
      <div className="page-inner">
        <PageHeader
          title="Города"
          breadcrumbs={null}
        />

        <div className="page-main">
          <CityList
            topActions={[
              <Button type="primary" onClick={() => this.showEditForm()}>
                <Icon type="plus"/>
                <span>Добавить</span>
              </Button>
            ]}
          />
        </div>

        <CityForm/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    city: state.city,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      city: bindActionCreators(Object.assign({}, actions.city), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CityListScreen);
