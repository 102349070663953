import React, { Component } from 'react';
import { Input, Select, Divider } from "antd";

import { FormItem } from 'utils';
import { CitySelect } from "components/blocks/City";
import { AgencySelect } from "components/blocks/Agency";


export default class MainFieldSet extends Component {
  state = {
    layout: 'horizontal',
    role: 'manager',
  };

  onRoleChange = (val) => {
    this.setState({
      role: val
    })
  };

  componentDidMount() {
    const { instance } = this.props;
    if (instance) {
      this.onRoleChange(instance.role)
    }
  }

  render() {
    const { form, instance, errorData, currentUser } = this.props;
    const { layout, role } = this.state;
    const itemProps = { form, instance, errorData, layout };

    const cityId = currentUser.city;

    return (
      <div className="form-block">
        <FormItem name="role" label="Роль" required={true} initialValue="manager" {...itemProps}>
          <Select
            style={{ width: '100%' }}
            placeholder="Выберите"
            onChange={this.onRoleChange}
            disabled={!!cityId}
          >
            <Select.Option value="manager">Менеджер</Select.Option>
            <Select.Option value="administrator">Администратор</Select.Option>
          </Select>
        </FormItem>

        {role == 'manager' ?
          <AgencySelect name="agency" label="Агентство" required={true} disabled={false} {...itemProps} /> :
          <CitySelect name="city" label="Город" required={false} disabled={!!cityId} {...itemProps} />
        }

        <FormItem name="email" label="E-mail" required={true} {...itemProps}>
          <Input size="large" />
        </FormItem>

        {!instance &&
          <FormItem name="password" label="Пароль" required={true} {...itemProps}>
            <Input.Password size="large" />
          </FormItem>}

        <Divider />

        <FormItem name="first_name" label="Имя" required={false} {...itemProps}>
          <Input size="large" />
        </FormItem>
      </div>
    );
  }
}
