import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils/redux';

export const APP_INIT = 'APP_INIT';
export const APP_DATA_LOAD = createRequestTypes('APP_DATA_LOAD');


export const actions = {
  appInit: createAction(APP_INIT),
  dataLoad: createRequestActions(APP_DATA_LOAD),
};
