import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as reduxActions from './reduxActions';


const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
  {
      editOrder: null,
  }
);

export default handleActions(
  {
    [reduxActions.ORDER_CAR_SELECT]: (state, action) => base.select(state, action),
    [reduxActions.ORDER_CAR_SHOW_EDIT_FORM]: (state, action) => ({
        ...state,
        showEditForm: action.payload.show,
        editObject: action.payload.object,
        editOrder:  action.payload.order,
    }),

    [reduxActions.ORDER_CAR_LIST_LOAD.REQUEST]: (state, action) => base.listLoadRequest(state, action),
    [reduxActions.ORDER_CAR_LIST_LOAD.COMPLETE]: (state, action) => base.listLoadComplete(state, action),
    [reduxActions.ORDER_CAR_LIST_LOAD.ERROR]: (state, action) => base.listLoadError(state, action),

    [reduxActions.ORDER_CAR_DETAIL_LOAD.REQUEST]: (state, action) => base.detailLoadRequest(state, action),
    [reduxActions.ORDER_CAR_DETAIL_LOAD.COMPLETE]: (state, action) => base.detailLoadComplete(state, action),
    [reduxActions.ORDER_CAR_DETAIL_LOAD.ERROR]: (state, action) => base.detailLoadError(state, action),

    [reduxActions.ORDER_CAR_UPDATE.REQUEST]: (state, action) => base.updateRequest(state, action),
    [reduxActions.ORDER_CAR_UPDATE.COMPLETE]: (state, action) => base.updateComplete(state, action),
    [reduxActions.ORDER_CAR_UPDATE.ERROR]: (state, action) => base.updateError(state, action),

    [reduxActions.ORDER_CAR_DELETE.REQUEST]: (state, action) => base.deleteRequest(state, action),
    [reduxActions.ORDER_CAR_DELETE.COMPLETE]: (state, action) => base.deleteComplete(state, action),
    [reduxActions.ORDER_CAR_DELETE.ERROR]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
