import React, { Component } from 'react';
import { Route } from 'react-router';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { getFullNameOrEmail } from "utils";
import { Page, PageHeader } from "components/layout";
import { Loader } from "components/common";
import { UserForm, PasswordChangeForm } from "components/blocks/User";
import DetailScreen from './DetailScreen';


class SettingsScreen extends Component {
  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.user.showEditForm({ show: true, object: item });
  };

  showPasswordForm = (item = null) => {
    const { actions } = this.props;
    actions.user.showPasswordForm({ show: true, object: item });
  };

  render() {
    const { history, currentUser, user } = this.props;
    const detail = currentUser;
    console.log('settings');

    return (
      <Page>
        <div className="page-inner">
          <PageHeader
            history={history}
            title={getFullNameOrEmail(detail)}
            actions={[
              <Button type="primary" onClick={() => this.showEditForm(detail)}>
                <Icon type="edit"/>
                <span>Редактировать</span>
              </Button>,
              <Button onClick={() => this.showPasswordForm(detail)}>
                <Icon type="lock"/>
                <span>Сменить пароль</span>
              </Button>
            ]}
            basePath={`/settings`}
            tabs={[
              { title: 'Основное', key: 'detail' },
            ]}
          />

          <Route exact path="/settings" component={DetailScreen}/>

          <UserForm preventLoadDetail={true}/>
          <PasswordChangeForm preventLoadDetail={true}/>
          <Loader active={user.loadDetailStatus.isLoading}/>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.fields,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      user: bindActionCreators(Object.assign({}, actions.user), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsScreen);
