import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Icon } from "antd";

import { actions } from "store";
import { PageHeader } from "components/layout";
import { WorkerList, WorkerForm } from "components/blocks/Worker";
import { bindActionCreators } from "redux";


class WorkerListScreen extends Component {
  showEditForm = () => {
    this.props.actions.worker.showEditForm({ show: true })
  };

  render() {
    const { city } = this.props;
    return (
      <div className="page-inner">
        <PageHeader
          title="Девушки"
          breadcrumbs={null}
        />

        <div className="page-main">
          <WorkerList
            activeFilter={{ city: city.activeId }}
            topActions={[
              <Button type="primary" onClick={() => this.showEditForm()}>
                <Icon type="plus"/>
                <span>Добавить</span>
              </Button>
            ]}
          />
        </div>

        <WorkerForm/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    city: state.city,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      worker: bindActionCreators(Object.assign({}, actions.worker), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkerListScreen);
