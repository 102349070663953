import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { getUrl } from "utils";
import { PARAMETERS, getParametetsValue } from "utils/choices";
import { Descriptions, Fieldset, DateTimeText } from 'components/common';
import { CityValue } from "components/blocks/City";
import { AgencyValue } from "components/blocks/Agency";
import styles from './detail.module.css';


class WorkerDetail extends Component {
  render() {
    const { worker } = this.props;
    const detail = worker.detail || {};
    const parameters = detail.parameters || {};
    const photos = detail.photos;

    return (
      <Row gutter={16}>
        <Col span={16}>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Город">
              <CityValue detail={detail.city} asLink={true}/>
            </Descriptions.Item>
            <Descriptions.Item label="Агентство">
              <AgencyValue detail={detail.agency} asLink={true}/>
            </Descriptions.Item>
            <Descriptions.Item label="Имя">{detail.name}</Descriptions.Item>
            <Descriptions.Item label="Телефон">{detail.phone}</Descriptions.Item>
            <Descriptions.Item label="Дата создания"><DateTimeText date={detail.date_created}/></Descriptions.Item>
          </Descriptions>

          <br/>
          <br/>

          <Fieldset title="Параметры">
            <Descriptions column={2} gutter={[16, 16]}>
              {PARAMETERS.map(p => (
                <Descriptions.Item key={`${p.name}`} label={p.label}>
                  {getParametetsValue(parameters, p.name)}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Fieldset>
        </Col>

        <Col span={8}>
          {photos && photos.length > 0 &&
          <div>
            <div className={styles.photoDetail}>
              <img src={getUrl(photos[0].url)} alt=""/>
            </div>

            {photos.length > 1 &&
            <div className={styles.photos}>
              {photos.slice(1).map(p => (
                <div key={`photo${p.id}`} className={styles.photo}>
                  <img src={getUrl(p.url)} alt=""/>
                </div>
              ))}
            </div>
            }
          </div>
          }
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    worker: state.worker,
  };
}

export default connect(mapStateToProps)(WorkerDetail);
