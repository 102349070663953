import React, { Component } from 'react';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import WorkerListScreen from './WorkerListScreen';
import WorkerDetailScreen from './WorkerDetailScreen';


export default class WorkerScreen extends Component {
  render() {
    return (
      <Page>
        <Route exact path="/worker" component={WorkerListScreen}/>
        <Route exact path="/worker/:workerId" component={WorkerDetailScreen}/>
        <Route exact path="/worker/:workerId/history" component={WorkerDetailScreen}/>
        <Route exact path="/worker/:workerId/order" component={WorkerDetailScreen}/>
      </Page>
    );
  }
}
