import React from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';

import { profileListLoad } from "store/api/profile";
import { DataLoadComponent } from 'components/common';
import ProfileCard from '../ProfileCard';
import styles from './cards.module.css';


class ProfileCards extends DataLoadComponent {
  apiListLoad = profileListLoad;

  render() {
    const { items, loadStatus } = this.state;

    return (
      <Spin spinning={loadStatus.isLoading}>
        <div className={styles.main}>
          {items.map(item => (
            <ProfileCard key={`p${item.id}`} detail={item}/>
          ))}
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(ProfileCards);
