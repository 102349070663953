import React from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from "classnames";

import { workerDetailLoad } from "store/api/worker";
import { DataLoadComponent, Photo, Status } from 'components/common';
import styles from "./worker.module.css";


class WorkerValue extends DataLoadComponent {
  apiDetailLoad = workerDetailLoad;

  getValue() {
    const { id } = this.props;
    const { detail } = this.state;
    return (detail && detail.name) || id;
  }

  getPath() {
    const { detail } = this.state;
    const detailId = detail && detail.id;
    return `/worker/${detailId}`;
  }

  getLinkPath() {
    const { asLink, isBack, router } = this.props;
    let path, state;
    if (asLink) {
      path = this.getPath();
      state = { slideIn: true };
      if (isBack) {
        state.backUrl = router.location.pathname;
        state.backSearch = router.location.search
      }
      return { pathname: path, state: state }
    }
    return null;
  }

  render() {
    const { actions, size } = this.props;
    const { detail, loadStatus } = this.state;
    const value = this.getValue();
    if (!value) return null;
    const path = this.getLinkPath();

    const cx = classNames([
      styles.main,
      styles[`size--${size}`],
    ]);

    const photos = (detail && detail.photos) || [];
    const photoWidth = size === 'xs' ? 16: 24;
    const workerStatus = (detail && detail.status) || (detail && detail.is_busy && 200);

    return (
      <Spin spinning={loadStatus.isLoading}>
        <div className={cx}>
          <Photo className={styles.photo} src={photos.length && photos[0].url} isAvatar={true}
                 width={photoWidth} height={photoWidth}/>

          {path ?
            <Link className={styles.name} to={path}><span>{value}</span></Link> :
            <span className={styles.name}><span>{value}</span></span>}

          {workerStatus && <Status className={styles.status} status={workerStatus} size={size}/>}
          {actions && !workerStatus && <div className={styles.actions}>{actions}</div>}
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    router: state.router,
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(WorkerValue);
