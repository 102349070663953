import { put, takeEvery, select } from 'redux-saga/effects';
import { push, replace } from "connected-react-router";

import * as base from 'store/BaseSagas';
import * as reduxActions from './reduxActions';
import * as api from 'store/api/phone';


export function* watch() {
  yield takeEvery(reduxActions.PHONE_LIST_LOAD.START, actionListLoad);
  yield takeEvery(reduxActions.PHONE_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(reduxActions.PHONE_UPDATE.START, actionUpdate);
  yield takeEvery(reduxActions.PHONE_DELETE.START, actionDelete);
  yield takeEvery(reduxActions.PHONE_SELECT, actionSelect);
}

function* actionSelect(action) {
  yield put(push(`/`));
}

export function* actionListLoad(action) {
  yield base.Request(action, api.phoneListLoad, reduxActions.actions.listLoad);
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.phoneDetailLoad, reduxActions.actions.detailLoad);
}

function* actionUpdate(action) {
  yield base.Request(action, api.phoneUpdate, reduxActions.actions.update,
    function* (data) {
      yield put(reduxActions.actions.showEditForm({ show: false }));
      if (!action.payload.preventLoadDetail) {
        yield put(reduxActions.actions.detailLoad({ id: data.id }));
        yield put(push(`/agency/${data.agency}/phone/${data.id}`));
      } else {
        const filter = yield select((state) => state.phone.filter);
        yield put(reduxActions.actions.listLoad(filter));
      }
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.phoneDelete, reduxActions.actions.delete,
    function* (data) {
      const filter = yield select((state) => state.phone.filter);
      yield put(replace('/agency'));
      yield put(reduxActions.actions.listLoad(filter));
    }
  );
}
