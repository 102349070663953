import React, { PureComponent } from 'react';
import classNames from "classnames";

import { parseDate } from "utils";
import styles from './datetime.module.css';


export default class DateTimeText extends PureComponent {
  render() {
    const { className, size, inline, date, mode } = this.props;
    let text = '';

    const cx = classNames([
      styles.container,
      styles[`size--${size}`],
      inline && styles.inline,
      className,
    ]);

    switch (mode) {
      case 'date':
        text = parseDate(date).format('DD.MM.YYYY');
        break;
      case 'time':
        text = parseDate(date).format('HH:mm');
        break;
      default:
        text = parseDate(date).format('DD.MM.YYYY HH:mm');
    }

    return (
      <div className={cx}>{date ? text: '-'}</div>
    );
  }
}
