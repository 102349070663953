import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Descriptions, DateTimeText, Fieldset, Status } from 'components/common';
import { CityValue } from "components/blocks/City";
import { ClientValue } from "components/blocks/Client";
import { ClientAddressValue } from "components/blocks/ClientAddress";
import { UserValue } from "components/blocks/User";
import { PhoneValue } from "components/blocks/Phone";
import { ApartmentCard } from "components/blocks/Apartment";
import { CarCard } from "components/blocks/Car";
import { WorkerCard } from "components/blocks/Worker";


class OrderDetail extends Component {
  render() {
    const { currentUser, order } = this.props;
    const isManager = currentUser.role === 'manager';
    const detail = order.detail || {};
    const orderApartments = detail.apartments || [];
    const orderCars = detail.cars || [];
    const orderWorkers = detail.workers || [];

    const {
      date_created, date_car_assigned, date_apartment_assigned, date_planned,
      date_approved, date_prolonged, date_completed, date_canceled, date_rejected, status
    } = detail;

    return (
      <div>
        <Descriptions column={4} gutter={16}>
          <Descriptions.Item label="Город">
            <CityValue id={detail.city} asLink={true} isBack={true}/>
          </Descriptions.Item>
          <Descriptions.Item label="Менеджер">
            <UserValue detail={detail.manager} asLink={true} isBack={true}/>
          </Descriptions.Item>
          <Descriptions.Item label="Телефон, на который звонили">
            <PhoneValue id={detail.phone} asLink={true} isBack={true}/>
          </Descriptions.Item>

        </Descriptions>

        <br/>

        <Descriptions column={6} gutter={16}>
          <Descriptions.Item label="Дата создания"><DateTimeText date={date_created}/></Descriptions.Item>

          {date_car_assigned &&
          <Descriptions.Item label="Назначена машина"><DateTimeText date={date_car_assigned}/></Descriptions.Item>}

          {date_apartment_assigned &&
          <Descriptions.Item label="Назначены апартаменты"><DateTimeText date={date_apartment_assigned}/></Descriptions.Item>}

          {date_approved &&
          <Descriptions.Item label="Принято"><DateTimeText date={date_approved}/></Descriptions.Item>}

          {date_prolonged &&
          <Descriptions.Item label="Продлено"><DateTimeText date={date_prolonged}/></Descriptions.Item>}

          {date_planned &&
          <Descriptions.Item label="План. завершение"><DateTimeText date={date_planned}/></Descriptions.Item>}

          {date_completed && status === Status.COMPLETED &&
          <Descriptions.Item label="Завершено"><DateTimeText date={date_completed}/></Descriptions.Item>}

          {date_canceled && status === Status.CANCELED &&
          <Descriptions.Item label="Отменено"><DateTimeText date={date_canceled}/></Descriptions.Item>}

          {date_rejected && status === Status.REJECTED &&
          <Descriptions.Item label="Отказ"><DateTimeText date={date_rejected}/></Descriptions.Item>}
        </Descriptions>

        <br/>

        <Descriptions bordered column={6} gutter={[16, 16]}>
          <Descriptions.Item label="Клиент" span={6}>
            <ClientValue detail={detail.client} asLink={true} isBack={true}/>
          </Descriptions.Item>
          <Descriptions.Item label="Адрес" span={10}>
            <ClientAddressValue detail={detail.address}/>
          </Descriptions.Item>
          <Descriptions.Item label="Кол-во" span={2}>{detail.count}</Descriptions.Item>
          <Descriptions.Item label="Длительность" span={3}>{detail.duration} ч.</Descriptions.Item>
          <Descriptions.Item label="Цена" span={3}>{detail.price} руб.</Descriptions.Item>
        </Descriptions>

        <br/>

        <Descriptions bordered column={1} gutter={16}>
          <Descriptions.Item label="Комментарий">{detail.note}</Descriptions.Item>
        </Descriptions>

        <br/><br/>

        {orderApartments.length > 0 &&
        <Fieldset title="Апартаменты">
          <Row gutter={16}>
            {orderApartments.map(orderApartment =>
              <Col span={12} key={`apartment${orderApartment.id}`} style={{ marginBottom: '16px' }}>
                <ApartmentCard
                  detail={orderApartment.apartment} orderApartment={orderApartment} orderWorkers={orderWorkers}
                  isManager={isManager}
                />
              </Col>
            )}
          </Row>
        </Fieldset>}

        {orderCars.length > 0 &&
        <Fieldset title="Машины">
          <Row gutter={16}>
            {orderCars.map(orderCar =>
              <Col span={12} key={`car${orderCar.id}`} style={{ marginBottom: '16px' }}>
                <CarCard detail={orderCar.car} orderCar={orderCar} orderWorkers={orderWorkers} isManager={isManager}/>
              </Col>
            )}
          </Row>
        </Fieldset>}

        <br/>

        {orderWorkers.length > 0 &&
        <Fieldset title="Девушки">
          <Row gutter={16}>
            {orderWorkers.map(orderWorker =>
              <Col span={12} key={`worker${orderWorker.id}`} style={{ marginBottom: '16px' }}>
                <WorkerCard detail={orderWorker.worker} orderWorker={orderWorker} isManager={isManager}/>
              </Col>
            )}
          </Row>
        </Fieldset>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.fields,
    order: state.order,
  };
}

export default connect(mapStateToProps)(OrderDetail);
