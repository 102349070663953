import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { tableColumn, arrayIsEqual } from "utils";
import { TableList, ActionButton, DateTimeText } from "components/common";
import { CityValue, CitySelect } from "components/blocks/City";
import AgencyValue from "../AgencyValue";


class AgencyList extends Component {
  state = {
    columnList: ['name', 'city', 'date_created', 'actions'],
    columnByName: {
      name: tableColumn('Название', 'name', {
        render: (val, item) => <AgencyValue detail={item} asLink={true} isBack={true}/>,
        sorter: true,
      }),
      date_created: tableColumn('Дата создания', 'date_created', {
        render: (val, item) => <DateTimeText date={val}/>,
        width: 130,
      }),
      city: tableColumn('Город', 'city', {
        render: (val, item) => <CityValue detail={val}/>,
        width: 130,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        width: 50,
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }, {
              title: 'Удалить', key: 'delete', icon: 'delete', action: () => this.delete(item),
              confirm: {
                title: 'Уверены, что хотите удалить?',
                okText: 'Удалить',
                cancelText: 'Отмена'
              }
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.agency.listLoad({ query, activeFilter, locationChange: true });
  };

  showEditForm = (item) => {
    this.props.actions.agency.showEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.agency.delete({ id: item.id })
  };

  render() {
    const { agency, defaultFilter, activeFilter, history, topActions } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={agency}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        showSearch={true}
        topActions={topActions}
        filterFields={{
          list: [
            'city',
          ],
          byName: {
            'city': {
              label: 'Город',
              type: 'list_int',
              component: CitySelect,
              valueComponent: CityValue,
            },
          }
        }}

      />
    );
  }
}


function mapStateToProps(state) {
  return {
    agency: state.agency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      agency: bindActionCreators(Object.assign({}, actions.agency), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyList);
