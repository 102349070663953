import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ResponsiveContainer, AreaChart, Area, Tooltip } from 'recharts';

import { actions } from "store";
import { dictIsEqual, parseDate } from "utils";
import { Loader } from "components/common";
import styles from './card.module.css';


class OrderSummary extends Component {
  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { period, dateStart, dateEnd } = this.props.analytics;
    const prevPeriod = prevProps.analytics.period;
    const prevDateStart = prevProps.analytics.dateStart;
    const prevDateEnd = prevProps.analytics.dateEnd;
    if (!dictIsEqual(this.props.cityActiveId, prevProps.cityActiveId) ||
      period !== prevPeriod || dateStart !== prevDateStart || dateEnd !== prevDateEnd ) {
      this.loadData()
    }
  }

  loadData = () => {
    this.props.actions.analytics.orderLoad();
  };

  render() {
    const { analytics, stroke, fill } = this.props;
    const { dateFormat, orderStatus, order } = analytics;
    const data = order.groups;
    const completedCount = order.counts ? order.counts['29']: 0;

    return (
      <div className={styles.card}>
        <div className={styles.cardTitle}>Заказы</div>
        <div className={styles.cardCount}>{order.count}</div>
        <div className={styles.cardChart}>
          <ResponsiveContainer width="100%" height={50}>
            <AreaChart data={data}>
              <Tooltip
                labelFormatter={index =>
                  data && data[index] && parseDate(data[index].date, "YYYY-MM-DD HH:mm").format(dateFormat)
                }
                formatter={(value, name, props) => ([value, "заказы"])}
              />
              <Area type="monotone" dataKey="count" stroke={stroke} fillOpacity={1} fill={fill}/>
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div className={styles.cardBottom}>Успешных заказов: {completedCount}</div>

        <Loader active={orderStatus.isLoading}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cityActiveId: state.city.activeId,
    analytics: state.analytics,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      analytics: bindActionCreators(Object.assign({}, actions.analytics), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
