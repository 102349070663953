import React, { Component } from 'react';
import { Route } from 'react-router';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { getFullNameOrEmail } from "utils";
import { PageHeader } from "components/layout";
import { ActionButton, Loader } from "components/common";
import { UserForm, PasswordChangeForm } from "components/blocks/User";
import DetailScreen from './DetailScreen';


class UserDetailScreen extends Component {
  componentDidMount() {
    const { userId } = this.props.match.params;
    if (userId) {
      this.props.actions.user.detailLoad({ id: userId });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevId = prevProps.match.params.userId;
    const nextId = this.props.match.params.userId;
    if (nextId && prevId !== nextId) {
      this.props.actions.user.detailLoad({ id: nextId });
    }
  }

  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.user.showEditForm({ show: true, object: item });
  };

  showPasswordForm = (item = null) => {
    const { actions } = this.props;
    actions.user.showPasswordForm({ show: true, object: item });
  };

  delete = () => {
    const { userId } = this.props.match.params;
    this.props.actions.user.delete({ id: userId })
  };

  render() {
    const { history, user } = this.props;
    const { userId } = this.props.match.params;
    const detail = user.detail || {};

    return (
      <div className="page-inner">
        {userId &&
        <PageHeader
          mode="detail"
          history={history}
          title={getFullNameOrEmail(detail)}
          breadcrumbs={[
            { title: '', icon: 'home', path: '/', isBack: false },
            { title: 'Пользователи', icon: '', path: '/user', isBack: true },
          ]}
          actions={[
            <Button type="primary" onClick={() => this.showEditForm(detail)}>
              <Icon type="edit"/>
              <span>Редактировать</span>
            </Button>,
            <Button type="border" onClick={() => this.showPasswordForm(detail)}>
              <Icon type="lock"/>
              <span>Сменить пароль</span>
            </Button>,
            <ActionButton
              actions={[
                {
                  key: 'delete',
                  icon: 'delete',
                  title: 'Удалить',
                  action: this.delete,
                  confirm: {
                    title: 'Уверены, что хотите удалить?',
                    okText: 'Удалить',
                    cancelText: 'Отмена'
                  }
                },
              ]}
            />
          ]}
          basePath={`/user/${userId}`}
          tabs={[
            { title: 'Основное', key: 'detail' },
          ]}
        />}

        <Route exact path="/user/:userId" component={DetailScreen}/>

        <UserForm/>
        <PasswordChangeForm/>
        <Loader active={user.loadDetailStatus.isLoading}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      user: bindActionCreators(Object.assign({}, actions.user), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailScreen);
