import React, { PureComponent } from 'react';
import classNames from "classnames";

import styles from './fieldset.module.css';


export default class Fieldset extends PureComponent {
  render() {
    const { className, title, actions, children } = this.props;
    const cx = classNames([
      styles.container,
      className,
    ]);

    return (
      <div className={cx}>
        <div className={styles.header}>
          <div className={styles.headerMain}>
            <div className={styles.headerTitle}>{title}</div>
          </div>
          <div className={styles.headerActions}>{actions}</div>
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    );
  }
}
