import React, { Component } from 'react';
import { DatePicker, Icon } from "antd";
import moment from "moment";

import styles from './date_control.module.css';


export default class DateControl extends Component {
  state = {
    date: moment(),
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { date } = nextProps;
    if (!date || (date && date.isSame(prevState.date))) return null;
    return {
      date: date,
    }
  }

  onDateChange = (date) => {
    this.setState({
      date: date,
    });
    this.props.onChange && this.props.onChange(date);
  };

  nextDate = () => {
    this.onDateChange(
      this.state.date.add(1, 'day')
    );
  };

  prevDate = () => {
    this.onDateChange(
      this.state.date.subtract(1, 'day')
    );
  };

  render() {
    return (
      <div className={styles.dateControl}>
        <div className={styles.dateControlMain}>
          <div className={styles.prev} onClick={this.prevDate}>
            <Icon type="left" className={styles.dateControlPrev}/>
          </div>
          <div className={styles.dateControlTitle}>
            <DatePicker
              className="dateControlPicker"
              size="large"
              allowClear={false}
              format="DD.MM.YYYY"
              value={this.state.date}
              onChange={this.onDateChange}
            />
          </div>
          <div className={styles.next} onClick={this.nextDate}>
            <Icon type="right" className={styles.dateControlNext}/>
          </div>
        </div>
      </div>
    );
  }
}
