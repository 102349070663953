import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Row, Icon, Button } from 'antd';

import { actions } from "store";
import { Descriptions, Fieldset, DateTimeText } from 'components/common';
import { CityValue } from "components/blocks/City";
import { UserValue } from "components/blocks/User";
import { ApartmentForm, ApartmentCard } from "components/blocks/Apartment";
import { CarForm, CarCard } from "components/blocks/Car";
import { WorkerForm } from "components/blocks/Worker";


class ShiftDetail extends Component {
  showCarForm = (object = null) => {
    this.props.actions.car.showEditForm({ show: true, object })
  };

  showApartmentForm = (object = null) => {
    this.props.actions.apartment.showEditForm({ show: true, object })
  };

  render() {
    const { currentUser, detail } = this.props;
    const isManager = currentUser.role === 'manager';
    const cars = detail.cars || [];
    const apartments = detail.apartments || [];
    const isActive = detail && detail.id && !detail.date_end;

    return (
      <Row gutter={16}>
        <Col span={16}>
          <Descriptions bordered column={4} gutter={16}>
            <Descriptions.Item label="Город">
              <CityValue detail={detail.city}/>
            </Descriptions.Item>
            <Descriptions.Item label="Диспетчер">
              <UserValue detail={detail.manager}/>
            </Descriptions.Item>
            <Descriptions.Item label="Дата начала"><DateTimeText date={detail.date_start}/></Descriptions.Item>
            <Descriptions.Item label="Дата завершения"><DateTimeText date={detail.date_end}/></Descriptions.Item>
          </Descriptions>

          <br/><br/>

          <Fieldset
            title="Апартаменты"
            actions={[
              isActive &&
              <Button key="apartment-add-btn" type="primary" onClick={() => this.showApartmentForm()}>
                <Icon type="plus"/>Добавить апартаменты
              </Button>,
            ]}
          >
            <Row gutter={16}>
              {apartments.map(apartment =>
                <Col span={12} key={`apartment${apartment.id}`} style={{ marginBottom: '16px' }}>
                  <ApartmentCard detail={apartment} showActions={isActive} isManager={isManager}/>
                </Col>
              )}
            </Row>
          </Fieldset>

          <br/><br/>

          <Fieldset
            title="Машины"
            actions={[
              isActive &&
              <Button key="car-add-btn" type="primary" onClick={() => this.showCarForm()}>
                <Icon type="plus"/>Добавить машину
              </Button>,
            ]}
          >
            <Row gutter={16}>
              {cars.map(car =>
                <Col span={12} key={`car${car.id}`} style={{ marginBottom: '16px' }}>
                  <CarCard detail={car} showActions={isActive} isManager={isManager}/>
                </Col>
              )}
            </Row>
          </Fieldset>

          <CarForm/>
          <WorkerForm/>
          <ApartmentForm/>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.fields,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      car: bindActionCreators(Object.assign({}, actions.car), dispatch),
      apartment: bindActionCreators(Object.assign({}, actions.apartment), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftDetail);
