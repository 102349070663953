import React, { Component } from 'react';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import ClientListScreen from './ClientListScreen';
import ClientDetailScreen from './ClientDetailScreen';


export default class ClientScreen extends Component {
  render() {
    return (
      <Page>
        <Route exact path="/client" component={ClientListScreen}/>
        <Route exact path="/client/:clientId" component={ClientDetailScreen}/>
        <Route exact path="/client/:clientId/history" component={ClientDetailScreen}/>
        <Route exact path="/client/:clientId/order" component={ClientDetailScreen}/>
      </Page>
    );
  }
}

