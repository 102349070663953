import React from 'react';
import classNames from "classnames";

import { getUrl } from 'utils';


const FieldFileValue = (props) => {
  const { className, label, value } = props;
  const cx = classNames([
    "field-value",
    className,
  ]);

  const fileList = Array.isArray(value) ? value : [];

  return (
    <div className={cx}>
      <div className="field-value-lbl">{label}</div>
      <div className="field-value-val">
        {fileList.map(item => {
          return <a key={item.id} href={getUrl(item.url)} target="_blank" rel="noopener noreferrer">
            {item.name}
          </a>
        })}
      </div>
    </div>
  );
};

export default FieldFileValue;
