import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Descriptions, DateTimeText } from 'components/common';
import { CityValue } from "components/blocks/City";


class AgencyDetail extends Component {
  render() {
    const { agency } = this.props;
    const detail = agency.detail || {};

    return (
      <Row gutter={16}>
        <Col span={12}>
          <Descriptions column={1} gutter={16}>
            <Descriptions.Item label="Город">
              <CityValue detail={detail.city} asLink={true} isBack={true}/>
            </Descriptions.Item>
          </Descriptions>

          <br/>

          <Descriptions column={2} gutter={16}>
            <Descriptions.Item label="Название">{detail.name}</Descriptions.Item>
            <Descriptions.Item label="Дата создания"><DateTimeText date={detail.date_created}/></Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    agency: state.agency,
  };
}

export default connect(mapStateToProps)(AgencyDetail);
