import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from "antd";

import { actions } from "store";
import { tableColumn, arrayIsEqual } from "utils";
import { TableList, ActionButton, OnOff } from "components/common";
import { AddressValue } from "components/blocks/Address";
import { WorkerValue } from "components/blocks/Worker";


class ApartmentList extends Component {
  state = {
    updateItemId: null,
    columnList: ['address', 'workers', 'is_closed', 'actions'],
    columnByName: {
      address: tableColumn('Адрес', 'address', {
        render: (val, item) => <AddressValue detail={val} asLink={true} isBack={true}/>,
        sorter: true,
        width: 200,
      }),
      workers: tableColumn('Девушки', 'workers', {
        render: (val, item) => (
          val.map(worker => <WorkerValue key={`worker${worker.id}`} detail={worker} asLink={true} isBack={true}/>)
        ),
        sorter: true,
      }),
      is_closed: tableColumn('Завершено', 'is_closed', {
        render: (val, item) => (
          <OnOff
            param="is_closed"
            preventLoadDetail={true}
            item={item}
            updateItemId={this.state.updateItemId}
            updateStatus={this.props.apartment.updateStatus}
            onChange={this.itemUpdate}
          />
        ),
        width: 120,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        width: 50,
        render: (val, item) => (
          !item.is_closed &&
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }, {
              title: 'Завершить', key: 'stop', icon: 'stop', action: () => this.stop(item),
              confirm: {
                title: 'Уверены, что хотите завершить?',
                okText: 'Завершить',
                cancelText: 'Отмена'
              }
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.apartment.listLoad({ query, activeFilter });
  };

  showEditForm = (item) => {
    this.props.actions.apartment.showEditForm({ show: true, object: item })
  };

  stop = (item) => {
    this.props.actions.apartment.update({ id: item.id, is_closed: true })
  };

  itemUpdate = (data) => {
    this.setState({
      updateItemId: data.id,
    });
    this.props.actions.apartment.update(data);
  };

  render() {
    const { apartment, defaultFilter, activeFilter, history } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={apartment}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        showSearch={true}
        topActions={[
          <Button type="primary" className="" icon="plus" onClick={() => this.showEditForm()}>
            Добавить
          </Button>
        ]}
      />
    );
  }
}


function mapStateToProps(state) {
  return {
    apartment: state.apartment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      apartment: bindActionCreators(Object.assign({}, actions.apartment), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApartmentList);
