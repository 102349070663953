import React, { Component } from 'react';

import { AddressDetail } from 'components/blocks/Address';


export default class DetailScreen extends Component {
  render() {
    return (
      <div className="page-main">
        <AddressDetail/>
      </div>
    );
  }
}
