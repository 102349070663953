import { put, call, takeEvery, select } from 'redux-saga/effects';
import { push } from 'connected-react-router'

import * as reduxActions from './reduxActions';
import { reduxActions as AppActions } from 'store/app';
import { setToken, removeToken } from 'utils/helpers';
import { apiAuthLogin, apiAuthLogout } from 'store/api/auth';


export function* watch() {
  yield takeEvery(reduxActions.AUTH_LOGIN.START, login);
  yield takeEvery(reduxActions.AUTH_LOGOUT.START, logout);
}


function* login(action) {
  yield put(reduxActions.actions.login.request());
  const next = action.payload.next || '/';

  try {
    const { error, data, statusCode } = yield call(() => apiAuthLogin(action.payload.data));
    const { token } = data;

    if (error) {
      yield put(reduxActions.actions.login.error({ data, statusCode }));
    } else {
      setToken(token);
      yield put(reduxActions.actions.login.complete(data));
      yield put(AppActions.actions.dataLoad({ token }));
      yield put(push(next))
    }

  } catch (error) {
    yield put(reduxActions.actions.login.error());
  }
}


function* logout(action) {
  console.log('logout');
  yield put(reduxActions.actions.logout.request());
  const token = yield select((state) => state.auth.token);

  try {
    const { error, data, statusCode } = yield call(() => apiAuthLogout(token));

    console.log(error);
    console.log(data);

    if (error) {
      yield put(reduxActions.actions.logout.error({ data, statusCode }));
    } else {
      yield put(reduxActions.actions.logout.complete(data));
    }

  } catch (error) {
    console.log(error);
    yield put(reduxActions.actions.logout.error());
  }

  console.log('logout:end');
  removeToken();
  yield put(push('/login'));
}
