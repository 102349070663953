import React, { Component } from 'react';
import { Col, Input, InputNumber, Row } from "antd";

import { FormItem, getObject } from "utils";


export default class MainFieldSet extends Component {
  state = {
    layout: 'horizontal2',
  };

  render() {
    const { form, instance, errorData, order, orderWorker } = this.props;
    const { layout } = this.state;
    const itemProps = { form, instance, errorData, layout };

    const orderId = orderWorker.editOrderId;
    const orderObj = (order.detail && order.detail.id === orderId) ? order.detail: getObject(order, orderId);

    const initialDuration = (orderObj && orderObj.duration) || 1;
    const initialPrice = (orderObj && orderObj.price) || 1000;

    return (
      <div className="form-block">
        <Row gutter={16} type="flex">
          <Col span={14}>
            <FormItem name="duration" label="Длительность" required={true} initialValue={initialDuration}
                      {...itemProps}>
              <InputNumber min={0.5} max={24} step={0.5}/>
            </FormItem>
          </Col>
          <Col span={10}>
            <FormItem name="price" label="Цена" required={true} initialValue={initialPrice} {...itemProps}>
              <Input size="large" addonAfter="руб."/>
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  }
}
