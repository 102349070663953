import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const CLIENT_LIST_LOAD = createRequestTypes('CLIENT_LIST_LOAD');
export const CLIENT_DETAIL_LOAD = createRequestTypes('CLIENT_DETAIL_LOAD');
export const CLIENT_UPDATE = createRequestTypes('CLIENT_UPDATE');
export const CLIENT_DELETE = createRequestTypes('CLIENT_DELETE');
export const CLIENT_SHOW_EDIT_FORM = 'CLIENT_SHOW_EDIT_FORM';
export const CLIENT_SELECT = 'CLIENT_SELECT';


export const actions = {
  showEditForm: createAction(CLIENT_SHOW_EDIT_FORM),
  select: createAction(CLIENT_SELECT),

  listLoad: createRequestActions(CLIENT_LIST_LOAD),
  detailLoad: createRequestActions(CLIENT_DETAIL_LOAD),
  update: createRequestActions(CLIENT_UPDATE),
  delete: createRequestActions(CLIENT_DELETE),
};
