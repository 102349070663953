import { createAction } from "redux-actions";

export function createRequestTypes(asyncType) {
  return {
    START: asyncType + '_START',
    REQUEST: asyncType + '_REQUEST',
    COMPLETE: asyncType + 'COMPLETE',
    ERROR: asyncType + '_ERROR',
  }
}


export function createRequestActions(actionTypes) {
  let result = createAction(actionTypes.START);
  result.request = createAction(actionTypes.REQUEST);
  result.complete = createAction(actionTypes.COMPLETE);
  result.error = createAction(actionTypes.ERROR);
  return result
}


export function importResults(results, list = [], byId = {}) {
  results.forEach(item => {
    list.push(item.id);
    byId[item.id] = item;
  });
  return { list, byId }
}


export function updateItem(newItem, list = [], byId = {}) {
  let id = list.find(id => id === newItem.id);

  if (id) {
    byId[id] = Object.assign({}, byId[id], newItem);
  } else {
    list.push(newItem.id);
    byId[newItem.id] = newItem;
  }

  return { list, byId }
}

export function deleteItem(id, list = [], byId = {}) {
  if (byId[id]) {
    const index = list.indexOf(id);

    if (index > -1) {
      list.splice(index, 1);
    }

    delete byId[id];
  }

  return { list, byId }
}


export const loadStates = {
  notAsked: 'notAsked',
  loading: 'loading',
  loaded: 'loaded',
  failed: 'failed',
};


export const loadStatus = (status, error = null) => {
  return {
    status: status,
    error: error,
    notAsked: status === loadStates.notAsked,
    isLoading: status === loadStates.loading,
    isLoaded: status === loadStates.loaded,
    isError: status === loadStates.failed,
  }
};


export const getLoadStatus = (items, key = 'loadListStatus') => {
  return items.some(item => item[key].isLoading)
};
