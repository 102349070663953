import React, { Component } from 'react';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import UserListScreen from './UserListScreen';
import UserDetailScreen from './UserDetailScreen';


export default class UserScreen extends Component {
  render() {
    return (
      <Page>
        <Route exact path="/user" component={UserListScreen}/>
        <Route exact path="/user/:userId" component={UserDetailScreen}/>
      </Page>
    );
  }
}
