import React, { Component } from 'react';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import ServiceListScreen from './ServiceListScreen';
import ServiceDetailScreen from './ServiceDetailScreen';


export default class ServiceScreen extends Component {
  render() {
    return (
      <Page>
        <Route exact path="/service" component={ServiceListScreen}/>
        <Route exact path="/service/:serviceId" component={ServiceDetailScreen}/>
        <Route exact path="/service/:serviceId/history" component={ServiceDetailScreen}/>
      </Page>
    );
  }
}
