import React, { Component } from 'react';
import { Route } from 'react-router';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { PageHeader } from "components/layout";
import { Loader } from "components/common";
import { PhoneForm } from "components/blocks/Phone";
import DetailScreen from './DetailScreen';
import HistoryScreen from './HistoryScreen';


class PhoneDetailScreen extends Component {
  componentDidMount() {
    const { phoneId } = this.props.match.params;
    if (phoneId) {
      this.props.actions.phone.detailLoad({ id: phoneId });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevId = prevProps.match.params.phoneId;
    const nextId = this.props.match.params.phoneId;
    if (nextId && prevId !== nextId) {
      //this.props.actions.phone.select(nextId);
      this.props.actions.phone.detailLoad({ id: nextId });
    }
  }

  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.phone.showEditForm({ show: true, object: item });
  };

  render() {
    const { history, phone } = this.props;
    const { agencyId, phoneId } = this.props.match.params;
    const detail = phone.detail || {};
    const agency = detail.agency || {};

    return (
      <div className="page-inner">
        {phoneId &&
        <PageHeader
          mode="detail"
          history={history}
          title={detail.name}
          breadcrumbs={[
            { title: '', icon: 'home', path: '/', isBack: false },
            { title: 'Агентства', icon: '', path: '/agency', isBack: true },
            { title: agency.name, icon: '', path: `/agency/${agency.id}/phone`, isBack: true },
          ]}
          actions={[
            <Button type="primary" onClick={() => this.showEditForm(detail)}>
              <Icon type="edit"/>
              <span>Редактировать</span>
            </Button>
          ]}
          basePath={`/agency/${agencyId}/phone/${phoneId}`}
          tabs={[
            { title: 'Основное', key: 'detail' },
            { title: 'История изменений', key: 'history' },
          ]}
        />}

        <Route exact path="/agency/:agencyId/phone/:phoneId" component={DetailScreen}/>
        <Route exact path="/agency/:agencyId/phone/:phoneId/history" component={HistoryScreen}/>

        <PhoneForm/>
        <Loader active={phone.loadDetailStatus.isLoading}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    phone: state.phone,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      phone: bindActionCreators(Object.assign({}, actions.phone), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhoneDetailScreen);
