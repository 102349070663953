import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Icon } from "antd";

import { actions } from "store";
import { PhoneList, PhoneForm } from "components/blocks/Phone";


class PhoneListScreen extends Component {
  showEditForm = () => {
    this.props.actions.phone.showEditForm({ show: true })
  };

  render() {
    const { city } = this.props;
    const { agencyId } = this.props.match.params;

    return (
      <div>
        <PhoneList
          activeFilter={{ city: city.activeId, agency: agencyId }}
          topActions={[
            <Button type="primary" onClick={() => this.showEditForm()}>
              <Icon type="plus"/>
              <span>Добавить</span>
            </Button>
          ]}
        />

        <PhoneForm agencyId={agencyId}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    city: state.city,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      phone: bindActionCreators(Object.assign({}, actions.phone), dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhoneListScreen);
