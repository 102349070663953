import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const CAR_LIST_LOAD = createRequestTypes('CAR_LIST_LOAD');
export const CAR_DETAIL_LOAD = createRequestTypes('CAR_DETAIL_LOAD');
export const CAR_UPDATE = createRequestTypes('CAR_UPDATE');
export const CAR_DELETE = createRequestTypes('CAR_DELETE');
export const CAR_SHOW_EDIT_FORM = 'CAR_SHOW_EDIT_FORM';
export const CAR_SELECT = 'CAR_SELECT';


export const actions = {
  showEditForm: createAction(CAR_SHOW_EDIT_FORM),
  select: createAction(CAR_SELECT),

  listLoad: createRequestActions(CAR_LIST_LOAD),
  detailLoad: createRequestActions(CAR_DETAIL_LOAD),
  update: createRequestActions(CAR_UPDATE),
  delete: createRequestActions(CAR_DELETE),
};
