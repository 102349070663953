import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Descriptions } from 'antd';

import { DateTimeText } from 'components/common';


class DetailScreen extends Component {
  render() {
    const { currentUser } = this.props;
    const detail = currentUser;

    return (
      <div className="page-main">
        <Row gutter={16}>
          <Col span={12}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label="E-mail">{detail.email}</Descriptions.Item>
              <Descriptions.Item label="Имя Фамилия">{detail.first_name} {detail.last_name}</Descriptions.Item>
              <Descriptions.Item label="Роль">{detail.role}</Descriptions.Item>
              <Descriptions.Item label="Дата создания"><DateTimeText date={detail.date_joined}/></Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.fields,
  };
}

export default connect(mapStateToProps)(DetailScreen);
