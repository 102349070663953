import React from 'react';
import classNames from 'classnames';

import styles from './error.module.css';


export default function Error(props) {
  const { className, errors } = props;
  if (!errors) return null;

  const cx = classNames([
    styles.error,
    className,
  ]);

  return (
    <div className={cx}>{errors}</div>
  );
};
