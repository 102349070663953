import { BASE_URL } from "config";

import * as base from './_base';


export const endpoints = {
  list: `${BASE_URL}/api/v1/client/address/`,
  detail: (id) => `${BASE_URL}/api/v1/client/address/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/client/address/${id}/history/`,
};

export const clientAddressListLoad = (token, payload) => base.ListLoad(endpoints.list, token, payload);
export const clientAddressDetailLoad = (token, { id }) => base.DetailLoad(endpoints.detail, token, { id });
export const clientAddressUpdate = (token, data) => base.Update(endpoints.list, endpoints.detail, token, data);
export const clientAddressDelete = (token, data) => base.Delete(endpoints.detail, token, data);
export const historyLoad = (token, payload) => base.DetailLoad(endpoints.history, token, { id: payload.id });
