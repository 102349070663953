import { createAction } from 'redux-actions';
import { createRequestTypes, createRequestActions } from 'utils';

export const AGENCY_LIST_LOAD = createRequestTypes('AGENCY_LIST_LOAD');
export const AGENCY_DETAIL_LOAD = createRequestTypes('AGENCY_DETAIL_LOAD');
export const AGENCY_UPDATE = createRequestTypes('AGENCY_UPDATE');
export const AGENCY_DELETE = createRequestTypes('AGENCY_DELETE');
export const AGENCY_SHOW_EDIT_FORM = 'AGENCY_SHOW_EDIT_FORM';
export const AGENCY_SELECT = 'AGENCY_SELECT';


export const actions = {
  showEditForm: createAction(AGENCY_SHOW_EDIT_FORM),
  select: createAction(AGENCY_SELECT),

  listLoad: createRequestActions(AGENCY_LIST_LOAD),
  detailLoad: createRequestActions(AGENCY_DETAIL_LOAD),
  update: createRequestActions(AGENCY_UPDATE),
  delete: createRequestActions(AGENCY_DELETE),
};
