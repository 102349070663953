import { takeEvery } from 'redux-saga/effects';

import * as base from 'store/BaseSagas';
import * as reduxActions from './reduxActions';


export function* watch() {
  yield takeEvery(reduxActions.HISTORY_LIST_LOAD.START, actionListLoad);
}

export function* actionListLoad(action) {
  const { payload } = action;
  yield base.Request(action, payload.apiLoad, reduxActions.actions.listLoad);
}
