import React, { Component } from 'react';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import ProfileListScreen from './ProfileListScreen';
import ProfileDetailScreen from './ProfileDetailScreen';


export default class ProfileScreen extends Component {
  render() {
    return (
      <Page>
        <Route exact path="/profile" component={ProfileListScreen}/>
        <Route exact path="/profile/:profileId" component={ProfileDetailScreen}/>
        <Route exact path="/profile/:profileId/history" component={ProfileDetailScreen}/>
        <Route exact path="/profile/:profileId/district" component={ProfileDetailScreen}/>
      </Page>
    );
  }
}
